export const urls = {
  // API: "http://192.168.1.10/ProcX-Backend/",
  // PHP_API: "http://192.168.1.10/ProcX-Backend/",

  API: "https://procx.co/admin/",
  PHP_API: "https://procx.co/admin/",

  // API: "https://dedevelopers.org/procx/",
  // PHP_API: "https://dedevelopers.org/procx/",

  // API: "http://192.168.1.15/ProcX-Backend/",
  // PHP_API: "http://192.168.1.15/ProcX-Backend/",

  // API: "http://192.168.1.18/procx/",
  // PHP_API: "http://192.168.1.18/procx/",

  // API: "http://192.168.18.80/procx/",
  // PHP_API: "http://192.168.18.80/procx/",
};
