import React, { useState } from "react";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LOGO from "../../assets/images/logo.svg";
import LABX from "../../assets/images/labx.svg";
function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const do_action = async () => {
    if (email == "") {
      toast.error("Please enter your email address!");
      return;
    }
    if (!validateEmail(email)) {
      toast.error("Please enter a valid email address!");
      return false;
    }
    const payload = {
      email: email,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/send_forgot_otp");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        toast.success("OTP has been sent to your email.");
        navigate("/otp", { state: { user_email: email } });
      } else {
        setLoading(false);
        toast.error(data?.data);
      }
    }
  };
  return (
    <div className="container flex_center_page login_light">
      <div className="card p10 w35 text-center">
        <div className="m-auto-60">
          <div className="logo text-center mb-10">
            <img src={LOGO} />
          </div>
          <div className="login_name mb-15">Forgot Password</div>
          <div className="form-group">
            <input
              name="email"
              type="email"
              className="form-control"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="custom_bottom_margin">
            <div className="flex-space-between gap_10">
              <button className="custom_button w50" onClick={() => do_action()}>
                Submit
              </button>
              <button
                className="custom_button w50"
                onClick={() => navigate("/login")}
              >
                Login
              </button>
            </div>
          </div>
        </div>
        <img src={LABX} style={{ marginBottom: "-80px" }} />
      </div>
    </div>
  );
}

export default Login;
