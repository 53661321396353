import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";

Chart.register(ArcElement);

const App = ({ percentage, color }) => {
  let Showing = percentage;

  if (percentage < 0) {
    percentage = 0;
  } else if (percentage > 100) {
    percentage = 100;
  } else {
    percentage = percentage;
  }

  const empty = 100 - percentage;

  const data = {
    datasets: [
      {
        data: [percentage, empty],
        backgroundColor: [color, "#2b2b36"],
        display: true,
        borderColor: "transparent",
      },
    ],
  };

  return (
    <div style={{ position: "relative" }}>
      <Doughnut
        data={data}
        options={{
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          rotation: -90,
          circumference: 180,
          cutout: "65%",
          maintainAspectRatio: true,
          responsive: true,
        }}
      ></Doughnut>
      <div
        style={{
          position: "absolute",
          top: "65%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <div style={{ fontSize: "33px", fontWeight: "700", color: "#7E7E88" }}>
          {Math.round(Showing)}%
        </div>
      </div>
    </div>
  );
};

export default App;
