import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Proof from "../../assets/images/proof.svg";
import Quotation from "../../assets/images/quotation.svg";

import SideBar from "./../../base-components/SideBar";
import Header from "./../../base-components/Header";

function Login() {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const { getLoggedObject, isLoggedIn } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [upload, setUpload] = useState(false);
  const [edit, setEdit] = useState(0);
  const [tabs, setTabs] = useState(1);
  const [delpopup, setDelPopup] = useState(false);
  const [lpo, setLpo] = useState(false);
  const [proof, setProof] = useState(false);
  const [data, setData] = useState([]);
  const [suppliers, setSuppliers] = useState([]);

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (isLoggedIn) {
        await do_get_brands_info();
        setLoading(false);
      }
    };
    fetchData();
  }, [isLoggedIn]);

  const do_get_brands_info = async () => {
    const payload = {
      token: getLoggedObject()?.token,
      id: slugFromUrl,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/do_get_customers_info"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setData(data?.data[0]);
        setSuppliers(data?.orders);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  return (
    <div className="container">
      <div className="wrapper-full">
        <div className="page-content">
          <SideBar />
          <div className="right_side_bar">
            <Header title={"Budget Overview"} />
            <div className="content_inner">
              <div className="">
                <div className="card wd100 p40">
                  <div className="wrap_budget_bar flex-space-between-start">
                    <div className="budget_left">
                      {data.name}
                      <br />
                      {data.parent}
                    </div>
                    <div className="budget_right">
                      <div
                        style={{
                          marginBottom: "5px",
                        }}
                        className="top_nam_budget"
                      >
                        BIF: AED {data.amount_used} /{" "}
                        <span style={{ color: "#908686" }}>
                          AED {data.budget_text}
                        </span>
                      </div>
                      <div>
                        <div className="flex-space-between gap_20 customer_mov">
                          <div className="budget_barrr">
                            <span
                              className={`bar_filled ${
                                data.exceed == 1 ? "redcolor" : ""
                              }`}
                              style={{ width: data.amount_perc + "%" }}
                            ></span>
                          </div>
                          <div className="buget_price">
                            <span style={{ color: "#908686" }}>Balance:</span>{" "}
                            AED {data.budget_text}
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: "15px",
                            marginBottom: "5px",
                          }}
                          className="top_nam_budget"
                        >
                          POS: AED {data.amount_used_pos} /{" "}
                          <span style={{ color: "#908686" }}>
                            AED {data.budget_text_pos}
                          </span>
                        </div>
                        <div className="flex-space-between gap_20 customer_mov">
                          <div className="budget_barrr">
                            <span
                              className={`bar_filled ${
                                data.exceed == 1 ? "redcolor" : ""
                              }`}
                              style={{ width: data.amount_perc_pos + "%" }}
                            ></span>
                          </div>
                          <div className="buget_price">
                            <span style={{ color: "#908686" }}>Balance:</span>{" "}
                            AED {data.budget_text_pos}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="budget_details_customer">
                    <div className="responsive_table">
                      <table
                        className="wd100 table_wrap black_table"
                        style={{ background: "#121212" }}
                      >
                        <tr>
                          <th>Date</th>
                          <th>Product</th>
                          <th>Project</th>
                          <th>KAM</th>
                          <th>Order Type</th>
                          <th></th>
                          <th style={{ width: "100px" }}>Amount (AED)</th>
                        </tr>
                        {suppliers.length == 0 && (
                          <>
                            <tr>
                              <td colSpan={7} className="no_data">
                                No Data found!
                              </td>
                            </tr>
                          </>
                        )}
                        {suppliers.map((v, index) => (
                          <tr>
                            <td>
                              <span className="wrap_flex_td">
                                <span>{v.date}</span>
                              </span>
                            </td>
                            <td>{v.product}</td>
                            <td>{v.project}</td>
                            <td>{v.kam}</td>
                            <td>{v.type}</td>
                            <td>
                              ..............................................
                            </td>
                            <td>{v.amount}</td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </div>
                </div>

                {lpo && (
                  <>
                    <div className="popup_outer">
                      <div
                        className="popup_inner cursor"
                        onClick={() => setLpo(false)}
                      >
                        <div className="">
                          <div className="form_setcion">
                            <img src={Quotation} style={{ width: "430px" }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {proof && (
                  <>
                    <div className="popup_outer">
                      <div
                        className="popup_inner cursor"
                        onClick={() => setProof(false)}
                      >
                        <div className="">
                          <div className="form_setcion">
                            <img src={Proof} style={{ width: "100%" }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
