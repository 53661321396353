import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import LOGO from "../../assets/images/logo.svg";
import Sun from "../../assets/images/sun.svg";
import Moon from "../../assets/images/moon.svg";
import Shop from "../../assets/images/shop.svg";
import useAuth from "./../../hooks/useAuth";

import Dashboard from "../../assets/images/dashboard.svg";
import Order from "../../assets/images/cart.svg";
import Product from "../../assets/images/product.svg";
import Vendor from "../../assets/images/vendor.svg";
import Customer from "../../assets/images/customer.svg";
import Settings from "../../assets/images/setting.svg";
import Logout from "../../assets/images/logout.svg";
import {
  doPost,
  check_role_restrictions,
  doPostImage,
} from "./../../utils/apiCalls";
import ImagesPop from "../ImagesPopup";

function SideBar() {
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const navigate = useNavigate();
  const [darkmode, setDarkMode] = useState(2);
  const [role, setRole] = useState(0);
  const [customerlogo, setCustomerImage] = useState("");

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  useEffect(() => {
    const fetchData = async () => {
      if (isLoggedIn) {
        try {
          const response = await check_role_restrictions();
          setRole(response);
        } catch (error) {
          console.error("Error:", error);
        }
      }
    };
    fetchData();
  }, [isLoggedIn]);

  useEffect(() => {
    const DarkValue = localStorage.getItem("DarkMode");
    if (DarkValue === null || parseInt(DarkValue) === 0) {
      setDarkMode(2);
    } else {
      setDarkMode(1);
    }
  }, []);

  const do_make_action = () => {
    const collapseValue = localStorage.getItem("Collapse");
    if (collapseValue === null || parseInt(collapseValue) === 0) {
      localStorage.setItem("Collapse", "1");
      document.body.classList.add("nocollapse");
    } else {
      localStorage.removeItem("Collapse");
      document.body.classList.remove("nocollapse");
    }
  };

  const do_make = () => {
    document.body.classList.remove("mobile_view_p");
  };

  const set_dark_mode = (val) => {
    setDarkMode(val);
    if (val === 1) {
      localStorage.setItem("DarkMode", "1");
      document.body.classList.add("darkmind");
    } else {
      localStorage.removeItem("DarkMode");
      document.body.classList.remove("darkmind");
    }
  };

  const do_logout = async () => {
    setLoggedObject(null);
    localStorage.removeItem("PROCXAUTH");
    toast.success("You're logout successfully!");
    window.location.href = `${window.location.origin}/login`;
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    const payload = {
      token: getLoggedObject()?.token,
    };
    const { isError, data } = await doPost(payload, "auth/get_company_data");
    if (isError) {
      toast.error("Something went wrong with server.");
    } else {
      if (data.action == "success") {
        setCustomerImage(data?.data);
      } else {
        toast.error(data?.error);
      }
    }
  };

  return (
    <>
      <div className="left_bar_fixed">
        {getLoggedObject()?.ac_type == 9 && (
          <>
            <style>{`.main-navigation { margin-top: 0px; }`}</style>
          </>
        )}
        <div className="text-center dashboard_logo">
          <Link to={"/"}>
            <img style={{ cursor: "pointer" }} src={LOGO} />
          </Link>
        </div>
        {role != 5 && (
          <>
            {getLoggedObject()?.ac_type != 9 && (
              <div className="navigation">
                <div className="heading">
                  <img src={Shop} />
                  <span>Shop</span>
                </div>
                <div className="sub_nav">
                  <Link
                    to="/products"
                    onClick={() => do_make()}
                    className="inner_nav"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6"
                      height="9"
                      viewBox="0 0 6 9"
                      fill="none"
                    >
                      <path
                        d="M1.33008 1.30359L5.33008 4.80359L1.33008 8.30359"
                        stroke="#87888C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Stock Items</span>
                  </Link>
                  <Link
                    to="/beskpoke/project"
                    onClick={() => do_make()}
                    className="inner_nav"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6"
                      height="9"
                      viewBox="0 0 6 9"
                      fill="none"
                    >
                      <path
                        d="M1.33008 1.30359L5.33008 4.80359L1.33008 8.30359"
                        stroke="#87888C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>Bespoke Project</span>
                  </Link>
                  <Link
                    to="/pos"
                    onClick={() => do_make()}
                    className="inner_nav"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6"
                      height="9"
                      viewBox="0 0 6 9"
                      fill="none"
                    >
                      <path
                        d="M1.33008 1.30359L5.33008 4.80359L1.33008 8.30359"
                        stroke="#87888C"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>POS Orders</span>
                  </Link>
                </div>
              </div>
            )}
          </>
        )}
        <div className="main-navigation">
          <Link
            to={
              getLoggedObject()?.ac_type == 9
                ? "/dashboard/suppliers"
                : "/dashboard"
            }
            onClick={() => do_make()}
            className={`nav_main ${
              slugFromUrl == "dashboard" || slugFromUrl == "suppliers"
                ? "nav_main_active"
                : ""
            }`}
          >
            <img src={Dashboard} /> <span>Dashboard</span>
          </Link>
          <Link
            to={
              getLoggedObject()?.ac_type == 9 ? "/suppliers_orders" : "/orders"
            }
            onClick={() => do_make()}
            className={`nav_main ${
              slugFromUrl == "orders" || slugFromUrl == "suppliers_orders"
                ? "nav_main_active"
                : ""
            }`}
          >
            <img src={Order} /> <span>Order Mgt. </span>
          </Link>
          {getLoggedObject()?.ac_type != 9 && role != 5 && (
            <>
              <Link
                to="/products"
                onClick={() => do_make()}
                className={`nav_main ${
                  slugFromUrl == "products" ? "nav_main_active" : ""
                }`}
              >
                <img src={Product} /> <span>Products</span>
              </Link>
              {getLoggedObject()?.ac_type != 4 &&
                getLoggedObject()?.ac_type != 3 && (
                  <Link
                    to="/vendors"
                    onClick={() => do_make()}
                    className={`nav_main ${
                      slugFromUrl == "vendors" ? "nav_main_active" : ""
                    }`}
                  >
                    <img src={Vendor} /> <span>Vendor Mgt. </span>
                  </Link>
                )}
              <Link
                to="/customers"
                onClick={() => do_make()}
                className={`nav_main ${
                  slugFromUrl == "customers" ? "nav_main_active" : ""
                }`}
              >
                <img src={Customer} /> <span>Customer Mgt.</span>
              </Link>
            </>
          )}
        </div>

        <div className="customer_logo">
          <img src={customerlogo} />
        </div>
        <div className="dark_mode">
          <Link
            className={darkmode === 1 ? "dark_mode_active" : ""}
            onClick={() => set_dark_mode(1)}
          >
            <img src={Sun} />
            <div className="no_mobile">Light</div>
          </Link>
          <Link
            className={darkmode == 2 ? "dark_mode_active" : ""}
            onClick={() => set_dark_mode(2)}
          >
            <img src={Moon} />
            <div className="no_mobile">Dark</div>
          </Link>
        </div>

        <div className="bottom-nav">
          {(getLoggedObject()?.ac_type == -1 ||
            getLoggedObject()?.ac_type == 2) && (
            <>
              {slugFromUrl != "suppliers" &&
                slugFromUrl != "suppliers_orders" && (
                  <Link
                    to="/settings"
                    onClick={() => do_make()}
                    className={`nav_main ${
                      slugFromUrl == "settings" ? "nav_main_active" : ""
                    }`}
                  >
                    <img src={Settings} /> <span>Settings</span>
                  </Link>
                )}
            </>
          )}
          <Link onClick={() => do_logout()} className="nav_main">
            <img src={Logout} /> <span>Signout</span>
          </Link>
        </div>
      </div>
    </>
  );
}
export default SideBar;
