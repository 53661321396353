import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import {Link, useNavigate} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import LOGO from './../../assets/images/logo.svg';
import Profile from './../../assets/images/pro_image.svg';
import DownArrow from './../../assets/images/down_arrow.svg';
import BellIcon from './../../assets/images/bell_icon.svg';
import SearchIcon from './../../assets/images/search_icon.svg';
// import Profile from './../../assets/images/pro_image.svg';
// import Profile from './../../assets/images/pro_image.svg';
// import Profile from './../../assets/images/pro_image.svg';

import { AiOutlineLogout } from "react-icons/ai";
import useAuth from "./../../hooks/useAuth";
import { doPost, check_role_restrictions  } from "./../../utils/apiCalls";
import { Tooltip } from 'react-tooltip';


function Graph({firstchild="Order", c1, c2=0, c3=0, c4=0, c5=0, c6=0, hover=[], show_lpopp, show_proofp}) {
    const navigate = useNavigate();
    const {getLoggedObject, setLoggedObject, isLoggedIn, checkLogin} = useAuth();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null)

    const do_show_lpo = () => {
        show_lpopp()
    }

    const do_show_profp = () => {
        show_proofp()
    }


    return(
        <>
           <span className="dots_outer">
                    <span className="dots" data-tooltip-id="c1" data-tooltip-html={hover.length>0 && hover[0].content}>
                        <span className="dots_border">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                            <circle cx="9.5" cy="9.5" r="9.5" fill="#D9D9D9"/>
                            <circle cx="9.5" cy="9.5" r="6" fill={c1 === 1 ? '#32B4A3' : c1 === 2 ? '#C1BB27' : ''} stroke="black"/>
                            </svg>
                        </span>
                        </span>
                        <span className="border_title">{'Order'}</span>
                        {
                            hover.length > 0 &&
                            <Tooltip id="c1" style={{ backgroundColor: "#5ca399"}} />
                        }
                    </span>
                    <span onClick={()=> hover.length>2 && do_show_lpo()} className="dots" data-tooltip-id="c2" data-tooltip-html={hover.length>1 && hover[1].content}>
                        <span className="dots_border">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                            <circle cx="9.5" cy="9.5" r="9.5" fill="#D9D9D9"/>
                            <circle cx="9.5" cy="9.5" r="6" fill={c2 === 1 ? '#32B4A3' : c2 === 2 ? '#C1BB27' : ''} stroke="black"/>
                            </svg>
                        </span>
                        </span>
                        <span className="border_title">LPO</span>
                        {
                            hover.length > 1 &&
                            <Tooltip id="c2" style={{ backgroundColor: "#5ca399"}} />
                        }
                    </span>
                    <span onClick={()=> hover.length>3 && do_show_profp()} className="dots" data-tooltip-id="c3" data-tooltip-html={hover.length>2&&hover[2].content}>
                        <span className="dots_border">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                            <circle cx="9.5" cy="9.5" r="9.5" fill="#D9D9D9"/>
                            <circle cx="9.5" cy="9.5" r="6" fill={ hover.length>2&&hover[2].reject == 1? 'red' :c3 === 1 ? '#32B4A3' : c3 === 2 ? '#C1BB27' : ''} stroke="black"/>
                            </svg>
                        </span>
                        </span>
                        <span className="border_title">Proof</span>
                        {
                            hover.length > 2 &&
                            <Tooltip id="c3" style={{ backgroundColor: "#5ca399"}} />
                        }
                    </span>
                    <span className="dots" data-tooltip-id="c4" data-tooltip-html={hover.length>3&&hover[3].content}>
                        <span className="dots_border">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                            <circle cx="9.5" cy="9.5" r="9.5" fill="#D9D9D9"/>
                            <circle cx="9.5" cy="9.5" r="6" fill={
                                hover.length>3&&hover[3].reject == 1? 'red' :c4 === 1 ? '#32B4A3' : c4 === 2 ? '#C1BB27' : ''} stroke="black"/>
                            </svg>
                        </span>
                        </span>
                        <span className="border_title">Approval</span>
                        {
                            hover.length > 3 &&
                            <Tooltip id="c4" style={{ backgroundColor: "#5ca399"}} />
                        }
                    </span>
                    <span className="dots" data-tooltip-id="c5" data-tooltip-html={hover.length>4&&hover[4].content}>
                        <span className="dots_border">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                            <circle cx="9.5" cy="9.5" r="9.5" fill="#D9D9D9"/>
                            <circle cx="9.5" cy="9.5" r="6" fill={c5 === 1 ? '#32B4A3' : c5 === 2 ? '#C1BB27' : ''} stroke="black"/>
                            </svg>
                        </span>
                        </span>
                        <span className="border_title">Production</span>
                        {
                            hover.length > 4 &&
                            <Tooltip id="c5" style={{ backgroundColor: "#5ca399"}} />
                        }
                    </span>
                    <span className="dots" data-tooltip-id="c6" data-tooltip-html={hover.length>5&&hover[5].content}>
                        <span className="dots_border">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                            <circle cx="9.5" cy="9.5" r="9.5" fill="#D9D9D9"/>
                            <circle cx="9.5" cy="9.5" r="6" fill={c6 === 1 ? '#32B4A3' : c6 === 2 ? '#C1BB27' : ''} stroke="black"/>
                            </svg>
                        </span>
                        </span>
                        <span className="border_title">Delivery</span>
                        {
                            hover.length > 5 &&
                            <Tooltip id="c6" style={{ backgroundColor: "#5ca399", width:"120px", textAlign:"center"}}  />
                        }
                    </span>
                    </span>
        </>
    )
}
export default Graph;