import React, { useEffect, useState } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import SideBar from "./../../base-components/SideBar";
import Header from "./../../base-components/Header";

function UpdateCoustomer() {
  const { id } = useParams();
  console.log(id);
  const navigate = useNavigate();
  const { getLoggedObject } = useAuth();
  const [accountname, setAccountName] = useState("");
  const [contact, setContact] = useState("");
  const [contactnumber, setContactNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(0);
  const [projects, setProjects] = useState([]);
  const [btnloading, setBtnLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setEdit(id);
      setLoading(true);
      get_user_customer_data(id);
    }
  }, [id]);

  const get_user_customer_data = async (val) => {
    const payload = {
      token: getLoggedObject()?.token,
      id: val,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/do_get_customer_data"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setAccountName(data?.data.name);
        setContact(data?.data.contact);
        setContactNumber(data?.data.phone);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const do_get_brands_info = async () => {
    const payload = {
      token: getLoggedObject()?.token,
      roleu: 4,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/do_get_specific_user"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setProjects(data?.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const handle_submit = async () => {
    if (accountname == "") {
      toast.error("Please enter account name!");
      return;
    }
    if (contact == "") {
      toast.error("Please enter contact!");
      return;
    }
    if (contactnumber == 0) {
      toast.error("Please enter contact number!");
      return;
    }

    const payload = {
      accountname: accountname,
      contact: contact,
      contactnumber: contactnumber,
      token: getLoggedObject()?.token,
      edit: edit,
    };
    setBtnLoading(true);
    const { isError, data } = await doPost(
      payload,
      "auth/submit_customer_small"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setBtnLoading(false);
    } else {
      if (data.action == "success") {
        toast.success(
          edit == 0
            ? "Customer information has been added successfully!"
            : "Customer information updated successfully!"
        );
        await do_get_brands_info();
        setAccountName("");
        setContact("");
        setContactNumber("");
        setEdit(0);
      } else {
        setBtnLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const regex = /^[0-9\b]+$/;
    if (value === "" || regex.test(value)) {
      setContactNumber(value);
    }
  };

  return (
    <div className="container">
      <div className="wrapper-full">
        <div className="page-content">
          <SideBar />
          <div className="right_side_bar">
            <Header title={"Update Customer Details"} />

            <div className="content_inner">
              <div className="content_settings">
                <div style={{ margin: "auto" }} className="right_settings_box">
                  <div className="form_setcion">
                    <div className="double_in_row">
                      <div className="form-group ">
                        <label>Account Name</label>
                        <input
                          type="text"
                          name="service"
                          className="setting_input"
                          placeholder="Please enter Account name"
                          value={accountname}
                          onChange={(e) => setAccountName(e.target.value)}
                        />
                      </div>

                      <div className="form-group ">
                        <label>Contact</label>
                        <input
                          type="text"
                          name="contact_name"
                          className="setting_input"
                          placeholder="Please enter Contact name"
                          value={contact}
                          onChange={(e) => setContact(e.target.value)}
                        />
                      </div>

                      <div className="form-group ">
                        <label>Contact Number</label>
                        <input
                          type="text"
                          name="contact_number"
                          className="setting_input"
                          placeholder="Please enter contact number"
                          value={contactnumber}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-20px" style={{ marginBottom: "40px" }}>
                    <button
                      type="button"
                      className="button_submit"
                      onClick={() => handle_submit()}
                    >
                      {edit == 0 ? "Submit" : "Update"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateCoustomer;
