import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import UploadIcon from "../../assets/images/image_icon_upload.svg";
import Select from "react-select";

import SideBar from "./../../base-components/SideBar";
import Header from "./../../base-components/Header";
import { AiFillCloseCircle } from "react-icons/ai";
import Loader from "../../base-components/loader";
function Login() {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const { getLoggedObject } = useAuth();
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  const [imagedata, setImageData] = useState("");
  const [image, setImage] = useState("");

  const [imagedata2, setImageData2] = useState("");
  const [image2, setImage2] = useState("");
  const [imagedata3, setImageData3] = useState("");
  const [image3, setImage3] = useState("");
  const [imagedata4, setImageData4] = useState("");
  const [image4, setImage4] = useState("");

  const [file, setFile] = useState(0);

  const [projectname, setProjectName] = useState("");

  const [kamselect, setKamSelect] = useState("");
  const [kamslist, setKamsList] = useState([]);
  const [short, setShort] = useState("");
  const [dimension, setDimension] = useState("");
  const [customer, setCustomer] = useState("");
  const [allcustomer, setAllCustomers] = useState([]);

  const [brand, setBrand] = useState("");
  const [allbrand, setAllBrand] = useState([]);
  const [budget, setBudget] = useState("");

  const [deadline, setDeadline] = useState("");
  const [request, setRequest] = useState("");
  const [sitevisit, setSiteVisit] = useState("");
  console.log("🚀 ~ Login ~ sitevisit:", sitevisit);

  const [brief, setBrief] = useState("");
  const [wetransfer, setWeTransfer] = useState("");
  const [Options, setOptions] = useState([]);
  const [darkmode, setDarkMode] = useState(null);

  // React Select Style
  //

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      border: `${darkmode === null ? "1px solid #E0E4EC" : ""} `,
      background: `${darkmode === null ? "#21222D" : "#D7D7D7"} `,
      height: "44px",
      width: "100%",
      boxSizing: "border-box",
      boxShadow: state.isFocused ? "none" : "none",
      fontSize: "12px",
      color: "#FFF",

      "&:hover": { borderColor: "" },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: `${darkmode === null ? "#ffffff8f" : "#4c535f"} `,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: `${darkmode === null ? "#ffffff8f" : "#4c535f"} `,
    }),
    menu: (provided, state) => ({
      ...provided,
      background: `${darkmode === null ? "#21222D" : "#D7D7D7"} `,
      color: "#FFF",
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected
        ? `${darkmode === null ? "#4D4F62" : "#4D4F62"}`
        : state.isFocused
        ? "transparent"
        : "transparent",
      color: state.isSelected ? "#FFF" : "#4c535f",
      "&:hover": {
        background: "#4D4F62",
        color: "#FFF",
      },
    }),
    input: (provided) => ({
      ...provided,
      color: `${darkmode === null ? "#ffffff8f" : "#4c535f"}`,
    }),
  };

  const DarkValue = localStorage.getItem("DarkMode");
  useEffect(() => {
    setDarkMode(DarkValue);
  }, [DarkValue]);

  console.log("🚀 ~ Login ~ darkmode:", darkmode);

  const do_click_file = (val) => {
    fileInputRef.current.click();
    setFile(val);
  };
  const onFileChange = (e) => {
    let files = e.target.files;
    if (files.length > 0) {
      let fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      fileReader.onload = (event) => {
        if (file == 1) {
          setImageData(event.target.result);
        } else if (file == 2) {
          setImageData2(event.target.result);
        } else if (file == 3) {
          setImageData3(event.target.result);
        } else if (file == 4) {
          setImageData4(event.target.result);
        }
      };
    }
  };

  useEffect(() => {
    if (getLoggedObject()?.ac_type == 9) {
      window.location.href = `${window.location.origin}/suppliers_orders`;
      return;
    } else {
      // if(getLoggedObject()?.ac_type == 4){
      //   setKamSelect(getLoggedObject()?.id);
      //   if(kamslist.length > 0){
      //   const selectedItem = kamslist.find(item => item.id === kamselect);
      //   setkamDisplay(selectedItem.name);
      //   }
      // }
      fetchData();
    }
  }, [slugFromUrl]);

  const fetchData = async () => {
    const payload = {
      token: getLoggedObject()?.token,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/fetch_all_data_need_beskpok"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setKamsList(data?.kam);
        setAllCustomers(data?.customer);
        setAllBrand(data?.brand);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const do_show_popup = () => {
    if (
      imagedata == "" &&
      imagedata2 == "" &&
      imagedata3 == "" &&
      imagedata4 == ""
    ) {
      toast.error("Please upload atleast one product image!");
      return;
    }
    if (projectname == "") {
      toast.error("Please enter project name!");
      return;
    }
    // if(kamselect==""){
    //   toast.error('Please select a KAM!');
    //   return;
    // }
    if (short == "") {
      toast.error("Please enter short description!");
      return;
    }
    if (dimension == "") {
      toast.error("Please enter dimension/color!");
      return;
    }
    if (customer == "") {
      toast.error("Please select a customer!");
      return;
    }
    // if (brand == "") {
    //   toast.error("Please select a brand!");
    //   return;
    // }
    if (budget == "") {
      toast.error("Please enter budget!");
      return;
    }
    if (deadline == "") {
      toast.error("Please enter deadline!");
      return;
    }
    if (request == "") {
      toast.error("Please select request type!");
      return;
    }
    if (brief == "") {
      toast.error("Please enter brief of project!");
      return;
    }

    // console.log(kamselect)
    setPopup(true);
  };

  const [kamdisplay, setkamDisplay] = useState("");
  // useEffect(() => {
  //   if(kamselect != ""){
  //       const selectedItem = kamslist.find(item => item.id === kamselect);
  //       setkamDisplay(selectedItem.name);
  //   }
  // }, [kamselect]);

  const [customerdata, setCustomerData] = useState("");
  useEffect(() => {
    if (customer != "") {
      const selectedItem = allcustomer.find((item) => item.id === customer);
      setCustomerData(selectedItem);
    }
  }, [customer]);

  const [brandata, setBranData] = useState("");
  useEffect(() => {
    if (brand != "") {
      const selectedItem = allbrand.find((item) => item.id === brand);
      setBranData(selectedItem.title);
    }
  }, [brand]);

  const [Bespokeloading, setBespokeloading] = useState(false);

  const do_submit_beskpok = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    setBespokeloading(true);

    const payload = {
      token: getLoggedObject()?.token,
      image: imagedata,
      image2: imagedata2,
      image3: imagedata3,
      image4: imagedata4,
      name: projectname,
      kam: kamselect,
      short: short,
      dimension: dimension,
      customer: customer,
      brand: brand,
      budget: budget,
      deadline: deadline,
      wetransfer: wetransfer,
      sitevisit: `${sitevisit ? "1" : "0"}`,
      request: request,
      brief: brief,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/submit_beskpok_order"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
      setBespokeloading(false);
    } else {
      if (data.action == "success") {
        toast.success("Beskpok order created successfully!");
        setLoading(false);
        navigate("/orders?tab=3");
        setBespokeloading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
        setBespokeloading(false);
      }
    }
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowFormatted = tomorrow.toISOString().split("T")[0];

  const handleCustomerChange = (selectedOption) => {
    setCustomer(selectedOption.id);
  };

  useEffect(() => {
    let options = allcustomer.map((item) => {
      return {
        value: item?.accountname,
        label: item?.accountname,
        id: item?.id,
      };
    });
    setOptions(options);
  }, [allcustomer]);

  const handleDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const today = new Date();
    today.setDate(today.getDate() + 1);

    if (selectedDate < today) {
      setDeadline("");
      toast.error("Please select future dates only.");
    } else {
      setDeadline(e.target.value);
    }
  };

  return (
    <div className="container">
      <div className="wrapper-full">
        <div className="page-content">
          <SideBar />
          <div className="right_side_bar">
            <Header title={"Beskpoke Project"} />

            <div className="content_inner">
              <div className="content_settings card p30 wd100">
                <div className="right_settings_box custom_mar_auto">
                  <div className="form_setcion beskop_set">
                    <div className="form-group">
                      <div className="image_upload_flex">
                        <div
                          className="image_browse_button"
                          onClick={() => do_click_file(1)}
                        >
                          {imagedata == "" && image == "" ? (
                            <>
                              <img src={UploadIcon} />
                              <span>
                                {" "}
                                Upload <br />
                                Image
                              </span>
                            </>
                          ) : (
                            <>
                              <div className="image_upl_file">
                                <img src={imagedata || image} />
                              </div>
                            </>
                          )}
                        </div>
                        <div
                          className="image_browse_button"
                          onClick={() => do_click_file(2)}
                        >
                          {imagedata2 == "" && image2 == "" ? (
                            <>
                              <img src={UploadIcon} />
                              <span>
                                {" "}
                                Upload <br />
                                Image
                              </span>
                            </>
                          ) : (
                            <>
                              <div className="image_upl_file">
                                <img src={imagedata2 || image2} />
                              </div>
                            </>
                          )}
                        </div>
                        <div
                          className="image_browse_button"
                          onClick={() => do_click_file(3)}
                        >
                          {imagedata3 == "" && image3 == "" ? (
                            <>
                              <img src={UploadIcon} />
                              <span>
                                {" "}
                                Upload <br />
                                Image
                              </span>
                            </>
                          ) : (
                            <>
                              <div className="image_upl_file">
                                <img src={imagedata3 || image3} />
                              </div>
                            </>
                          )}
                        </div>
                        <div
                          className="image_browse_button"
                          onClick={() => do_click_file(4)}
                        >
                          {imagedata4 == "" && image4 == "" ? (
                            <>
                              <img src={UploadIcon} />
                              <span>
                                {" "}
                                Upload <br />
                                Image
                              </span>
                            </>
                          ) : (
                            <>
                              <div className="image_upl_file">
                                <img src={imagedata4 || image4} />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <input
                        type="file"
                        name="logo"
                        id="logo"
                        accept=".png, .jpg, .jpeg"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={onFileChange}
                      />
                    </div>
                    <div className="double_in_row">
                      <div className="form-group ">
                        <label>Project Name*</label>
                        <input
                          type="text"
                          name="company_name"
                          className="setting_input"
                          placeholder=""
                          value={projectname}
                          onChange={(e) => setProjectName(e.target.value)}
                        />
                      </div>
                      {/* <div className="form-group">
                                <label>KAM</label>
                                <select name="category" className="setting_input" value={kamselect} onChange={(e)=>setKamSelect(e.target.value)}>
                                    <option value={''}>--Select KAM--</option>
                                    {
                                        kamslist.map((v, i)=>(
                                            <>
                                                <option value={v.id}>{v.name}</option>
                                            </>
                                        ))
                                    }
                                </select>
                            </div> */}
                      <div className="form-group ">
                        <label>Short Description</label>
                        <input
                          type="text"
                          name="product_price"
                          className="setting_input"
                          placeholder=""
                          value={short}
                          onChange={(e) => setShort(e.target.value)}
                        />
                      </div>

                      <div className="form-group ">
                        <label>Dimensions / Color</label>
                        <input
                          type="text"
                          name="stock"
                          className="setting_input"
                          placeholder=""
                          value={dimension}
                          onChange={(e) => setDimension(e.target.value)}
                        />
                      </div>

                      <div className="form-group">
                        <label>Customer</label>
                        {/* <select name="category" className="setting_input" value={customer} onChange={(e) => setCustomer(e.target.value)}>
                          <option value={''}>--Select Customer--</option>
                          {
                            allcustomer.map((v, i) => (
                              <>
                                <option value={v.id}>{v.accountname}</option>
                              </>
                            ))
                          }
                        </select> */}
                        <Select
                          styles={customStyles}
                          value={Options.find(
                            (option) => option.value === customer
                          )}
                          onChange={handleCustomerChange}
                          options={Options}
                          placeholder="--Select Customer--"
                        />
                      </div>
                      {/* 
                      <div className="form-group">
                        <label>Brand</label>
                        <select
                          name="category"
                          className="setting_input"
                          value={brand}
                          onChange={(e) => setBrand(e.target.value)}
                        >
                          <option value={""}>--Select Brand--</option>
                          {allbrand.map((v, i) => (
                            <>
                              <option value={v.id}>{v.title}</option>
                            </>
                          ))}
                        </select>
                      </div> */}

                      <div className="form-group ">
                        <label>Budget</label>
                        <input
                          type="number"
                          name="budget"
                          className="setting_input"
                          placeholder=""
                          value={budget}
                          onChange={(e) => setBudget(e.target.value)}
                        />
                      </div>

                      <div className="form-group ">
                        <label>Deadline</label>
                        <input
                          type="date"
                          min={tomorrowFormatted}
                          name="deadline"
                          className="setting_input"
                          placeholder=""
                          value={deadline}
                          onChange={handleDateChange}
                        />
                      </div>
                      {/* <div className="form-group ">
                        <label>Artwork Download link</label>
                        <input
                          type="url"
                          name="link"
                          className="setting_input"
                          placeholder=""
                          value={wetransfer}
                          onChange={(e) => setWeTransfer(e.target.value)}
                        />
                      </div> */}

                      <div className="form-group">
                        <label>Request Type</label>
                        <select
                          name="category"
                          className="setting_input"
                          value={request}
                          onChange={(e) => setRequest(e.target.value)}
                        >
                          <option value={""}>--Select Request Type--</option>
                          <option value={"Wall branding"}>Wall branding</option>
                          <option value={"Bar counter refurb"}>
                            Bar counter refurb
                          </option>
                          <option value={"Cube"}>Cube</option>
                          <option value={"Custom-made promotion kit"}>
                            Custom-made promotion kit
                          </option>
                          <option value={"Outlet take over"}>
                            Outlet take over
                          </option>
                          <option value={"Beer garden"}>Beer garden</option>
                          <option value={"Furniture"}>Furniture</option>
                          <option value={"Branded badges"}>
                            Branded badges
                          </option>
                          <option value={"Co-branded coaster"}>
                            Co-branded coaster
                          </option>
                          <option value={"Co-branded menu holder"}>
                            Co-branded menu holder
                          </option>
                          <option value={"Co-branded Staff uniform"}>
                            Co-branded Staff uniform
                          </option>
                        </select>
                      </div>

                      <div className="form-group wd100">
                        <label
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            val="1"
                            style={{ marginRight: "10px" }}
                            value={sitevisit}
                            onChange={(e) => setSiteVisit(e.target.checked)}
                          />
                          <span>Site visit</span>
                        </label>
                      </div>
                    </div>

                    <div className="form-group custom-mar-textarea">
                      <label>Brief</label>
                      <textarea
                        name="description"
                        placeholder=""
                        className="setting_input textarea_input"
                        value={brief}
                        onChange={(e) => setBrief(e.target.value)}
                      ></textarea>
                    </div>
                  </div>

                  <div className="mt-20px" style={{ marginBottom: "40px" }}>
                    <button
                      type="button"
                      disabled={Bespokeloading}
                      className="button_submit"
                      onClick={() => do_show_popup()}
                    >
                      {Bespokeloading ? <Loader /> : "submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popup && (
        <>
          <div className="popup_outer">
            <div className="popup_inner">
              <div className="popup_white_center custom_big">
                <div className="modal_heading">
                  <span></span>
                  <span className="close_icon" onClick={() => setPopup(false)}>
                    <AiFillCloseCircle />
                  </span>
                </div>
                <div className="form_setcion popup_bespoke">
                  <div
                    className=" flex-space-between"
                    style={{ justifyContent: "flex-start", gap: "30px" }}
                  >
                    {imagedata != "" && (
                      <img
                        src={imagedata || image}
                        style={{ width: "120px" }}
                      />
                    )}
                    {imagedata2 != "" && (
                      <img
                        src={imagedata2 || image2}
                        style={{ width: "120px" }}
                      />
                    )}
                    {imagedata3 != "" && (
                      <img
                        src={imagedata3 || image3}
                        style={{ width: "120px" }}
                      />
                    )}
                    {imagedata4 != "" && (
                      <img
                        src={imagedata4 || image4}
                        style={{ width: "120px" }}
                      />
                    )}
                  </div>
                  <div className="info_popup">
                    <p>{projectname}</p>
                    <p>{brief}</p>
                  </div>

                  <div className="beskpoke_customer flex-space-between">
                    <div className="beskpok_righ_cust_left">
                      {/* <div className="customer_beskpoksss">
                        <span className="besk1">Brand</span>
                        <span>{brand.title}</span>
                      </div> */}
                      <div className="customer_beskpoksss">
                        <span className="besk1">Description</span>
                        <span>{short} </span>
                      </div>
                      <div className="customer_beskpoksss">
                        <span className="besk1">Dimension/Color: </span>
                        <span>{dimension} </span>
                      </div>
                      {/* <div className="customer_beskpoksss">
                                                <span className="besk1">KAM</span>
                                                <span>
                                                    {kamdisplay}
                                                </span>
                                            </div> */}
                      <div className="customer_beskpoksss">
                        <span className="besk1">Request Type</span>
                        <span>{request}</span>
                      </div>
                    </div>
                    <div className="card p10 beskpok_righ_cust">
                      <u
                        style={{
                          fontSize: "14px",
                          marginBottom: "20px",
                          display: "block",
                        }}
                      >
                        Customer Details:
                      </u>
                      <div className="customer_beskpoksss">
                        <span className="besk1">Account Number:</span>
                        <span>{customerdata.accoutnumber}</span>
                      </div>
                      <div className="customer_beskpoksss">
                        <span className="besk1">Account Name:</span>
                        <span>{customerdata.accountname}</span>
                      </div>
                      <div className="customer_beskpoksss">
                        <span className="besk1">Parent Account:</span>
                        <span>{customerdata.parentaccount}</span>
                      </div>
                      <div className="customer_beskpoksss">
                        <span className="besk1">Contact</span>
                        <span>
                          {customerdata.contact}
                          <br />
                          {customerdata.contactnumber}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="button_sub_box popup_beskpok_button">
                    <button
                      className="reject_button"
                      onClick={() => setPopup(false)}
                    >
                      Cancel
                    </button>
                    <button
                      disabled={Bespokeloading}
                      className="approve_button"
                      onClick={() => do_submit_beskpok()}
                    >
                      {Bespokeloading ? <Loader /> : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Login;
