import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Quotation from "../../assets/images/quotation.svg";
import SideBar from "./../../base-components/SideBar";
import Header from "./../../base-components/Header";
import { AiOutlineEye, AiOutlineDownload } from "react-icons/ai";
import Loader from "../../base-components/loader";

function Login() {
  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [lpo, setLpo] = useState(false);
  const [proof, setProof] = useState(false);

  const [data, setData] = useState([]);
  console.log("🚀 ~ Login ~ data:", data);

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  useEffect(() => {
    if (
      getLoggedObject()?.ac_type == -1 ||
      getLoggedObject()?.ac_type == 2 ||
      getLoggedObject()?.ac_type == 3
    ) {
    } else {
      window.location.href = `${window.location.origin}`;
      return;
    }
    if (getLoggedObject()?.ac_type == 9 || getLoggedObject()?.ac_type == 4) {
      window.location.href = `${window.location.origin}/orders`;
      return;
    } else {
      fetchOrders();
    }
  }, [slugFromUrl]);

  const fetchOrders = async () => {
    const payload = {
      token: getLoggedObject()?.token,
      type: getLoggedObject()?.ac_type == 9 ? 2 : 0,
      id: slugFromUrl,
    };
    const { isError, data } = await doPost(payload, "auth/get_all_quotations");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setData(data?.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const do_show_proof = (val) => {
    setProof(true);
    setPassword(val);
  };

  const handledownload = async (val) => {
    try {
      const response = await fetch(val);
      const contentType = response.headers.get("content-type");
      const extension = contentType.split("/")[1];
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `filename.${extension}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const [Rejectloading, setRejectloading] = useState(false);

  const reject_quotation = async (id) => {
    const payload = {
      token: getLoggedObject()?.token,
      type: getLoggedObject()?.ac_type == 9 ? 2 : 0,
      id: id,
    };
    setRejectloading(true);
    const { isError, data } = await doPost(payload, "auth/reject_quotation");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
      setRejectloading(false);
    } else {
      if (data.action == "success") {
        fetchOrders();
        toast.success("Quotation Rejected!");
        setLoading(false);
        setRejectloading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
        setRejectloading(false);
      }
    }
  };

  const [Acceptloading, setAcceptloading] = useState(false);

  const accept_quotation = async (id) => {
    const payload = {
      token: getLoggedObject()?.token,
      type: getLoggedObject()?.ac_type == 9 ? 2 : 0,
      id: id,
    };
    setAcceptloading(true);
    const { isError, data } = await doPost(payload, "auth/accept_quotation");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
      setAcceptloading(false);
    } else {
      if (data.action == "success") {
        fetchOrders();
        toast.success("Quotation accepted & notifed to TMM for LPO!");
        setLoading(false);
        setAcceptloading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
        setAcceptloading(false);
      }
    }
  };

  return (
    <div className="container">
      <div className="wrapper-full">
        <div className="page-content">
          <SideBar />
          <div className="right_side_bar">
            <Header title={"Orders Management"} />

            <div className="content_inner">
              <div className="">
                <div className="vendor_submission">Vendor Submissions</div>

                {data.length == 0 && (
                  <>
                    <div
                      className="no_text_found"
                      style={{ marginTop: "50px" }}
                    >
                      No quotation(s) found!
                    </div>
                  </>
                )}
                {data.map((v, i) => (
                  <>
                    <div className="card wd100 p20 mb-15">
                      <div className="wrap_inner_submission">
                        {getLoggedObject()?.ac_type != 3 && (
                          <>
                            <div className="vendor_sub_boxes">
                              <h3>Vendor</h3>
                              <span>
                                {v.vendor.companyname}
                                <br />
                                {v.vendor.apartsuite}, {v.vendor.address}
                                <br />
                                {v.vendor.city}
                                <br />
                                {v.vendor.country}
                              </span>
                            </div>
                            <div className="vendor_sub_boxes">
                              <h3>Contact Details</h3>
                              <span>
                                {v.vendor.contactperson}
                                <br />
                                <br />
                                {v.vendor.contactnumber}
                                <br />
                                {v.vendor.email}
                              </span>
                            </div>
                          </>
                        )}
                        <div className="vendor_sub_boxes">
                          <h3>Quotation</h3>
                          <a
                            target="_blank"
                            className="blue_link"
                            href={v.quotation}
                            // onClick={() => handledownload(v.quotation)}
                          >
                            <AiOutlineDownload size={22} />
                          </a>
                        </div>
                        <div className="vendor_sub_boxes">
                          <h3>Artwork Proof</h3>
                          <span
                            className="blue_link"
                            onClick={() => do_show_proof(v.artwork)}
                          >
                            <AiOutlineEye size={22} />
                          </span>
                        </div>
                        <div className="vendor_sub_boxes">
                          <h3>Itemized Price</h3>
                          <span
                            dangerouslySetInnerHTML={{ __html: v?.total_item }}
                          />
                        </div>
                        <div className="vendor_sub_boxes">
                          <h3>Total Price</h3>
                          <span>{v?.total_price}</span>
                        </div>
                        <div className="vendor_sub_boxes button_sub_box">
                          {v.status == 0 && (
                            <>
                              <button
                                style={{ cursor: "pointer" }}
                                disabled={Rejectloading}
                                className="reject_button"
                                onClick={() => reject_quotation(v.id)}
                              >
                                {Rejectloading ? <Loader /> : "Reject"}
                              </button>
                              <button
                                style={{ cursor: "pointer" }}
                                disabled={Acceptloading}
                                className="approve_button"
                                onClick={() => accept_quotation(v.id)}
                              >
                                {Acceptloading ? <Loader /> : "Approve"}
                              </button>
                            </>
                          )}
                          {v.status == 1 && (
                            <>
                              <div className="" style={{ color: "green" }}>
                                Accepted
                              </div>
                            </>
                          )}
                          {v.status == 2 && (
                            <>
                              <div className="" style={{ color: "red" }}>
                                Rejected
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ))}

                <div className="follow_up_div_full">
                  <Link onClick={() => navigate(-1)}>
                    <button type="button" className="button_followup">
                      Back
                    </button>
                  </Link>
                </div>

                {lpo && (
                  <>
                    <div className="popup_outer">
                      <div
                        className="popup_inner cursor"
                        onClick={() => setLpo(false)}
                      >
                        <div className="">
                          <div className="form_setcion">
                            <img src={Quotation} style={{ width: "430px" }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {proof && (
                  <>
                    <div className="popup_outer">
                      <div
                        className="popup_inner cursor"
                        onClick={() => setProof(false)}
                      >
                        <div className="">
                          <div className="form_setcion">
                            <img src={password} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
