import React, { useState } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import LOGO from "../../assets/images/logo.svg";
import LABX from "../../assets/images/labx.svg";
function Login() {
  const navigate = useNavigate();

  const params = useLocation();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [showpassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");

  const do_action = async () => {
    if (email == "") {
      toast.error("Please enter your OTP COde!");
      return;
    }
    const payload = {
      otp: email,
      user_email: params.state.user_email,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/verify_otp_code");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        toast.success("OTP verified");
        setShowPassword(true);
      } else {
        setLoading(false);
        toast.error(data?.data);
      }
    }
  };

  const do_update_password = async () => {
    if (password == "") {
      toast.error("Please enter your password!");
      return;
    }
    if (cpassword == "") {
      toast.error("Please enter confirm password!");
      return;
    }
    if (cpassword !== password) {
      toast.error("Password & confirm password not matching!");
      return;
    }
    const payload = {
      otp: email,
      user_email: params.state.user_email,
      password: password,
      cpassword: cpassword,
    };
    setLoading(true);
    const { isError, data } = await doPost(
      payload,
      "auth/update_forgot_password"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        toast.success("Your password has been updated successfully!");
        setShowPassword(false);
        navigate("/login");
      } else {
        setLoading(false);
        toast.error(data?.data);
      }
    }
  };

  return (
    <>
      {!showpassword && (
        <>
          <div className="container flex_center_page login_light">
            <div className="card p10 w35 text-center">
              <div className="m-auto-60">
                <div className="logo text-center mb-10">
                  <img src={LOGO} />
                </div>
                <div className="login_name mb-15">OTP - Forgot Password</div>
                <div className="form-group">
                  <input
                    name="otp"
                    type="text"
                    className="form-control"
                    placeholder="Enter your OTP"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="custom_bottom_margin">
                  <div className="flex-space-between gap_10">
                    <button
                      className="custom_button w50"
                      onClick={() => do_action()}
                    >
                      Submit
                    </button>
                    <button
                      className="custom_button w50"
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
              <img src={LABX} style={{ marginBottom: "-80px" }} />
            </div>
          </div>
        </>
      )}

      {showpassword && (
        <>
          <div className="container flex_center_page login_light">
            <div className="card p10 w35 text-center">
              <div className="m-auto-60">
                <div className="logo text-center mb-10">
                  <img src={LOGO} />
                </div>
                <div className="login_name mb-15">Reset Password</div>
                <div className="form-group">
                  <input
                    name="password"
                    type="password"
                    className="form-control"
                    placeholder="New Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    name="password"
                    type="password"
                    className="form-control"
                    placeholder="Confirm Password"
                    value={cpassword}
                    onChange={(e) => setCPassword(e.target.value)}
                  />
                </div>
                <div className="custom_bottom_margin">
                  <div className="flex-space-between gap_10">
                    <button
                      className="custom_button w50"
                      onClick={() => do_update_password()}
                    >
                      Submit
                    </button>
                    <button
                      className="custom_button w50"
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
              <img src={LABX} style={{ marginBottom: "-80px" }} />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Login;
