import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { doPost } from "../../utils/apiCalls";
import { toast } from "react-toastify";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

function Login() {
  const [darkmode, setDarkMode] = useState(2);
  const [chartMonth, setChartMonth] = useState([]);
  const [chartData, setChartData] = useState([]);
  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
        fetchData();
    
        const DarkValue = localStorage.getItem("DarkMode");
        if (DarkValue === null || parseInt(DarkValue) === 0) {
          setDarkMode(2);
        } else {
          setDarkMode(1);
        }
      }, []);
    
      const fetchData = async () => {
        const payload = {
          token: getLoggedObject()?.token
        }
        const {isError, data} = await doPost(payload, 'auth/get_orders_graph');
        if(isError) {
            toast.error('Something went wrong with server.');
            setLoading(false);
        }else{
            if (data.action == "success") {
              setChartMonth(data?.month)
              setChartData(data?.data)
              setLoading(false);
            }
            else {
              setLoading(false);
              toast.error(data?.error);
            }
        }
    }

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
          display: false,
        },
        title: {
          display: true,
          text: '',
        },
      },
    };

    const labels = chartMonth;

    const data = {
      labels,
      datasets: [
        {
          fill: true,
          label: 'Orders',
          data: chartData,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    };

      return <Line options={options} data={data} height={120} />;
}

export default Login;