import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import SideBar from "./../../base-components/SideBar";
import Header from "./../../base-components/Header";
import { urls } from "./../../utils/Api_urls";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import ImagesPop from "../../base-components/ImagesPopup";

const imageURL = urls.API + "resources/uploads/collection/";

function Login() {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const { getLoggedObject, isLoggedIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [data, setData] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandselect, setBrandSelect] = useState("");
  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (isLoggedIn) {
        try {
          await do_get_product_stuff(slugFromUrl);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error("Error:", error);
        }
      }
    };
    fetchData();
  }, [isLoggedIn]);

  const do_get_product_stuff = async (id) => {
    const payload = {
      token: getLoggedObject()?.token,
      pid: id,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/do_get_specific_product"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setData(data?.data);
        setVendor(data?.vendor);
        setBrands(data?.brands);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const do_click_file = () => {
    fileInputRef.current.click();
  };

  const do_save_cart = (val) => {
    // if (data.type == 2) {
    if (val.min_order != 0) {
      if (count < data.min_order) {
        toast.error("Minimum order quantity should be " + val.min_order);
        return;
      }
      if (count > val.max_order) {
        toast.error(
          "Maximum order quantity should not be more than " + val.max_order
        );
        return;
      }
    }
    // }
    var productId = val.id;
    // console.log(val);
    // return;
    if (data.type == 2) {
      var cartItems = JSON.parse(localStorage.getItem("cartPOS")) || [];
    } else {
      var cartItems = JSON.parse(localStorage.getItem("cartProducts")) || [];
    }
    const existingProductIndex = cartItems.findIndex(
      (item) => item.id === productId
    );
    if (existingProductIndex !== -1) {
      cartItems[existingProductIndex].qty += count;
    } else {
      let price = 0;
      if (val && val.datahead) {
        let rangeFound = false;

        for (const item of val.datahead) {
          const start = item.start;
          const end = item.end;

          if (count >= start && count <= end) {
            price = item.price;
            rangeFound = true;
            break;
          }
        }

        if (!rangeFound) {
          price = val.price;
        }
      } else {
        price = 0;
      }
      cartItems.push({
        id: productId,
        qty: count,
        title: val.title,
        price: price,
        image: val.image_one,
        customer: 0,
        brand: brandselect,
        vID: val.vID,
        min_order: val.min_order,
        max_order: val.max_order,
        lead_time: val.lead_time,
      });
    }
    if (data.type == 2) {
      localStorage.setItem("cartPOS", JSON.stringify(cartItems));
    } else {
      localStorage.setItem("cartProducts", JSON.stringify(cartItems));
    }

    if (data.type == 1) {
      navigate("/products");
    } else {
      navigate("/pos");
    }
  };

  const [ImagePopupStatus, setImagePopupStatus] = useState(false);
  const [image, setImage] = useState("");

  const handleImgPop = (item) => {
    setImagePopupStatus(true);
    setImage(item);
  };

  return (
    <div className="container">
      <div className="wrapper-full">
        <div className="page-content">
          <SideBar />
          <div className="right_side_bar">
            <Header title={"Product Details"} />
            <div className="content_inner">
              <div className="flex-space-between-stretch products_main_Wrap gap_20">
                <div className="pro_detail_left">
                  <div className="card wd100 p10">
                    <div className="image_pro_box">
                      <img
                        src={imageURL + data.image_one}
                        className="round_sall"
                        style={{ marginBottom: "10px" }}
                      />
                      <div className="heart_icon_abs">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="14"
                          viewBox="0 0 13 14"
                          fill="none"
                        >
                          <path
                            d="M2.74577 7.73832L6.34179 11.3248C6.52874 11.5113 6.83131 11.5113 7.01825 11.3248L10.6143 7.73832C11.5573 6.79775 11.6734 5.30988 10.8875 4.23448L10.65 3.90948C9.67014 2.56864 7.6078 2.78454 6.92689 4.29924C6.83117 4.51215 6.52887 4.51216 6.43316 4.29924C5.75225 2.78454 3.68991 2.56864 2.71006 3.90948L2.47255 4.23449C1.68669 5.30988 1.80271 6.79775 2.74577 7.73832Z"
                            stroke="#5F5F5F"
                            stroke-width="2"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="name_pro_details ncc">{data.title}</div>
                    <span className="item_count item_count_dark pro_detail_count ">
                      <span
                        onClick={() => {
                          if (count == 1) {
                            setCount(1);
                          } else {
                            setCount(count - 1);
                          }
                        }}
                      >
                        <AiOutlineMinus />
                      </span>
                      <span
                        className="count_item"
                        contentEditable="true"
                        onBlur={(e) =>
                          setCount(Number(e.target.textContent.trim()))
                        }
                      >
                        {count}
                      </span>
                      <span
                        onClick={() => {
                          setCount(count + 1);
                        }}
                      >
                        <AiOutlinePlus />
                      </span>
                    </span>
                    <div
                      className="price_pro_box flex-space-between"
                      style={{ clear: "both" }}
                    >
                      <span className="amount_pro">{data.price} AED</span>
                      <span className="stock_pro">
                        {/* {data.stock != 0 ? "In Stock" : ""} */}
                      </span>
                    </div>
                    {data.type == 1 && 2 == 3 && (
                      <div className="bran_select form-group">
                        <select
                          name="brand"
                          className="bran_input_cart"
                          value={brandselect}
                          onChange={(e) => setBrandSelect(e.target.value)}
                        >
                          <option value={""}>--Select Brand--</option>
                          {brands.map((v, i) => (
                            <>
                              <option value={v.title}>{v.title}</option>
                            </>
                          ))}
                        </select>
                      </div>
                    )}
                    <div
                      className="button_pro_details"
                      style={{ marginTop: "20px" }}
                    >
                      {/* navigate(edit == 0?"/products?type=cart":"/pos?type=cart") */}
                      <button onClick={() => do_save_cart(data)}>
                        Add to Cart
                      </button>
                      <button
                        onClick={() =>
                          data.type == 2
                            ? navigate("/pos")
                            : navigate("/products")
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                  {data.type == 1 && getLoggedObject()?.ac_type != 4 && (
                    <div className="card wd100 p20 mt-20">
                      <div
                        className=""
                        style={{ fontSize: "13px", color: "#87888C" }}
                      >
                        Vendor Details
                      </div>
                      <div className="vendor_detai_pro">
                        {vendor.companyname} <br />
                        {vendor.apartsuite}, {vendor.address}
                        <br />
                        {vendor.city}
                        <br />
                        {vendor.country}
                        <br />
                        <br />
                        {vendor.contactperson}
                        <br />
                        {vendor.contactnumber}
                        <br />
                        {vendor.email}
                      </div>
                    </div>
                  )}
                </div>

                <div className="pro_detail_right">
                  <div className="card p20 wd100">
                    <div
                      className="image_four flex-space-between"
                      style={{ justifyContent: "center", gap: "15px" }}
                    >
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() => handleImgPop(imageURL + data.image_one)}
                        src={imageURL + data.image_one}
                      />
                      {data.image_two != null && (
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleImgPop(imageURL + data.image_two)
                          }
                          src={imageURL + data.image_two}
                        />
                      )}

                      {data.image_three != null && (
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleImgPop(imageURL + data.image_three)
                          }
                          src={imageURL + data.image_three}
                        />
                      )}

                      {data.image_four != null && (
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleImgPop(imageURL + data.image_four)
                          }
                          src={imageURL + data.image_four}
                        />
                      )}
                    </div>

                    {ImagePopupStatus && (
                      <ImagesPop
                        imgsrc={image}
                        onClose={() => setImagePopupStatus(false)}
                      />
                    )}

                    <div className="">
                      <div
                        className="name_pro_details mb-10"
                        style={{ fontSize: "16px" }}
                      >
                        {data.title}
                      </div>
                      <div className="price_pro_box flex-space-between">
                        <span className="amount_pro">{data.price} AED</span>
                        <span className="stock_pro">
                          {/* {data.stock != 0 ? "In Stock" : ""} */}
                        </span>
                      </div>

                      <div className="desc_pro">{data.description}</div>
                      {(data.type == 2 || data.type == 1) && (
                        <>
                          {data.min_order != 0 && (
                            <div className="pro_sub_details">
                              <span className="heading_pro_left">
                                Minimum Order
                              </span>
                              <span>{data.min_order}</span>
                            </div>
                          )}
                          {data.max_order != 0 && (
                            <div className="pro_sub_details">
                              <span className="heading_pro_left">
                                Maximum Order
                              </span>
                              <span>{data.max_order}</span>
                            </div>
                          )}
                          {data.lead_time != 0 && (
                            <div className="pro_sub_details">
                              <span className="heading_pro_left">Leadtime</span>
                              <span>{data.lead_time} Days</span>
                            </div>
                          )}
                          {data.lead_above != 0 && (
                            <div className="pro_sub_details">
                              <span className="heading_pro_left">
                                Leadtime above MOQ
                              </span>
                              <span>{data.lead_above} Days</span>
                            </div>
                          )}
                          <div className="pro_sub_details">
                            {data?.datahead.length > 0 && (
                              <>
                                <span className="heading_pro_left">
                                  Price Range
                                </span>
                                <span>
                                  {data?.datahead.map((v, i) => (
                                    <>
                                      <span>
                                        {v.start} - {v.end} Units: {v.price} AED
                                        per unit
                                      </span>
                                      <br />
                                    </>
                                  ))}
                                </span>
                              </>
                            )}
                            {/* 02 - 05 Units: 125.00 AED per unit<br />
                                            05 - 10 Units:  110.00 AED per unit */}
                          </div>
                        </>
                      )}

                      {/* <div className="mt-5p">
                                    <div className="button_pro_details">
                                        <button style={{padding:"10px", width:"15%"}}>Price on Demand</button>
                                    </div>
                                    <div className="demand_text">
                                        *Price on Demand Feature - For orders above the Maximum order quantity. 
                                    </div>
                                </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
