import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost, check_role_restrictions } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LOGO from "../../assets/images/logo.svg";
import Profile from "../../assets/images/pro_image.svg";
import VendorLogo from "../../assets/images/vendor_logo.svg";
import ActiveStatus from "../../assets/images/active_status.svg";
import InActiveStatus from "../../assets/images/inactive.svg";

import SideBar from "./../../base-components/SideBar";
import SettingsBar from "./../../base-components/SideBar/Settings";
import Header from "./../../base-components/Header";
import { AiFillEdit, AiFillDelete, AiFillCloseCircle } from "react-icons/ai";
import DeletePopup from "./../Settings/Delete";

function Login() {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [loading, setLoading] = useState(false);
  const [upload, setUpload] = useState(false);
  const [edit, setEdit] = useState(0);
  const [tabs, setTabs] = useState(1);
  const [delpopup, setDelPopup] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [inactive, setInactive] = useState([]);
  const [role, setRole] = useState(0);
  const [commnt, setCommnt] = useState("");
  const [delid, setDelID] = useState(0);

  const [delpopupdel, setDelPopupDel] = useState(false);

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (isLoggedIn) {
        try {
          const response = await check_role_restrictions();
          if (response == 4) {
            toast.error("You are not authorized to perform this action!");
            setLoading(true);
            navigate("/dashboard");
          } else {
            setRole(response);
            await do_get_vendors();
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          console.error("Error:", error);
        }
      }
    };
    fetchData();
  }, [isLoggedIn]);

  useEffect(() => {
    if (getLoggedObject()?.ac_type == 4 || getLoggedObject()?.ac_type == 3) {
      navigate("/dashboard");
    }
  }, [slugFromUrl]);

  const do_get_vendors = async () => {
    const payload = {
      token: getLoggedObject()?.token,
    };
    const { isError, data } = await doPost(payload, "auth/do_get_vendors_info");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setSuppliers(data?.data);
        setFilteredSuppliers(data?.data);
        setInactive(data?.inactive);
        setFilteredInactive(data?.inactive);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const do_click_file = () => {
    fileInputRef.current.click();
  };

  const do_make_inactive = async (id) => {
    setDelPopup(true);
    setEdit(id);
  };

  const handle_status_submit = async () => {
    if (commnt == "") {
      toast.error("Please enter reason of inactive!");
      return;
    }
    const payload = {
      token: getLoggedObject()?.token,
      id: edit,
      commnt: commnt,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/do_update_vendor_status"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        await do_get_vendors();
        setEdit(0);
        setLoading(false);
        setCommnt("");
        setDelPopup(false);
        toast.success("Supplier status updated successfully!");
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const handle_status_active = async (val) => {
    const payload = {
      token: getLoggedObject()?.token,
      id: val,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/do_update_vendor_inactive_status"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        await do_get_vendors();
        setEdit(0);
        setLoading(false);
        setCommnt("");
        setDelPopup(false);
        toast.success("Supplier status updated successfully!");
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const handle_delete_popup = (val) => {
    setDelID(val.id);
    setDelPopupDel(true);
  };

  const handle_del = async () => {
    const payload = {
      id: delid,
      token: getLoggedObject()?.token,
    };
    const { isError, data } = await doPost(payload, "auth/delete_vendor_by_id");
    if (isError) {
      toast.error("Something went wrong with server.");
    } else {
      if (data.action == "success") {
        toast.success("Vendor deleted successfully!");
        await do_get_vendors();
        setEdit(0);
        setDelID(0);
        setUpload(false);
        setDelPopupDel(false);
      } else {
        toast.error(data?.error);
      }
    }
  };

  const close_handle = () => {
    setDelID(0);
    setDelPopupDel(false);
  };

  //============ Filter Area ==========

  const [filteredSuppliers, setFilteredSuppliers] = useState([]);

  const [FilteredInactiveSuppliers, setFilteredInactive] = useState([]);

  const handleSearch = (e) => {
    const searchTextLowerCase = e.target.value.toLowerCase();

    const filteredActiveResult = suppliers.filter(
      (supplier) =>
        supplier.title.toLowerCase().includes(searchTextLowerCase) ||
        supplier.contact_name.toLowerCase().includes(searchTextLowerCase) ||
        supplier.email.toLowerCase().includes(searchTextLowerCase) ||
        supplier.phone.toLowerCase().includes(searchTextLowerCase)
    );

    const filteredInactiveResult = inactive.filter(
      (supplier) =>
        supplier.title.toLowerCase().includes(searchTextLowerCase) ||
        supplier.contact_name.toLowerCase().includes(searchTextLowerCase) ||
        supplier.email.toLowerCase().includes(searchTextLowerCase) ||
        supplier.phone.toLowerCase().includes(searchTextLowerCase)
    );

    setFilteredSuppliers(filteredActiveResult);
    setFilteredInactive(filteredInactiveResult);
  };

  return (
    <div className="container">
      <div className="wrapper-full">
        <div className="page-content">
          <SideBar />
          <div className="right_side_bar">
            <Header fun={handleSearch} title={"Vendor Management"} />

            <div className="content_inner">
              <div className="">
                <div className="tabs_top">
                  <button
                    className={`tabs_green ${tabs == 1 ? "active_green" : ""}`}
                    onClick={() => setTabs(1)}
                  >
                    Vendor List
                  </button>
                  <button
                    className={`tabs_green ${tabs == 2 ? "active_green" : ""}`}
                    onClick={() => setTabs(2)}
                  >
                    Inactive Vendors
                  </button>
                </div>
                <div className="card wd100 p10">
                  <div className="responsive_table">
                    <table className="wd100 table_wrap">
                      <tr>
                        <th>Company Name</th>
                        <th>Products / Services</th>
                        <th>Contact Details</th>
                        <th>Address</th>
                        <th>Joining Date</th>
                        {tabs == 2 && <th>Comments</th>}
                        <th>Status</th>
                        <th></th>
                      </tr>
                      {tabs == 1 && (
                        <>
                          {suppliers.length == 0 ? (
                            <>
                              <tr>
                                <td colSpan={8} className="no_data">
                                  No Data found!
                                </td>
                              </tr>
                            </>
                          ) : (
                            filteredSuppliers?.map((v, index) => (
                              <tr>
                                <td>
                                  <span className="wrap_flex_td">
                                    <img src={v.image} />
                                    <span>{v.title}</span>
                                  </span>
                                </td>
                                <td>{v.services}</td>
                                <td>
                                  {v.contact_name}
                                  <br />
                                  <small className="table_contact_marg">
                                    {v.email}
                                    <br />
                                    {v.phone}
                                  </small>
                                </td>
                                <td>
                                  {v.suite},<br />
                                  {v.address},<br />
                                  {v.city}, {v.country}
                                  <br />
                                </td>
                                <td>{v.join_date}</td>
                                <td>
                                  <span
                                    onClick={() => do_make_inactive(v.id)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img src={ActiveStatus} />
                                  </span>
                                </td>
                                <td>
                                  {/* <Link to={`/add-suppliers?id=${v.id}`} className="edit_link">
                                                Edit
                                            </Link> */}
                                  <AiFillEdit
                                    onClick={() =>
                                      navigate(`/add-suppliers?id=${v.id}`)
                                    }
                                    size={18}
                                    style={{
                                      marginRight: "5px",
                                      cursor: "pointer",
                                    }}
                                  />
                                  {(getLoggedObject()?.ac_type == -1 ||
                                    getLoggedObject()?.ac_type == 2) && (
                                    <>
                                      <AiFillDelete
                                        onClick={() => handle_delete_popup(v)}
                                        size={15}
                                        className="delete_icon_color"
                                      />
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))
                          )}
                        </>
                      )}

                      {tabs == 2 && (
                        <>
                          {inactive.length == 0 ? (
                            <>
                              <tr>
                                <td colSpan={8} className="no_data">
                                  No Data found!
                                </td>
                              </tr>
                            </>
                          ) : (
                            FilteredInactiveSuppliers?.map((v, index) => (
                              <tr>
                                <td>
                                  <span className="wrap_flex_td">
                                    <img src={v.image} />
                                    <span>{v.title}</span>
                                  </span>
                                </td>
                                <td>{v.services}</td>
                                <td>
                                  {v.contact_name}
                                  <br />
                                  <small className="table_contact_marg">
                                    {v.email}
                                    <br />
                                    {v.phone}
                                  </small>
                                </td>
                                <td>
                                  {v.suite},<br />
                                  {v.address},<br />
                                  {v.city}, {v.country}
                                  <br />
                                </td>
                                <td>{v.join_date}</td>
                                <td>{v.commnt}</td>
                                <td>
                                  <span
                                    onClick={() => handle_status_active(v.id)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img src={InActiveStatus} />
                                  </span>
                                </td>
                                <td>
                                  <Link
                                    to={`/add-suppliers?id=${v.id}`}
                                    className="edit_link"
                                  >
                                    Edit
                                  </Link>
                                </td>
                              </tr>
                            ))
                          )}
                        </>
                      )}
                    </table>
                  </div>
                </div>

                {delpopup && (
                  <>
                    <div className="popup_outer">
                      <div className="popup_inner">
                        <div className="popup_white_center">
                          <div className="modal_heading">
                            <span>Change Status</span>
                            <span
                              className="close_icon"
                              onClick={() => setDelPopup(false)}
                            >
                              <AiFillCloseCircle />
                            </span>
                          </div>
                          <div className="form_setcion">
                            <div className="form-group">
                              <label>Comment/Reason</label>
                              <textarea
                                name="company_name"
                                className="setting_input textarea_input"
                                placeholder="Please enter comment/reason of inactive"
                                value={commnt}
                                onChange={(e) => setCommnt(e.target.value)}
                              ></textarea>
                            </div>
                            <div className="">
                              <button
                                type="button"
                                className="button_submit"
                                onClick={() => handle_status_submit()}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {delpopupdel && (
        <DeletePopup
          title={"Vendor"}
          ondeleteCall={handle_del}
          onclosecall={close_handle}
        />
      )}
    </div>
  );
}

export default Login;
