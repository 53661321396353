import React from "react";
// import { useLocation } from "react-router-dom";
import "./style.css";

const Loader = () => {
  //   const location = useLocation();

  return (
    <div className="flex justify-center items-center MainLoaderComp">
      <svg viewBox="25 25 50 50">
        <circle r="20" cy="50" cx="50"></circle>
      </svg>
    </div>
  );
};

export default Loader;
