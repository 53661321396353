import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LOGO from "../../assets/images/logo.svg";
import LABX from "../../assets/images/labx.svg";
import Loader from "../../base-components/loader";
function Login() {
  const navigate = useNavigate();
  const { setLoggedObject, isLoggedIn, checkLogin } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  useEffect(() => {
    if (isLoggedIn) {
    }
  }, [isLoggedIn]);

  const do_login = async () => {
    if (email == "") {
      toast.error("Please enter your email address!");
      return;
    }
    if (!validateEmail(email)) {
      toast.error("Please enter a valid email address!");
      return false;
    }
    if (password == "") {
      toast.error("Please enter your password!");
      return;
    }
    const payload = {
      email: email,
      password: password,
      type: slugFromUrl == "vendor-login" ? 2 : 1,
    };
    setLoading(true);
    const { isError, data } = await doPost(payload, "auth/do_login");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setLoading(false);
        setLoggedObject(data?.data);
        if (data?.data.ac_type == 9) {
          window.location.href = `${window.location.origin}/dashboard/suppliers`;
        } else {
          window.location.href = `${window.location.origin}/dashboard`;
        }
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  return (
    <div className="container flex_center_page login_light">
      <div className="card p10 w35 text-center">
        <div className="m-auto-60">
          <div className="logo text-center mb-10">
            <img src={LOGO} />
          </div>
          <div className="login_name mb-15">Log In</div>
          <div className="form-group">
            <input
              name="email"
              type="email"
              className="form-control"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              name="password"
              type="password"
              className="form-control"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="custom_bottom_margin">
            <div className="flex-space-between gap_10">
              <button
                disabled={loading}
                style={{ cursor: "pointer" }}
                className="custom_button w50"
                onClick={() => do_login()}
              >
                {loading ? <Loader /> : "Login"}
              </button>
              <button
                className="custom_button w50"
                onClick={() => navigate("/forgot/password")}
              >
                Forgot Password
              </button>
            </div>
          </div>
          {slugFromUrl == "vendor-login" ? (
            <>
              <Link to="/login">Login as Member</Link>
            </>
          ) : (
            <Link to="/vendor-login">Login as Vendor</Link>
          )}
        </div>
        <img src={LABX} style={{ marginBottom: "-80px" }} />
      </div>
    </div>
  );
}

export default Login;
