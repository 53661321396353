import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";



function SideBar() {
  
  return (
    <>
        <div className="loader">
            Loading...
        </div>
    </>
  );
}
export default SideBar;
