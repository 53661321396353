import React from "react";
import { useNavigate } from "react-router-dom";
import LOGO from "../../assets/images/logo.svg";
import LABX from "../../assets/images/labx.svg";

function Login() {
  const navigate = useNavigate();

  return (
    <div className="container flex_center_page login_light">
      <div className="card p10 w35 text-center">
        <div className="m-auto-60">
          <div className="logo text-center mb-10">
            <img src={LOGO} />
          </div>
          <div className="login_name mb-15">Reset Password</div>
          <div className="form-group">
            <input
              name="password"
              type="password"
              className="form-control"
              placeholder="New Password"
            />
          </div>
          <div className="form-group">
            <input
              name="password"
              type="password"
              className="form-control"
              placeholder="Confirm Password"
            />
          </div>
          <div className="custom_bottom_margin">
            <div className="flex-space-between gap_10">
              <button
                className="custom_button w50"
                onClick={() => navigate("/login")}
              >
                Submit
              </button>
              <button
                className="custom_button w50"
                onClick={() => navigate("/login")}
              >
                Login
              </button>
            </div>
          </div>
        </div>
        <img src={LABX} style={{ marginBottom: "-80px" }} />
      </div>
    </div>
  );
}

export default Login;
