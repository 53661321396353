import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost, check_role_restrictions } from "./../../utils/apiCalls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import SideBar from "./../../base-components/SideBar";
import SettingsBar from "./../../base-components/SideBar/Settings";
import Header from "./../../base-components/Header";
import { AiFillCloseCircle, AiFillDelete } from "react-icons/ai";
import CSVFILE from "../../assets/images/logo.svg";
import { urls } from "./../../utils/Api_urls";

function Login() {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const { getLoggedObject, isLoggedIn } = useAuth();
  const [accoutnumber, setAccountNumber] = useState("");
  const [accountname, setAccountName] = useState("");
  const [parentaccount, setParentAccount] = useState("");
  const [contact, setContact] = useState("");
  const [contactnumber, setContactNumber] = useState("");
  const [budget, setBudget] = useState("");
  const [budgetPOS, setBudgetPOS] = useState("");
  const [kam, setKAM] = useState("");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(0);
  const [popup, setPopup] = useState(false);
  const [edit, setEdit] = useState(0);
  const [role, setRole] = useState(0);
  const [projects, setProjects] = useState([]);
  const [btnloading, setBtnLoading] = useState(false);
  const [csvfile, setCSVFile] = useState("");

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("id")) {
      const viewValue = params.get("id");
      setEdit(viewValue);
      setLoading(true);
      get_user_customer_data(viewValue);
    } else {
      setEdit(0);
    }
  }, []);

  const get_user_customer_data = async (val) => {
    const payload = {
      token: getLoggedObject()?.token,
      id: val,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/do_get_customer_data"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setAccountNumber(data?.data.account_number);
        setAccountName(data?.data.name);
        setParentAccount(data?.data.parent);
        setContact(data?.data.contact);
        setContactNumber(data?.data.phone);
        setBudget(data?.data.budget);
        setBudgetPOS(data?.data.pos_budget);
        setKAM(data?.data.kam_id);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (isLoggedIn) {
        try {
          const response = await check_role_restrictions();
          if (response == 5) {
            toast.error("You are not authorized to perform this action!");
            setLoading(true);
            navigate("/dashboard");
          } else {
            setRole(response);
            await do_get_brands_info();
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          console.error("Error:", error);
        }
      }
    };
    fetchData();
  }, [isLoggedIn]);

  const do_get_brands_info = async () => {
    const payload = {
      token: getLoggedObject()?.token,
      roleu: 4,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/do_get_specific_user"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setProjects(data?.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const do_click_file = () => {
    fileInputRef.current.click();
  };

  const handle_type = (val) => {
    setType(val);
  };

  const handle_submit = async () => {
    if (accoutnumber == "") {
      toast.error("Please enter account number!");
      return;
    }
    if (accountname == "") {
      toast.error("Please enter account name!");
      return;
    }
    if (contact == "") {
      toast.error("Please enter contact!");
      return;
    }
    if (contactnumber == 0) {
      toast.error("Please enter contact number!");
      return;
    }
    if (budget == "") {
      toast.error("Please enter customer bif budget!");
      return;
    }

    if (budgetPOS == "") {
      toast.error("Please enter customer pos budget!");
      return;
    }

    const payload = {
      accoutnumber: accoutnumber,
      accountname: accountname,
      parentaccount: parentaccount,
      contact: contact,
      contactnumber: contactnumber,
      budget: budget,
      posbudget: budgetPOS,
      kam: kam,
      token: getLoggedObject()?.token,
      edit: edit,
    };
    setBtnLoading(true);
    const { isError, data } = await doPost(payload, "auth/submit_customer");
    if (isError) {
      toast.error("Something went wrong with server.");
      setBtnLoading(false);
    } else {
      if (data.action == "success") {
        toast.success(
          edit == 0
            ? "Customer information has been added successfully!"
            : "Customer information updated successfully!"
        );
        await do_get_brands_info();
        setAccountNumber("");
        setAccountName("");
        setParentAccount("");
        setContact("");
        setContactNumber("");
        setBudget("");
        setBudgetPOS("");
        setKAM(0);
        setEdit(0);
      } else {
        setBtnLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const update_file_csv = (e) => {
    let files = e.target.files;
    if (files.length > 0) {
      let fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      fileReader.onload = (event) => {
        setCSVFile(event.target.result);
      };
    }
  };

  const handle_csv = async () => {
    if (csvfile == "") {
      toast.error("Please upload a CSV File!");
      return;
    }
    setBtnLoading(true);
    const payload = {
      file: csvfile,
      token: getLoggedObject()?.token,
      update: 0,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/bulk_upload_customer"
    );
    if (isError) {
      setBtnLoading(false);
      toast.error("Something went wrong with server.");
    } else {
      if (data.action == "success") {
        toast.success(
          edit == 0
            ? "Bulk Customers uploaded successfully!"
            : "Product information updated successfully!"
        );
        setPopup(false);
        setBtnLoading(false);
      } else {
        toast.error(data?.error);
        setBtnLoading(false);
      }
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const regex = /^[0-9\b]+$/;
    if (value === "" || regex.test(value)) {
      setContactNumber(value);
    }
  };

  return (
    <div className="container">
      <div className="wrapper-full">
        <div className="page-content">
          <SideBar />
          <div className="right_side_bar">
            <Header title={"Add Customer"} />

            <div className="content_inner">
              <div className="content_settings">
                {role != 4 ? (
                  <SettingsBar />
                ) : (
                  <div className="left_setting_bar"></div>
                )}

                <div className="right_settings_box">
                  <div className="button_bulk">
                    <button
                      className="create_button"
                      style={{ padding: "10px 15px" }}
                      onClick={() => setPopup(!popup)}
                    >
                      <span className="right_margin_button">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                        >
                          <path
                            d="M7.5 3.5H4.5V0.5C4.5 0.367392 4.44732 0.240215 4.35355 0.146447C4.25979 0.0526785 4.13261 0 4 0C3.86739 0 3.74021 0.0526785 3.64645 0.146447C3.55268 0.240215 3.5 0.367392 3.5 0.5V3.5H0.5C0.367392 3.5 0.240215 3.55268 0.146447 3.64645C0.0526785 3.74021 0 3.86739 0 4C0 4.13261 0.0526785 4.25979 0.146447 4.35355C0.240215 4.44732 0.367392 4.5 0.5 4.5H3.5V7.5C3.5 7.63261 3.55268 7.75979 3.64645 7.85355C3.74021 7.94732 3.86739 8 4 8C4.13261 8 4.25979 7.94732 4.35355 7.85355C4.44732 7.75979 4.5 7.63261 4.5 7.5V4.5H7.5C7.63261 4.5 7.75979 4.44732 7.85355 4.35355C7.94732 4.25979 8 4.13261 8 4C8 3.86739 7.94732 3.74021 7.85355 3.64645C7.75979 3.55268 7.63261 3.5 7.5 3.5Z"
                            fill="#D2D2D2"
                          />
                        </svg>
                      </span>
                      <span>Upload Bulk Customer</span>
                    </button>
                  </div>

                  <div className="form_setcion">
                    {/* <div className="form-group">
                        <div className="image_upload_flex">
                            <div className="image_browse_button" onClick={()=> do_click_file()}>
                                <img src={UploadIcon} />
                                <span> Upload Company <br />Logo</span>
                            </div>
                            
                          </div>
                          <input type="file" name="logo" id="logo" accept=".png, .jpg, .jpeg" ref={fileInputRef} style={{display:"none"}} />
                        </div> */}
                    <div className="double_in_row">
                      <div className="form-group ">
                        <label>Account Number/Customer ID</label>
                        <input
                          type="text"
                          name="account_number"
                          className="setting_input"
                          placeholder="Please enter Account Number"
                          value={accoutnumber}
                          onChange={(e) => setAccountNumber(e.target.value)}
                        />
                      </div>
                      <div className="form-group ">
                        <label>Account Name</label>
                        <input
                          type="text"
                          name="service"
                          className="setting_input"
                          placeholder="Please enter Account name"
                          value={accountname}
                          onChange={(e) => setAccountName(e.target.value)}
                        />
                      </div>

                      <div className="form-group ">
                        <label>Parent Account (Optional)</label>
                        <input
                          type="text"
                          name="contact_name"
                          className="setting_input"
                          placeholder="Please enter Parent Account"
                          value={parentaccount}
                          onChange={(e) => setParentAccount(e.target.value)}
                        />
                      </div>

                      <div className="form-group ">
                        <label>Contact</label>
                        <input
                          type="text"
                          name="contact_name"
                          className="setting_input"
                          placeholder="Please enter Contact name"
                          value={contact}
                          onChange={(e) => setContact(e.target.value)}
                        />
                      </div>

                      <div className="form-group ">
                        <label>Contact Number</label>
                        <input
                          type="text"
                          name="contact_number"
                          className="setting_input"
                          placeholder="Please enter contact number"
                          value={contactnumber}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>

                      {role != 4 && (
                        <div className="form-group ">
                          <label>KAM</label>
                          <select
                            name="vendor"
                            className="setting_input"
                            value={kam}
                            onChange={(e) => setKAM(e.target.value)}
                          >
                            <option value={""}>--Select KAM--</option>
                            {projects.map((v, i) => (
                              <option value={v.id}>{v.title}</option>
                            ))}
                          </select>
                        </div>
                      )}
                      <div className="form-group ">
                        <label>Budget (BIF)</label>
                        <input
                          type="number"
                          min={0}
                          name="budget"
                          className="setting_input"
                          placeholder="Please enter Budget"
                          value={budget}
                          onChange={(e) => setBudget(e.target.value)}
                        />
                      </div>
                      <div className="form-group ">
                        <label>Budget (POS)</label>
                        <input
                          type="number"
                          min={0}
                          name="budgetpos"
                          className="setting_input"
                          placeholder="Please enter Budget"
                          value={budgetPOS}
                          onChange={(e) => setBudgetPOS(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-20px" style={{ marginBottom: "40px" }}>
                    <button
                      type="button"
                      className="button_submit"
                      onClick={() => handle_submit()}
                    >
                      {edit == 0 ? "Submit" : "Update"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {popup && (
        <>
          <div className="popup_outer">
            <div className="popup_inner">
              <div className="popup_white_center">
                <div className="modal_heading">
                  <span>Add Customers (Bulk)</span>
                  <span className="close_icon" onClick={() => setPopup(false)}>
                    <AiFillCloseCircle />
                  </span>
                </div>
                <div className="form_setcion">
                  <div className="form-group">
                    <label>Upload CSV File</label>
                    <div
                      className="image_browse_button"
                      style={{ width: "100%" }}
                      onClick={() => do_click_file()}
                    >
                      {csvfile == "" ? (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.27269 5.84638C7.74361 5.53172 8.29726 5.36377 8.86364 5.36377C9.22727 5.36377 9.52204 5.65855 9.52204 6.02218C9.52204 6.38581 9.22727 6.68059 8.86364 6.68059C8.5577 6.68059 8.25864 6.7713 8.00427 6.94127C7.7499 7.11124 7.55164 7.35282 7.43456 7.63546C7.31749 7.91811 7.28685 8.22912 7.34654 8.52917C7.40622 8.82923 7.55354 9.10485 7.76987 9.32117C7.9862 9.5375 8.26181 9.68482 8.56187 9.7445C8.86192 9.80419 9.17293 9.77356 9.45558 9.65648C9.73822 9.53941 9.9798 9.34115 10.1498 9.08677C10.3197 8.8324 10.4105 8.53334 10.4105 8.22741C10.4105 7.86378 10.7052 7.569 11.0689 7.569C11.4325 7.569 11.7273 7.86378 11.7273 8.22741C11.7273 8.79378 11.5593 9.34743 11.2447 9.81836C10.93 10.2893 10.4828 10.6563 9.9595 10.8731C9.43624 11.0898 8.86046 11.1465 8.30497 11.036C7.74948 10.9255 7.23923 10.6528 6.83874 10.2523C6.43825 9.85181 6.16552 9.34156 6.05503 8.78607C5.94453 8.23058 6.00124 7.6548 6.21798 7.13154C6.43472 6.60828 6.80176 6.16104 7.27269 5.84638Z"
                              fill="#8D98AA"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M23.1692 9.18164C23.5276 9.18164 23.8181 9.47822 23.8181 9.84408V15.3909C23.8181 18.2285 23.2693 20.3871 21.8733 21.8201C20.4765 23.254 18.3713 23.818 15.6052 23.818H9.12196C6.35673 23.818 4.25161 23.2542 2.85457 21.8205C1.45838 20.3876 0.909058 18.229 0.909058 15.3909V13.1607C0.909058 12.7948 1.1996 12.4983 1.55799 12.4983C1.91639 12.4983 2.20693 12.7948 2.20693 13.1607V15.3909C2.20693 18.0996 2.73916 19.8233 3.77474 20.8861C4.80947 21.948 6.48633 22.4931 9.12196 22.4931H15.6052C18.2417 22.4931 19.9186 21.9478 20.9531 20.8859C21.9884 19.823 22.5203 18.0993 22.5203 15.3909V9.84408C22.5203 9.47822 22.8108 9.18164 23.1692 9.18164Z"
                              fill="#8D98AA"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2.88158 2.86617C4.2972 1.46169 6.43034 0.90918 9.23439 0.90918H13.6183C13.9797 0.90918 14.2727 1.19988 14.2727 1.55847C14.2727 1.91706 13.9797 2.20775 13.6183 2.20775H9.23439C6.55948 2.20775 4.85718 2.74258 3.80709 3.7844C2.75701 4.82622 2.21793 6.51513 2.21793 9.16898C2.21793 9.52757 1.92493 9.81827 1.5635 9.81827C1.20206 9.81827 0.909058 9.52757 0.909058 9.16898C0.909058 6.387 1.46595 4.27065 2.88158 2.86617Z"
                              fill="#8D98AA"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M15.5454 4.72718C15.5454 4.37573 15.8504 4.09082 16.2266 4.09082H22.5006C22.8768 4.09082 23.1818 4.37573 23.1818 4.72718C23.1818 5.07864 22.8768 5.36355 22.5006 5.36355H16.2266C15.8504 5.36355 15.5454 5.07864 15.5454 4.72718Z"
                              fill="#8D98AA"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M19.3637 0.90918C19.7151 0.90918 20 1.21415 20 1.59035V7.86437C20 8.24057 19.7151 8.54554 19.3637 8.54554C19.0122 8.54554 18.7273 8.24057 18.7273 7.86437V1.59035C18.7273 1.21415 19.0122 0.90918 19.3637 0.90918Z"
                              fill="#8D98AA"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M18.7588 13.4293L14.2551 17.3279L14.2431 17.3381C13.6956 17.7895 13.0085 18.0363 12.2995 18.0363C11.5905 18.0363 10.9035 17.7895 10.3559 17.3381C10.35 17.3332 10.3442 17.3282 10.3385 17.3231L9.99081 17.0153C9.69968 16.7804 9.34208 16.6432 8.96864 16.6232C8.59289 16.6031 8.22049 16.7028 7.90482 16.9079L2.56182 20.5242C2.26358 20.7261 1.85852 20.6474 1.6571 20.3485C1.45567 20.0497 1.53416 19.6438 1.83239 19.4419L7.18878 15.8166C7.7369 15.4583 8.38457 15.2841 9.03805 15.319C9.69153 15.354 10.317 15.5963 10.824 16.0109C10.8306 16.0163 10.837 16.0218 10.8434 16.0275L11.1921 16.3362C11.505 16.591 11.8961 16.7303 12.2995 16.7303C12.7041 16.7303 13.0962 16.5903 13.4095 16.3341L17.9133 12.4354L17.9252 12.4253C18.4728 11.9739 19.1598 11.7271 19.8688 11.7271C20.5779 11.7271 21.2649 11.9739 21.8124 12.4253L21.8244 12.4354L23.5925 13.9661C23.8648 14.2019 23.8949 14.6142 23.6596 14.8872C23.4243 15.1601 23.0128 15.1902 22.7405 14.9544L20.9788 13.4293C20.6655 13.1731 20.2734 13.0331 19.8688 13.0331C19.4643 13.0331 19.0722 13.1731 18.7588 13.4293Z"
                              fill="#8D98AA"
                            />
                          </svg>
                          <span> Upload your File</span>
                        </>
                      ) : (
                        <div className="image_upl_file">
                          <img src={CSVFILE} />
                        </div>
                      )}
                    </div>
                    <input
                      type="file"
                      name="logo"
                      id="logo"
                      accept=".csv"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={update_file_csv}
                    />
                  </div>

                  <small
                    style={{
                      marginBottom: "10px",
                      display: "block",
                      float: "right",
                    }}
                  >
                    <a
                      target="_blank"
                      href={urls.API + "customer.csv"}
                      download
                    >
                      Download Sample File (please follow this format)
                    </a>
                  </small>

                  <div className="">
                    <button
                      type="button"
                      className="button_submit"
                      onClick={() => handle_csv()}
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Login;
