import React, { useEffect, useState } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import SideBar from "./../../base-components/SideBar";
import Header from "./../../base-components/Header";

function Login() {
  const { getLoggedObject } = useAuth();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [ntifications, setNotifications] = useState([]);

  useEffect(() => {
    if (getLoggedObject()?.ac_type != 9) {
      window.location.href = `${window.location.origin}/dashboard`;
      return;
    } else {
      fetchOrders();
    }
  }, []);

  const fetchOrders = async () => {
    const payload = {
      token: getLoggedObject()?.token,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/fetch_supplier_dashboard"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setData(data?.data);
        setNotifications(data?.notifications);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  return (
    <div className="container">
      <div className="wrapper-full">
        <div className="page-content">
          <SideBar />
          <div className="right_side_bar">
            <Header title={"Supplier Dashboard Overview"} />

            <div className="content_inner">
              <div className="dashboard_boxes_wrap">
                {/* SEOCND BOX */}
                <div className="card p20 wd100">
                  <div className="dashboard_heading">Notifications</div>
                  <div className="notifx_wrap">
                    {ntifications.map((v, index) => (
                      <>
                        <div className="notif_div">
                          <div className="left_not">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="6"
                              height="6"
                              viewBox="0 0 6 6"
                              fill="none"
                            >
                              <circle cx="3" cy="3" r="3" fill="#9D0C2F" />
                            </svg>
                            <span
                              dangerouslySetInnerHTML={{ __html: v.title }}
                            ></span>
                          </div>
                          <span className="time_notf">{v.ago}</span>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>

              <div className="bottom_dashboard mt-20px">
                <div className="card p20 wd100">
                  <div className="dashboard_heading">
                    Order Management (Action Required)
                  </div>

                  <div className="responsive_table mt-10px">
                    <table className="wd100 table_wrap">
                      <tr>
                        <th>S.No</th>
                        <th>Product</th>
                        <th>Project</th>
                        <th>Amount</th>
                        <th>LPO</th>
                        <th>Review</th>
                        <th>Status</th>
                      </tr>
                      {data.length == 0 && (
                        <tr>
                          <td colSpan={7}>
                            <div className="no_text_found">
                              No record found!
                            </div>
                          </td>
                        </tr>
                      )}
                      {data.map((v, index) => (
                        <tr>
                          <td>{v.order_id}</td>
                          <td>
                            {v.products.map((v, i) => (
                              <>
                                <span className="product_data">
                                  {v.customer.accountname}
                                </span>
                              </>
                            ))}
                          </td>
                          <td>{v.project}</td>
                          <td>{v.total_amount}</td>
                          <td>{v.lpo}</td>

                          <td>
                            <Link
                              to={`/order/details/${v.id}`}
                              className="blue_link"
                            >
                              <u>Details</u>
                            </Link>
                          </td>
                          <td style={{ width: "100px" }}>
                            <small>{v.status}</small>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
