import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LOGO from "../../assets/images/logo.svg";
import Download from "../../assets/images/download_small.svg";

import SideBar from "./../../base-components/SideBar";
import Header from "./../../base-components/Header";
import { AiFillCloseCircle } from "react-icons/ai";
import Graph from "../../base-components/Header/Graph";
import { IoEyeOutline } from "react-icons/io5";
import { FaFilePdf } from "react-icons/fa6";
import { GoFileMedia } from "react-icons/go";
import Loader from "../../base-components/loader";
import { BsFilePdfFill } from "react-icons/bs";

function Login() {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const fileInputRefinvoice = useRef(null);
  const fileInputRefinvoicetwo = useRef(null);
  const { getLoggedObject } = useAuth();
  const [loading, setLoading] = useState(false);
  const [lpo, setLpo] = useState(false);
  const [proof, setProof] = useState(false);
  const [data, setData] = useState([]);
  const [products, setProducts] = useState([]);
  const [hoverdata, setHoverData] = useState([]);
  const [vendor, setVendor] = useState("");
  const [LPOUpload, setLPOUpload] = useState(false);

  const [selected, setSelected] = useState(0);
  const [imagedata, setImageData] = useState("");
  const [lponumber, setlponumber] = useState("");
  const [reject, setReject] = useState(false);
  const [deliverydate, setDeliveryDate] = useState(false);
  const [id, setID] = useState(0);
  const [completeod, setCompleteOD] = useState(0);

  const [history, setHistory] = useState([]);

  const [invoice, setInvoice] = useState("");

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  useEffect(() => {
    fetchOrders();
  }, [slugFromUrl]);

  const fetchOrders = async () => {
    const payload = {
      token: getLoggedObject()?.token,
      id: slugFromUrl,
      type: getLoggedObject()?.ac_type,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/fetch_specific_order_id"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setData(data?.data);
        setProducts(data?.data.products);
        setVendor(data?.data.vendor);
        setHistory(data?.history);
        setHoverData(data?.hover);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const upload_lpo = async (id) => {
    setLPOUpload(true);
    setSelected(id);
  };

  const [UploadLpoLoading, setUploadLpoLoading] = useState(false);
  const handle_csv = async () => {
    if (imagedata == "" && PdfData == "") {
      toast.error("Please upload atleast one file!");
      return;
    }

    setUploadLpoLoading(true);

    const payload = {
      image: imagedata,
      name: lponumber,
      pdf: PdfData,
      id: selected,
      token: getLoggedObject()?.token,
      type: getLoggedObject()?.ac_type == 9 ? 2 : 1,
    };
    const { isError, data } = await doPost(
      payload,
      getLoggedObject()?.ac_type == 9
        ? "auth/upload_proof_vendor"
        : "auth/upload_lpo"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setUploadLpoLoading(false);
    } else {
      if (data.action == "success") {
        if (getLoggedObject()?.ac_type == 9) {
          toast.success("Proof uploaded & notification sent successfully!");
          setUploadLpoLoading(false);
        } else {
          toast.success("LPO uploaded & notified the vendor successfully!");
        }
        setUploadLpoLoading(false);
        setLPOUpload(false);
        setSelected(0);
        fetchOrders();
        setImageData("");
      } else {
        toast.error(data?.error);
      }
    }
  };
  const show_lpo = async (val) => {
    setLpo(true);
    setSelected(val);
  };

  const do_click_file = () => {
    fileInputRef.current.click();
  };

  const do_click_file_complete = (val) => {
    setCompleteOD(val);
    if (val == 1) {
      fileInputRefinvoice.current.click();
    } else {
      fileInputRefinvoicetwo.current.click();
    }
  };

  const update_file_csv = (e) => {
    let files = e.target.files;
    if (files.length > 0) {
      let fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      fileReader.onload = (event) => {
        setImageData(event.target.result);
      };
    }
  };

  const update_file_complete = (e) => {
    let files = e.target.files;
    if (files.length > 0) {
      let fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      fileReader.onload = (event) => {
        if (completeod == 1) {
          setImageData(event.target.result);
        } else {
          setInvoice(event.target.result);
        }
        fileInputRefinvoice.current.value = "";
      };
    }
  };

  const reject_submit = async (val) => {
    if (lponumber == "") {
      toast.error("Please enter reason of rejection!");
      return;
    }
    const payload = {
      reason: lponumber,
      id: val,
      token: getLoggedObject()?.token,
    };
    const { isError, data } = await doPost(payload, "auth/proof_rejection");
    if (isError) {
      toast.error("Something went wrong with server.");
    } else {
      if (data.action == "success") {
        toast.success("information submitted successfully!");
        setlponumber("");
        setReject(false);
        fetchOrders();
      } else {
        toast.error(data?.error);
      }
    }
  };

  const do_submit_approve = async (id) => {
    const payload = {
      id: id,
      token: getLoggedObject()?.token,
    };
    const { isError, data } = await doPost(payload, "auth/accept_proof");
    if (isError) {
      toast.error("Something went wrong with server.");
    } else {
      if (data.action == "success") {
        toast.success("Notification sent to vendor!");
        fetchOrders();
      } else {
        toast.error(data?.error);
      }
    }
  };

  const confirm_delivery_date = (id) => {
    setDeliveryDate(true);
    setID(id);
  };

  const submit_delivery = async (id) => {
    if (lponumber == "") {
      toast.error("Please enter delivery date!");
      return;
    }

    const payload = {
      id: id,
      token: getLoggedObject()?.token,
      date: lponumber,
      type: getLoggedObject()?.ac_type == 9 ? 2 : 0,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/delivery_date_by_vendor"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
    } else {
      if (data.action == "success") {
        toast.success("Information submitted successfully!");
        setDeliveryDate(false);
        setID(0);
        fetchOrders();
      } else {
        toast.error(data?.error);
      }
    }
  };

  const [complete, setComplete] = useState(false);

  const complete_order_popup = async (val) => {
    setComplete(true);
    setID(val);
  };

  const handle_complete = async () => {
    if (imagedata == "") {
      toast.error("Please upload delivery notes!");
      return;
    }
    if (invoice == "") {
      toast.error("Please upload invoice!");
      return;
    }
    const payload = {
      image: imagedata,
      invoice: invoice,
      id: id,
      token: getLoggedObject()?.token,
      type: getLoggedObject()?.ac_type == 9 ? 2 : 1,
    };
    //   console.log(payload);
    //   return;
    const { isError, data } = await doPost(payload, "auth/complete_order");
    if (isError) {
      toast.error("Something went wrong with server.");
    } else {
      if (data.action == "success") {
        toast.success("Order completed successfully!");
        setComplete(false);
        setID(0);
        fetchOrders();
        setImageData("");
        setInvoice("");
      } else {
        toast.error(data?.error);
      }
    }
  };

  const handledownload = async (val) => {
    try {
      const response = await fetch(val);
      const contentType = response.headers.get("content-type");
      const extension = contentType.split("/")[1]; // Extract file extension from content type
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `filename.${extension}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const show_lpopp = async () => {
    show_lpo(data.lpo_file);
  };

  const show_proofp = async () => {
    setProof(true);
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowFormatted = tomorrow.toISOString().split("T")[0];

  ////////////

  const [PosAction, setPosAction] = useState(false);

  const do_pos_Accept = async (id) => {
    const payload = {
      id: id,
      token: getLoggedObject()?.token,
    };
    setPosAction(true);
    const { isError, data } = await doPost(
      payload,
      "auth/do_accept_posss_budget"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setPosAction(false);
    } else {
      if (data.action == "success") {
        fetchOrders();
        setPosAction(false);
        toast.success("Order accepted");
      } else {
        toast.error(data?.error);
        setPosAction(false);
      }
    }
  };

  const do_pos_Reject = async (id) => {
    if (RejectReason == "") {
      return toast.error("Please enter reason of rejection");
    }

    const payload = {
      id: id,
      reason: RejectReason,
      token: getLoggedObject()?.token,
    };
    setPosAction(true);
    const { isError, data } = await doPost(
      payload,
      "auth/do_reject_posss_budget"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setPosAction(false);
    } else {
      if (data.action == "success") {
        fetchOrders();
        setPosAction(false);
        toast.success("Order rejected");
      } else {
        toast.error(data?.error);
        setPosAction(false);
      }
    }
  };

  //// Reject APIS

  const [RejectReason, setRejectReason] = useState("");
  const [ReasonLoading, setReasonLoading] = useState(false);

  const RejectReasonsPost = async (id) => {
    if (RejectReason == "") {
      return toast.error("Please enter reason of rejection");
    }

    const payload = {
      id: id,
      reason: RejectReason,
      token: getLoggedObject()?.token,
    };
    setReasonLoading(true);
    const { isError, data } = await doPost(payload, "auth/orderRejected");
    if (isError) {
      toast.error("Something went wrong with server.");
      setRejectReason("");
      setReasonLoading(false);
    } else {
      if (data.action == "success") {
        toast.success("Order rejected");
        setRejectReason("");
        setReasonLoading(false);
        fetchOrders();
      } else {
        toast.error(data?.error);
        setRejectReason("");
        setReasonLoading(false);
      }
    }
  };

  // Upload PDF File With LPO

  const [PdfData, setPdfData] = useState("");
  const fileInputPdfRef = useRef(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPdfData(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      toast.error("Please upload a valid PDF file.");
    }
  };

  const handleClick = () => {
    fileInputPdfRef.current.click();
  };

  const [NoteToVendor, setNoteToVendor] = useState("");
  const [NoteLoading, setNoteLoading] = useState(false);

  const AddNotetoVendor = async (id) => {
    if (NoteToVendor == "") {
      return toast.error("Please enter note to vendor");
    }
    const payload = {
      id: id,
      vendor_notes: NoteToVendor,
      token: getLoggedObject()?.token,
    };
    setNoteLoading(true);
    const { isError, data } = await doPost(payload, "auth/sendOrderNotes");
    if (isError) {
      toast.error("Something went wrong with server.");
      setNoteToVendor("");
      setNoteLoading(false);
    } else {
      if (data.status == "success") {
        toast.success("Note Added to vendor");
        setNoteToVendor("");
        setNoteLoading(false);
        fetchOrders();
      } else {
        toast.error(data?.error);
        setNoteToVendor("");
        setNoteLoading(false);
      }
    }
  };

  return (
    <div className="container">
      <div className="wrapper-full">
        <div className="page-content">
          <SideBar />
          <div className="right_side_bar">
            <Header title={"Orders Details"} />

            <div className="content_inner">
              <div className="">
                <div className="card wd100 p30">
                  {data?.status != 7 && (
                    <>
                      {data?.type == 1 && (
                        <div className="graph_view">
                          <Graph
                            c1={data.status == 1 || data.status > 1 ? 1 : 2}
                            c2={data.status == 2 ? 2 : data.status > 2 && 1}
                            c3={data.status == 3 ? 2 : data.status > 3 && 1}
                            c4={data.status == 4 ? 2 : data.status > 4 && 1}
                            c5={data.status == 5 ? 2 : data.status > 5 && 1}
                            c6={data.status == 6 ? 1 : 0}
                            hover={hoverdata}
                            show_lpopp={show_lpopp}
                            show_proofp={show_proofp}
                          />
                        </div>
                      )}
                    </>
                  )}

                  <div className="order_detail_wrap">
                    <div className="left_order_box">
                      <div className="box_order_inner">
                        <span className="heading_order">Order Created:</span>
                        <span>{data.created_by}</span>
                      </div>
                      <div className="box_order_inner">
                        <span className="heading_order">Order No:</span>
                        <span>{data.order_id}</span>
                      </div>
                      <div className="box_order_inner">
                        <span className="heading_order">Date:</span>
                        <span>{data.date_created}</span>
                      </div>
                      {data?.type == 1 && (
                        <>
                          <div className="box_order_inner">
                            <span className="heading_order">Delivery:</span>
                            <span>ETA {data.days_delivery} DAYS</span>
                          </div>
                        </>
                      )}
                      <div className="box_order_inner">
                        <span className="heading_order">Project:</span>
                        <span>{data.project}</span>
                      </div>

                      {/* <div className="box_order_inner">
                                        <span className="heading_order">Product:</span>
                                        <span>Monster T-Shirt</span>
                                    </div> */}
                    </div>
                  </div>
                  {products.map((v, i) => (
                    <>
                      <div
                        className="flex_wrap_box_order_vendor light_bar_order mt-10px"
                        style={{ alignItems: "center" }}
                      >
                        <div className="left_order_box">
                          <div className="box_order_inner">
                            <span className="heading_order">
                              Customer Details:
                            </span>
                            <span>{v.customer}</span>
                          </div>
                          <div className="box_order_inner">
                            <span className="heading_order">KAM:</span>
                            <span>{v.kam}</span>
                          </div>
                        </div>
                        <div className="left_order_box">
                          <div className="box_order_inner">
                            <span className="heading_order">Product:</span>
                            <span>{v.pro_title}</span>
                          </div>
                          <div className="box_order_inner">
                            <span className="heading_order">Quantity:</span>
                            <span>{v.qty} Pcs</span>
                          </div>
                          {data.type == 1 && (
                            <>
                              {/* <div className="box_order_inner">
                                            <span className="heading_order">Brand:</span>
                                            <span>{v.brand}</span>
                                        </div> */}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ))}

                  {data?.type == "2" && (
                    <div className="budget_right" style={{ marginTop: "40px" }}>
                      <div
                        className="top_nam_budget"
                        style={{ marginBottom: "10px" }}
                      >
                        AED {data?.customer_data?.amount_used} /{" "}
                        <span style={{ color: "#908686" }}>
                          AED {data?.customer_data?.customer?.pos_budget}
                        </span>
                      </div>

                      <div className="flex-space-between gap_20 customer_mov">
                        <div className="budget_barrr">
                          <span
                            className="bar_filled"
                            style={{
                              width: data?.customer_data?.amount_perc + "%",
                              background:
                                "linear-gradient(180deg, #FA3C3C 0%, #B14C4C 100%)",
                            }}
                          ></span>
                        </div>
                        <div className="buget_price">
                          AED {data?.customer_data?.budget_text}
                        </div>
                      </div>
                    </div>
                  )}

                  {(getLoggedObject()?.ac_type == 2 ||
                    getLoggedObject()?.ac_type == 3) && (
                    <>
                      <td>
                        {data?.order_approve == "0" && data?.type == "2" && (
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                            }}
                          >
                            <div className="button_sub_box">
                              <button
                                type="button"
                                style={{
                                  cursor: "pointer",
                                }}
                                disabled={PosAction}
                                className="approve_button"
                                onClick={() => do_pos_Accept(data.id)}
                              >
                                {PosAction ? <Loader /> : "Approve"}
                              </button>
                              <button
                                style={{
                                  cursor: "pointer",
                                }}
                                disabled={PosAction}
                                type="button"
                                className="reject_button"
                                onClick={() => do_pos_Reject(data.id)}
                              >
                                {PosAction ? <Loader /> : "Reject"}
                              </button>
                              <input
                                type="text"
                                className="RejectInp"
                                placeholder="Reason for Rejection?"
                                onChange={(e) =>
                                  setRejectReason(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        )}
                        {data?.order_approve == "2" && (
                          <p
                            style={{
                              color: "red",
                            }}
                          >
                            Order rejected
                          </p>
                        )}
                      </td>
                    </>
                  )}

                  {/* <div className="flex_wrap_box_order_vendor light_bar_order"  style={{alignItems:"center"}}>
                            <div className="left_order_box">
                                <div className="box_order_inner">
                                    <span className="heading_order">Customer Details:</span>
                                    <span>
                                        Andrew
                                    </span>
                                </div>
                                <div className="box_order_inner">
                                    <span className="heading_order">Delivery Address:</span>
                                    <span>
                                        The Palace
                                    </span>
                                </div>
                            </div>
                            <div className="left_order_box" >
                                <div className="box_order_inner">
                                    <span className="heading_order">Quantity:</span>
                                    <span>2 Set (1Set = 25pcs)</span>
                                </div>
                            </div>
                        </div> */}

                  <div className="flex_wrap_box_order_vendor">
                    <div
                      className="left_order_box"
                      style={{ marginTop: "10px" }}
                    >
                      {data.type == 1 && (
                        <div className="box_order_inner">
                          {getLoggedObject()?.ac_type != 4 && (
                            <>
                              <span className="heading_order">Vendor:</span>
                              <span>
                                {vendor.companyname}
                                <br />
                                {vendor.apartsuite}, {vendor.address}
                                <br />
                                {vendor.city}
                                <br />
                                {vendor.country}
                                <br />
                                <br />
                                {vendor.contactperson}
                                <br />
                                {vendor.contactnumber}
                                <br />
                                {vendor.email}
                              </span>
                            </>
                          )}
                        </div>
                      )}
                      {data?.type == 1 && (
                        <div className="NoteKamVendorTmm">
                          {getLoggedObject()?.ac_type == 3 ||
                            (getLoggedObject()?.ac_type == 4 && (
                              <div className="NoteBoxVen">
                                <p>Note to vendor:</p>
                                <div style={{ width: "70%" }}>
                                  <textarea
                                    value={NoteToVendor}
                                    onChange={(e) =>
                                      setNoteToVendor(e.target.value)
                                    }
                                    className="NoteTextKamVenarea"
                                    placeholder="Any additional details can be added here."
                                  ></textarea>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <button
                                      disabled={NoteLoading}
                                      onClick={() => AddNotetoVendor(data?.id)}
                                      className="KamNoteBtn"
                                    >
                                      {NoteLoading ? <Loader /> : "Submit"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))}

                          <div className="NotePrevKamTmmVen">
                            <p className="KmNoteVenHead">{`${
                              getLoggedObject()?.ac_type != 9
                                ? "Previous note:"
                                : "Note to vendor:"
                            }`}</p>
                            <p>{data?.vendor_notes}</p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="left_order_box"
                      style={{ marginTop: "10px" }}
                    >
                      {/* <div className="box_order_inner">
                                    <span className="heading_order">Branding:</span>
                                    <span>Monster Original</span>
                                </div> */}
                      {data.type == 1 && (
                        <>
                          <div className="box_order_inner">
                            <span className="heading_order">
                              Date Required:
                            </span>
                            <span>{data.deliver_date}</span>
                          </div>

                          <div className="box_order_inner">
                            <span className="heading_order">Vendor Date:</span>
                            <span>{data.vendor_date}</span>
                          </div>
                          {getLoggedObject()?.ac_type != 4 && (
                            <>
                              {data?.status != 7 && (
                                <div className="box_order_inner">
                                  <span className="heading_order cursor">
                                    LPO:
                                  </span>
                                  {data.lpo === null &&
                                  getLoggedObject()?.ac_type != 9 ? (
                                    <>
                                      <Link
                                        className="blue"
                                        onClick={() => {
                                          upload_lpo(data.id);
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="13"
                                          height="13"
                                          viewBox="0 0 17 17"
                                          fill="none"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <g clip-path="url(#clip0_267_14834)">
                                            <path
                                              d="M0.708171 5.71826V3.71148C0.708171 2.25834 1.87069 1.09582 3.32383 1.09582L13.6758 1.09582C15.1151 1.09582 16.2915 2.27222 16.2915 3.71148V5.71826"
                                              stroke="#2894D1"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                            <path
                                              d="M8.5 5.93954L8.5 15.9041"
                                              stroke="#2894D1"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                            <path
                                              d="M11.6831 9.12266L8.51384 5.93955L5.30304 9.13647"
                                              stroke="#2894D1"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_267_14834">
                                              <rect
                                                width="17"
                                                height="17"
                                                fill="white"
                                                transform="matrix(-1 0 0 -1 17 17)"
                                              />
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </Link>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        style={{ display: "flex", gap: "10px" }}
                                      >
                                        {data?.lpo_file != "" && (
                                          <span
                                            className="blue cursor"
                                            onClick={() =>
                                              show_lpo(data.lpo_file)
                                            }
                                          >
                                            <GoFileMedia className="blue cursor" />
                                          </span>
                                        )}

                                        {data?.lpo_pdf != "" && (
                                          <span>
                                            <Link
                                              to={data.lpo_pdf}
                                              target="_blank"
                                            >
                                              <FaFilePdf />
                                            </Link>
                                          </span>
                                        )}
                                      </div>

                                      {/* {(getLoggedObject()?.ac_type == 2 ||
                                        getLoggedObject()?.ac_type == 3) &&
                                        data.status != 6 && (
                                          <>
                                            <Link
                                              className="blue"
                                              onClick={() => {
                                                upload_lpo(data.id);
                                              }}
                                              style={{ marginLeft: "10px" }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="13"
                                                height="13"
                                                viewBox="0 0 17 17"
                                                fill="none"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <g clip-path="url(#clip0_267_14834)">
                                                  <path
                                                    d="M0.708171 5.71826V3.71148C0.708171 2.25834 1.87069 1.09582 3.32383 1.09582L13.6758 1.09582C15.1151 1.09582 16.2915 2.27222 16.2915 3.71148V5.71826"
                                                    stroke="#2894D1"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M8.5 5.93954L8.5 15.9041"
                                                    stroke="#2894D1"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M11.6831 9.12266L8.51384 5.93955L5.30304 9.13647"
                                                    stroke="#2894D1"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_267_14834">
                                                    <rect
                                                      width="17"
                                                      height="17"
                                                      fill="white"
                                                      transform="matrix(-1 0 0 -1 17 17)"
                                                    />
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                            </Link>
                                          </>
                                        )} */}
                                    </>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                          <div className="box_order_inner">
                            <span className="heading_order">Vendor Proof:</span>
                            {data.proof == "" ? (
                              ""
                            ) : (
                              <>
                                <div className="flex-start gap_10">
                                  <span>
                                    <IoEyeOutline
                                      size={16}
                                      className="blue cursor"
                                      onClick={() => {
                                        setProof(true);
                                      }}
                                    />
                                  </span>
                                  {/* <span><img src={Download}  className="blue cursor" onClick={()=> {handleDownload(data.proof)}} /></span> */}
                                </div>
                              </>
                            )}
                          </div>
                          <div className="box_order_inner">
                            <span className="heading_order">
                              Delivery Notes:
                            </span>
                            {data.delivery_note == "" ? (
                              ""
                            ) : (
                              <>
                                <div className="flex-start gap_10">
                                  <Link target="_blank" to={data.delivery_note}>
                                    <span>
                                      <img
                                        src={Download}
                                        style={{ width: "11px" }}
                                        className="blue cursor"
                                        // onClick={() =>
                                        //   handledownload(data.delivery_note)
                                        // }
                                      />
                                    </span>
                                  </Link>
                                </div>
                              </>
                            )}
                          </div>
                          {getLoggedObject()?.ac_type != 4 && (
                            <>
                              <div className="box_order_inner">
                                <span className="heading_order">Invoice:</span>
                                {data.invoice == "" ? (
                                  ""
                                ) : (
                                  <>
                                    <div className="flex-start gap_10">
                                      <span>
                                        <img
                                          src={Download}
                                          style={{ width: "11px" }}
                                          className="blue cursor"
                                          onClick={() =>
                                            handledownload(data.invoice)
                                          }
                                        />
                                      </span>
                                    </div>
                                  </>
                                )}
                              </div>
                            </>
                          )}
                          <div className="box_order_inner lime_color">
                            <span className="heading_order">Status: </span>
                            <span>{data.current_status}</span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {/* ----------------  */}
                  {data?.type == 1 &&
                    (getLoggedObject()?.ac_type == 2 ||
                      getLoggedObject()?.ac_type == -1 ||
                      getLoggedObject()?.ac_type == 3) && (
                      <div className="budget_right" style={{ margin: "auto" }}>
                        <div
                          className="top_nam_budget"
                          style={{ marginBottom: "10px" }}
                        >
                          AED {data?.amount_used} /{" "}
                          <span style={{ color: "#908686" }}>
                            AED {data?.actual_budget}
                          </span>
                        </div>

                        <div className="flex-space-between gap_20 customer_mov">
                          <div className="budget_barrr">
                            <span
                              className="bar_filled"
                              style={{
                                width: data?.amount_perc + "%",
                                background:
                                  "linear-gradient(180deg, #FA3C3C 0%, #B14C4C 100%)",
                              }}
                            ></span>
                          </div>
                        </div>
                      </div>
                    )}

                  {/* ------------------- */}

                  {data?.type == 1 && (
                    <div className="follow_up_div">
                      {getLoggedObject()?.ac_type == 9 && (
                        <>
                          {
                            <div className="button_sub_box">
                              {data.status == 5 && (
                                <>
                                  <button
                                    type="button"
                                    className="approve_button"
                                    style={{
                                      width: "auto",
                                      padding: "5px 20px",
                                    }}
                                    onClick={() => {
                                      complete_order_popup(data.id);
                                    }}
                                  >
                                    COMPLETE ORDER
                                  </button>

                                  <button
                                    type="button"
                                    className="reject_button"
                                    onClick={() =>
                                      navigate("/dashboard/suppliers")
                                    }
                                  >
                                    Back
                                  </button>
                                </>
                              )}
                            </div>
                          }
                        </>
                      )}

                      {getLoggedObject()?.ac_type == 9 ? (
                        <>
                          {data.vendor_date == "" && (
                            <div className="button_sub_box">
                              {data.prof_status == 1 ? (
                                <>
                                  <button
                                    type="button"
                                    className="approve_button"
                                    style={{
                                      width: "auto",
                                      padding: "5px 20px",
                                    }}
                                    onClick={() => {
                                      confirm_delivery_date(data.id);
                                    }}
                                  >
                                    CONFIRM DELIVERY DATE
                                  </button>
                                </>
                              ) : (
                                <button
                                  type="button"
                                  className="approve_button"
                                  style={{ width: "auto", padding: "5px 20px" }}
                                  onClick={() => {
                                    upload_lpo(data.id);
                                  }}
                                >
                                  UPLOAD PROOF
                                </button>
                              )}

                              <button
                                type="button"
                                className="reject_button"
                                onClick={() => navigate("/dashboard/suppliers")}
                              >
                                Back
                              </button>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {data.approval == 1 &&
                          (data.prof_status == 0 || data.prof_status == 2) &&
                          getLoggedObject()?.ac_type != 4 ? (
                            <>
                              <div className="button_sub_box">
                                <button
                                  type="button"
                                  className="approve_button"
                                  onClick={() => do_submit_approve(data.id)}
                                >
                                  Approve
                                </button>
                                <button
                                  type="button"
                                  className="reject_button"
                                  onClick={() => setReject(true)}
                                >
                                  Reject
                                </button>
                              </div>
                            </>
                          ) : (
                            <div className="mainDetailsReject">
                              {data?.status == 2 &&
                                getLoggedObject()?.ac_type != 4 && (
                                  <div className="DetailsReject">
                                    <input
                                      type="text"
                                      className="RejectInp"
                                      placeholder="Reason for Rejection?"
                                      onChange={(e) =>
                                        setRejectReason(e.target.value)
                                      }
                                    />
                                    <div>
                                      <button
                                        disabled={ReasonLoading}
                                        type="button"
                                        className="NewRejBtn"
                                        onClick={() =>
                                          RejectReasonsPost(data?.id)
                                        }
                                      >
                                        {ReasonLoading ? <Loader /> : "Reject"}
                                      </button>
                                    </div>
                                  </div>
                                )}
                              <button
                                type="button"
                                className="button_followup"
                                onClick={() => navigate(-1)}
                              >
                                Back
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {data.approval == 0 && data.prof_status == 2 && (
                    <>
                      <div className="reason_proof">
                        <b>PROOF REJECTION REASON:</b>
                        <br />
                        <br />
                        {data.prof_reason}
                      </div>
                    </>
                  )}
                </div>

                {lpo && (
                  <>
                    <div className="popup_outer">
                      <div
                        className="popup_inner cursor"
                        onClick={() => setLpo(false)}
                      >
                        <div className="">
                          <div className="form_setcion">
                            <img
                              src={data.lpo_file}
                              style={{ width: "430px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {proof && (
                  <>
                    <div className="popup_outer">
                      <div
                        className="popup_inner cursor"
                        onClick={() => setProof(false)}
                      >
                        <div className="">
                          <div className="form_setcion">
                            <img src={data.proof} style={{ width: "100%" }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {LPOUpload && (
                  <>
                    <div className="popup_outer">
                      <div className="popup_inner">
                        <div className="popup_white_center">
                          <div className="modal_heading">
                            <span>
                              {getLoggedObject()?.ac_type == 9
                                ? "Upload Proof"
                                : "Upload LPO"}
                            </span>
                            <span
                              className="close_icon"
                              onClick={() => setLPOUpload(false)}
                            >
                              <AiFillCloseCircle />
                            </span>
                          </div>
                          <div className="form_setcion">
                            {getLoggedObject()?.ac_type != 9 && (
                              <div className="form-group">
                                <label>LPO #</label>
                                <input
                                  type="number"
                                  value={lponumber}
                                  className="form-control"
                                  onChange={(e) => setlponumber(e.target.value)}
                                />
                              </div>
                            )}
                            <div className="form-group">
                              <label>
                                Upload{" "}
                                {getLoggedObject()?.ac_type == 9
                                  ? "PROOF"
                                  : "LPO"}{" "}
                                Image
                              </label>
                              <div
                                className="image_browse_button"
                                style={{ width: "100%" }}
                                onClick={() => do_click_file()}
                              >
                                {imagedata == "" ? (
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M7.27269 5.84638C7.74361 5.53172 8.29726 5.36377 8.86364 5.36377C9.22727 5.36377 9.52204 5.65855 9.52204 6.02218C9.52204 6.38581 9.22727 6.68059 8.86364 6.68059C8.5577 6.68059 8.25864 6.7713 8.00427 6.94127C7.7499 7.11124 7.55164 7.35282 7.43456 7.63546C7.31749 7.91811 7.28685 8.22912 7.34654 8.52917C7.40622 8.82923 7.55354 9.10485 7.76987 9.32117C7.9862 9.5375 8.26181 9.68482 8.56187 9.7445C8.86192 9.80419 9.17293 9.77356 9.45558 9.65648C9.73822 9.53941 9.9798 9.34115 10.1498 9.08677C10.3197 8.8324 10.4105 8.53334 10.4105 8.22741C10.4105 7.86378 10.7052 7.569 11.0689 7.569C11.4325 7.569 11.7273 7.86378 11.7273 8.22741C11.7273 8.79378 11.5593 9.34743 11.2447 9.81836C10.93 10.2893 10.4828 10.6563 9.9595 10.8731C9.43624 11.0898 8.86046 11.1465 8.30497 11.036C7.74948 10.9255 7.23923 10.6528 6.83874 10.2523C6.43825 9.85181 6.16552 9.34156 6.05503 8.78607C5.94453 8.23058 6.00124 7.6548 6.21798 7.13154C6.43472 6.60828 6.80176 6.16104 7.27269 5.84638Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M23.1692 9.18164C23.5276 9.18164 23.8181 9.47822 23.8181 9.84408V15.3909C23.8181 18.2285 23.2693 20.3871 21.8733 21.8201C20.4765 23.254 18.3713 23.818 15.6052 23.818H9.12196C6.35673 23.818 4.25161 23.2542 2.85457 21.8205C1.45838 20.3876 0.909058 18.229 0.909058 15.3909V13.1607C0.909058 12.7948 1.1996 12.4983 1.55799 12.4983C1.91639 12.4983 2.20693 12.7948 2.20693 13.1607V15.3909C2.20693 18.0996 2.73916 19.8233 3.77474 20.8861C4.80947 21.948 6.48633 22.4931 9.12196 22.4931H15.6052C18.2417 22.4931 19.9186 21.9478 20.9531 20.8859C21.9884 19.823 22.5203 18.0993 22.5203 15.3909V9.84408C22.5203 9.47822 22.8108 9.18164 23.1692 9.18164Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M2.88158 2.86617C4.2972 1.46169 6.43034 0.90918 9.23439 0.90918H13.6183C13.9797 0.90918 14.2727 1.19988 14.2727 1.55847C14.2727 1.91706 13.9797 2.20775 13.6183 2.20775H9.23439C6.55948 2.20775 4.85718 2.74258 3.80709 3.7844C2.75701 4.82622 2.21793 6.51513 2.21793 9.16898C2.21793 9.52757 1.92493 9.81827 1.5635 9.81827C1.20206 9.81827 0.909058 9.52757 0.909058 9.16898C0.909058 6.387 1.46595 4.27065 2.88158 2.86617Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M15.5454 4.72718C15.5454 4.37573 15.8504 4.09082 16.2266 4.09082H22.5006C22.8768 4.09082 23.1818 4.37573 23.1818 4.72718C23.1818 5.07864 22.8768 5.36355 22.5006 5.36355H16.2266C15.8504 5.36355 15.5454 5.07864 15.5454 4.72718Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M19.3637 0.90918C19.7151 0.90918 20 1.21415 20 1.59035V7.86437C20 8.24057 19.7151 8.54554 19.3637 8.54554C19.0122 8.54554 18.7273 8.24057 18.7273 7.86437V1.59035C18.7273 1.21415 19.0122 0.90918 19.3637 0.90918Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M18.7588 13.4293L14.2551 17.3279L14.2431 17.3381C13.6956 17.7895 13.0085 18.0363 12.2995 18.0363C11.5905 18.0363 10.9035 17.7895 10.3559 17.3381C10.35 17.3332 10.3442 17.3282 10.3385 17.3231L9.99081 17.0153C9.69968 16.7804 9.34208 16.6432 8.96864 16.6232C8.59289 16.6031 8.22049 16.7028 7.90482 16.9079L2.56182 20.5242C2.26358 20.7261 1.85852 20.6474 1.6571 20.3485C1.45567 20.0497 1.53416 19.6438 1.83239 19.4419L7.18878 15.8166C7.7369 15.4583 8.38457 15.2841 9.03805 15.319C9.69153 15.354 10.317 15.5963 10.824 16.0109C10.8306 16.0163 10.837 16.0218 10.8434 16.0275L11.1921 16.3362C11.505 16.591 11.8961 16.7303 12.2995 16.7303C12.7041 16.7303 13.0962 16.5903 13.4095 16.3341L17.9133 12.4354L17.9252 12.4253C18.4728 11.9739 19.1598 11.7271 19.8688 11.7271C20.5779 11.7271 21.2649 11.9739 21.8124 12.4253L21.8244 12.4354L23.5925 13.9661C23.8648 14.2019 23.8949 14.6142 23.6596 14.8872C23.4243 15.1601 23.0128 15.1902 22.7405 14.9544L20.9788 13.4293C20.6655 13.1731 20.2734 13.0331 19.8688 13.0331C19.4643 13.0331 19.0722 13.1731 18.7588 13.4293Z"
                                        fill="#8D98AA"
                                      />
                                    </svg>
                                    <span> Upload your Image</span>
                                  </>
                                ) : (
                                  <div className="image_upl_file">
                                    <img src={imagedata} />
                                  </div>
                                )}
                              </div>
                              <input
                                type="file"
                                name="logo"
                                id="logo"
                                accept=".png, .jpg, ,jpeg"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={update_file_csv}
                              />
                            </div>
                            <div className="form-group">
                              <label>
                                Upload{" "}
                                {getLoggedObject()?.ac_type == 9
                                  ? "PROOF"
                                  : "LPO"}{" "}
                                (PDF)
                              </label>
                              <div
                                className="image_browse_button"
                                style={{ width: "100%" }}
                                onClick={handleClick}
                              >
                                {PdfData === "" ? (
                                  <>
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 256 256"
                                    >
                                      <g
                                        strokeWidth={0}
                                        strokeMiterlimit={10}
                                        fill="#8D98AA"
                                      >
                                        <path
                                          d="M45 20.866c-.633 0-1.228.246-1.676.693l-10.63 10.63a2.362 2.362 0 00-.514 2.583 2.364 2.364 0 002.19 1.463h2.682v8.751a2.373 2.373 0 002.37 2.371h11.155a2.373 2.373 0 002.371-2.371v-8.751h2.682a2.36 2.36 0 002.19-1.465 2.363 2.363 0 00-.515-2.581l-10.63-10.63A2.351 2.351 0 0045 20.866zm-9.11 12.37l9.11-9.11 9.109 9.11h-2.661a1.5 1.5 0 00-1.5 1.5v9.622h-9.896v-9.622a1.5 1.5 0 00-1.5-1.5H35.89z"
                                          transform="matrix(2.81 0 0 2.81 1.407 1.407)"
                                        />
                                        <path
                                          d="M77.474 17.28L61.526 1.332A4.516 4.516 0 0058.311 0H15.742a4.553 4.553 0 00-4.548 4.548v80.904A4.553 4.553 0 0015.742 90h58.516a4.554 4.554 0 004.549-4.548V20.496a4.517 4.517 0 00-1.333-3.216zM61.073 5.121l12.611 12.612H62.35a1.278 1.278 0 01-1.276-1.277V5.121zM15.742 3h42.332v13.456a4.281 4.281 0 004.276 4.277h13.457v33.2H14.194V4.548A1.55 1.55 0 0115.742 3zm58.516 84H15.742a1.55 1.55 0 01-1.548-1.548V56.934h61.613v28.519A1.55 1.55 0 0174.258 87z"
                                          transform="matrix(2.81 0 0 2.81 1.407 1.407)"
                                        />
                                        <path
                                          d="M31.116 62.679h-5.944a1.5 1.5 0 00-1.5 1.5v16.602a1.5 1.5 0 103 0v-5.248h4.444a4.343 4.343 0 004.338-4.338v-4.177a4.343 4.343 0 00-4.338-4.339zm1.338 8.515c0 .737-.6 1.338-1.338 1.338h-4.444v-6.854h4.444a1.34 1.34 0 011.338 1.339v4.177zM46.109 82.28h-5.652a1.5 1.5 0 01-1.5-1.5V64.179a1.5 1.5 0 011.5-1.5h5.652a4.635 4.635 0 014.63 4.63V77.65a4.635 4.635 0 01-4.63 4.63zm-4.152-3h4.152c.898 0 1.63-.731 1.63-1.63V67.309c0-.898-.731-1.63-1.63-1.63h-4.152V79.28zM64.828 62.679h-8.782a1.5 1.5 0 00-1.5 1.5V80.78a1.5 1.5 0 003 0v-6.801h4.251a1.5 1.5 0 000-3h-4.251v-5.301h7.282a1.5 1.5 0 000-2.999z"
                                          transform="matrix(2.81 0 0 2.81 1.407 1.407)"
                                        />
                                      </g>
                                    </svg>
                                    <span> Upload your PDF</span>
                                  </>
                                ) : (
                                  <div className="image_upl_file">
                                    {PdfData != "" && (
                                      <BsFilePdfFill size={40} />
                                    )}
                                  </div>
                                )}
                              </div>
                              <input
                                type="file"
                                name="logo"
                                id="logo"
                                accept=".pdf"
                                ref={fileInputPdfRef}
                                style={{ display: "none" }}
                                onChange={handleFileUpload}
                              />
                            </div>
                            <div className="">
                              <button
                                disabled={UploadLpoLoading}
                                type="button"
                                className="button_submit"
                                onClick={() => handle_csv()}
                              >
                                {UploadLpoLoading ? <Loader /> : "Upload"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {reject && (
                  <>
                    <div className="popup_outer">
                      <div className="popup_inner">
                        <div className="popup_white_center">
                          <div className="modal_heading">
                            <span>Reject Vendor Proof</span>
                            <span
                              className="close_icon"
                              onClick={() => setReject(false)}
                            >
                              <AiFillCloseCircle />
                            </span>
                          </div>
                          <div className="form_setcion">
                            <div className="form-group">
                              <label>Reason of Rejection</label>
                              <textarea
                                type="number"
                                style={{ height: "100px" }}
                                value={lponumber}
                                className="form-control"
                                onChange={(e) => setlponumber(e.target.value)}
                              ></textarea>
                            </div>

                            <div className="">
                              <button
                                type="button"
                                className="button_submit"
                                onClick={() => reject_submit(data.id)}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {deliverydate && (
                  <>
                    <div className="popup_outer">
                      <div className="popup_inner">
                        <div className="popup_white_center">
                          <div className="modal_heading">
                            <span>Delivery Date</span>
                            <span
                              className="close_icon"
                              onClick={() => setDeliveryDate(false)}
                            >
                              <AiFillCloseCircle />
                            </span>
                          </div>
                          <div className="form_setcion">
                            <p
                              style={{
                                textTransform: "uppercase",
                                marginBottom: "20px",
                              }}
                            >
                              Expected delivery date:{" "}
                              <span className="blue">{data.deliver_date}</span>
                            </p>
                            <div className="form-group">
                              <label>Enter Delivery Date</label>
                              <input
                                type="date"
                                min={tomorrowFormatted}
                                value={lponumber}
                                className="form-control"
                                onChange={(e) => setlponumber(e.target.value)}
                              />
                            </div>

                            <div className="">
                              <button
                                type="button"
                                className="button_submit"
                                onClick={() => submit_delivery(data.id)}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {complete && (
                  <>
                    <div className="popup_outer">
                      <div className="popup_inner">
                        <div className="popup_white_center">
                          <div className="modal_heading">
                            <span>Complete Delivery</span>
                            <span
                              className="close_icon"
                              onClick={() => setComplete(false)}
                            >
                              <AiFillCloseCircle />
                            </span>
                          </div>
                          <div className="form_setcion">
                            <div className="form-group">
                              <label>Upload Delivery Note</label>
                              <div
                                className="image_browse_button"
                                style={{ width: "100%" }}
                                onClick={() => do_click_file_complete(1)}
                              >
                                {imagedata == "" ? (
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M7.27269 5.84638C7.74361 5.53172 8.29726 5.36377 8.86364 5.36377C9.22727 5.36377 9.52204 5.65855 9.52204 6.02218C9.52204 6.38581 9.22727 6.68059 8.86364 6.68059C8.5577 6.68059 8.25864 6.7713 8.00427 6.94127C7.7499 7.11124 7.55164 7.35282 7.43456 7.63546C7.31749 7.91811 7.28685 8.22912 7.34654 8.52917C7.40622 8.82923 7.55354 9.10485 7.76987 9.32117C7.9862 9.5375 8.26181 9.68482 8.56187 9.7445C8.86192 9.80419 9.17293 9.77356 9.45558 9.65648C9.73822 9.53941 9.9798 9.34115 10.1498 9.08677C10.3197 8.8324 10.4105 8.53334 10.4105 8.22741C10.4105 7.86378 10.7052 7.569 11.0689 7.569C11.4325 7.569 11.7273 7.86378 11.7273 8.22741C11.7273 8.79378 11.5593 9.34743 11.2447 9.81836C10.93 10.2893 10.4828 10.6563 9.9595 10.8731C9.43624 11.0898 8.86046 11.1465 8.30497 11.036C7.74948 10.9255 7.23923 10.6528 6.83874 10.2523C6.43825 9.85181 6.16552 9.34156 6.05503 8.78607C5.94453 8.23058 6.00124 7.6548 6.21798 7.13154C6.43472 6.60828 6.80176 6.16104 7.27269 5.84638Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M23.1692 9.18164C23.5276 9.18164 23.8181 9.47822 23.8181 9.84408V15.3909C23.8181 18.2285 23.2693 20.3871 21.8733 21.8201C20.4765 23.254 18.3713 23.818 15.6052 23.818H9.12196C6.35673 23.818 4.25161 23.2542 2.85457 21.8205C1.45838 20.3876 0.909058 18.229 0.909058 15.3909V13.1607C0.909058 12.7948 1.1996 12.4983 1.55799 12.4983C1.91639 12.4983 2.20693 12.7948 2.20693 13.1607V15.3909C2.20693 18.0996 2.73916 19.8233 3.77474 20.8861C4.80947 21.948 6.48633 22.4931 9.12196 22.4931H15.6052C18.2417 22.4931 19.9186 21.9478 20.9531 20.8859C21.9884 19.823 22.5203 18.0993 22.5203 15.3909V9.84408C22.5203 9.47822 22.8108 9.18164 23.1692 9.18164Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M2.88158 2.86617C4.2972 1.46169 6.43034 0.90918 9.23439 0.90918H13.6183C13.9797 0.90918 14.2727 1.19988 14.2727 1.55847C14.2727 1.91706 13.9797 2.20775 13.6183 2.20775H9.23439C6.55948 2.20775 4.85718 2.74258 3.80709 3.7844C2.75701 4.82622 2.21793 6.51513 2.21793 9.16898C2.21793 9.52757 1.92493 9.81827 1.5635 9.81827C1.20206 9.81827 0.909058 9.52757 0.909058 9.16898C0.909058 6.387 1.46595 4.27065 2.88158 2.86617Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M15.5454 4.72718C15.5454 4.37573 15.8504 4.09082 16.2266 4.09082H22.5006C22.8768 4.09082 23.1818 4.37573 23.1818 4.72718C23.1818 5.07864 22.8768 5.36355 22.5006 5.36355H16.2266C15.8504 5.36355 15.5454 5.07864 15.5454 4.72718Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M19.3637 0.90918C19.7151 0.90918 20 1.21415 20 1.59035V7.86437C20 8.24057 19.7151 8.54554 19.3637 8.54554C19.0122 8.54554 18.7273 8.24057 18.7273 7.86437V1.59035C18.7273 1.21415 19.0122 0.90918 19.3637 0.90918Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M18.7588 13.4293L14.2551 17.3279L14.2431 17.3381C13.6956 17.7895 13.0085 18.0363 12.2995 18.0363C11.5905 18.0363 10.9035 17.7895 10.3559 17.3381C10.35 17.3332 10.3442 17.3282 10.3385 17.3231L9.99081 17.0153C9.69968 16.7804 9.34208 16.6432 8.96864 16.6232C8.59289 16.6031 8.22049 16.7028 7.90482 16.9079L2.56182 20.5242C2.26358 20.7261 1.85852 20.6474 1.6571 20.3485C1.45567 20.0497 1.53416 19.6438 1.83239 19.4419L7.18878 15.8166C7.7369 15.4583 8.38457 15.2841 9.03805 15.319C9.69153 15.354 10.317 15.5963 10.824 16.0109C10.8306 16.0163 10.837 16.0218 10.8434 16.0275L11.1921 16.3362C11.505 16.591 11.8961 16.7303 12.2995 16.7303C12.7041 16.7303 13.0962 16.5903 13.4095 16.3341L17.9133 12.4354L17.9252 12.4253C18.4728 11.9739 19.1598 11.7271 19.8688 11.7271C20.5779 11.7271 21.2649 11.9739 21.8124 12.4253L21.8244 12.4354L23.5925 13.9661C23.8648 14.2019 23.8949 14.6142 23.6596 14.8872C23.4243 15.1601 23.0128 15.1902 22.7405 14.9544L20.9788 13.4293C20.6655 13.1731 20.2734 13.0331 19.8688 13.0331C19.4643 13.0331 19.0722 13.1731 18.7588 13.4293Z"
                                        fill="#8D98AA"
                                      />
                                    </svg>
                                    <span> Upload your File</span>
                                  </>
                                ) : (
                                  <div className="image_upl_file">
                                    <img src={LOGO} />
                                  </div>
                                )}
                              </div>
                              <input
                                type="file"
                                name="logo"
                                id="logo"
                                accept=".pdf"
                                ref={fileInputRefinvoice}
                                style={{ display: "none" }}
                                onChange={update_file_complete}
                              />

                              <input
                                type="file"
                                name="logo"
                                id="logo"
                                accept=".pdf"
                                ref={fileInputRefinvoicetwo}
                                style={{ display: "none" }}
                                onChange={update_file_complete}
                              />
                            </div>

                            <div className="form-group">
                              <label>Upload Invoice</label>
                              <div
                                className="image_browse_button"
                                style={{ width: "100%" }}
                                onClick={() => do_click_file_complete(2)}
                              >
                                {invoice == "" ? (
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M7.27269 5.84638C7.74361 5.53172 8.29726 5.36377 8.86364 5.36377C9.22727 5.36377 9.52204 5.65855 9.52204 6.02218C9.52204 6.38581 9.22727 6.68059 8.86364 6.68059C8.5577 6.68059 8.25864 6.7713 8.00427 6.94127C7.7499 7.11124 7.55164 7.35282 7.43456 7.63546C7.31749 7.91811 7.28685 8.22912 7.34654 8.52917C7.40622 8.82923 7.55354 9.10485 7.76987 9.32117C7.9862 9.5375 8.26181 9.68482 8.56187 9.7445C8.86192 9.80419 9.17293 9.77356 9.45558 9.65648C9.73822 9.53941 9.9798 9.34115 10.1498 9.08677C10.3197 8.8324 10.4105 8.53334 10.4105 8.22741C10.4105 7.86378 10.7052 7.569 11.0689 7.569C11.4325 7.569 11.7273 7.86378 11.7273 8.22741C11.7273 8.79378 11.5593 9.34743 11.2447 9.81836C10.93 10.2893 10.4828 10.6563 9.9595 10.8731C9.43624 11.0898 8.86046 11.1465 8.30497 11.036C7.74948 10.9255 7.23923 10.6528 6.83874 10.2523C6.43825 9.85181 6.16552 9.34156 6.05503 8.78607C5.94453 8.23058 6.00124 7.6548 6.21798 7.13154C6.43472 6.60828 6.80176 6.16104 7.27269 5.84638Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M23.1692 9.18164C23.5276 9.18164 23.8181 9.47822 23.8181 9.84408V15.3909C23.8181 18.2285 23.2693 20.3871 21.8733 21.8201C20.4765 23.254 18.3713 23.818 15.6052 23.818H9.12196C6.35673 23.818 4.25161 23.2542 2.85457 21.8205C1.45838 20.3876 0.909058 18.229 0.909058 15.3909V13.1607C0.909058 12.7948 1.1996 12.4983 1.55799 12.4983C1.91639 12.4983 2.20693 12.7948 2.20693 13.1607V15.3909C2.20693 18.0996 2.73916 19.8233 3.77474 20.8861C4.80947 21.948 6.48633 22.4931 9.12196 22.4931H15.6052C18.2417 22.4931 19.9186 21.9478 20.9531 20.8859C21.9884 19.823 22.5203 18.0993 22.5203 15.3909V9.84408C22.5203 9.47822 22.8108 9.18164 23.1692 9.18164Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M2.88158 2.86617C4.2972 1.46169 6.43034 0.90918 9.23439 0.90918H13.6183C13.9797 0.90918 14.2727 1.19988 14.2727 1.55847C14.2727 1.91706 13.9797 2.20775 13.6183 2.20775H9.23439C6.55948 2.20775 4.85718 2.74258 3.80709 3.7844C2.75701 4.82622 2.21793 6.51513 2.21793 9.16898C2.21793 9.52757 1.92493 9.81827 1.5635 9.81827C1.20206 9.81827 0.909058 9.52757 0.909058 9.16898C0.909058 6.387 1.46595 4.27065 2.88158 2.86617Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M15.5454 4.72718C15.5454 4.37573 15.8504 4.09082 16.2266 4.09082H22.5006C22.8768 4.09082 23.1818 4.37573 23.1818 4.72718C23.1818 5.07864 22.8768 5.36355 22.5006 5.36355H16.2266C15.8504 5.36355 15.5454 5.07864 15.5454 4.72718Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M19.3637 0.90918C19.7151 0.90918 20 1.21415 20 1.59035V7.86437C20 8.24057 19.7151 8.54554 19.3637 8.54554C19.0122 8.54554 18.7273 8.24057 18.7273 7.86437V1.59035C18.7273 1.21415 19.0122 0.90918 19.3637 0.90918Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M18.7588 13.4293L14.2551 17.3279L14.2431 17.3381C13.6956 17.7895 13.0085 18.0363 12.2995 18.0363C11.5905 18.0363 10.9035 17.7895 10.3559 17.3381C10.35 17.3332 10.3442 17.3282 10.3385 17.3231L9.99081 17.0153C9.69968 16.7804 9.34208 16.6432 8.96864 16.6232C8.59289 16.6031 8.22049 16.7028 7.90482 16.9079L2.56182 20.5242C2.26358 20.7261 1.85852 20.6474 1.6571 20.3485C1.45567 20.0497 1.53416 19.6438 1.83239 19.4419L7.18878 15.8166C7.7369 15.4583 8.38457 15.2841 9.03805 15.319C9.69153 15.354 10.317 15.5963 10.824 16.0109C10.8306 16.0163 10.837 16.0218 10.8434 16.0275L11.1921 16.3362C11.505 16.591 11.8961 16.7303 12.2995 16.7303C12.7041 16.7303 13.0962 16.5903 13.4095 16.3341L17.9133 12.4354L17.9252 12.4253C18.4728 11.9739 19.1598 11.7271 19.8688 11.7271C20.5779 11.7271 21.2649 11.9739 21.8124 12.4253L21.8244 12.4354L23.5925 13.9661C23.8648 14.2019 23.8949 14.6142 23.6596 14.8872C23.4243 15.1601 23.0128 15.1902 22.7405 14.9544L20.9788 13.4293C20.6655 13.1731 20.2734 13.0331 19.8688 13.0331C19.4643 13.0331 19.0722 13.1731 18.7588 13.4293Z"
                                        fill="#8D98AA"
                                      />
                                    </svg>
                                    <span> Upload your File</span>
                                  </>
                                ) : (
                                  <div className="image_upl_file">
                                    <img src={LOGO} />
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="">
                              <button
                                type="button"
                                className="button_submit"
                                onClick={() => handle_complete()}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* ADD HISTORY */}
            {getLoggedObject()?.ac_type != 4 && (
              <div className="content_inner">
                <div className="">
                  <div className="card wd100 p30" style={{ marginTop: "20px" }}>
                    <h3
                      style={{
                        textTransform: "uppercase",
                        marginBottom: "20px",
                      }}
                    >
                      History
                    </h3>

                    {history.map((v, i) => (
                      <>
                        <div className="history_wrap">
                          <div className="history_number">{i + 1}</div>
                          <div className="history_data">
                            <div
                              className="content_history"
                              dangerouslySetInnerHTML={{ __html: v.content }}
                            ></div>
                            <div className="history_date">{v.date}</div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
