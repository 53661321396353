import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LOGO from "./../../assets/images/logo.svg";
import Profile from "./../../assets/images/pro_image.svg";
import DownArrow from "./../../assets/images/down_arrow.svg";
import BellIcon from "./../../assets/images/bell_icon.svg";
import SearchIcon from "./../../assets/images/search_icon.svg";
import BURGER from "./../../assets/images/hambergermenu.svg";

import { AiOutlineArrowLeft, AiOutlineLogout } from "react-icons/ai";
import useAuth from "./../../hooks/useAuth";
import { doPost, check_role_restrictions } from "./../../utils/apiCalls";

function Header({ title, fun = () => {} }) {
  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [dropdown, setDropDown] = useState(false);
  const [prodrop, setProDrop] = useState(false);
  const [searchText, setsearchText] = useState("");

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  const do_make_action = () => {
    const bodyHasMobileClass =
      document.body.classList.contains("mobile_view_p");
    if (bodyHasMobileClass) {
      document.body.classList.remove("mobile_view_p");
    } else {
      document.body.classList.add("mobile_view_p");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isLoggedIn) {
        await do_get_user_info();
      }
    };
    fetchData();
  }, [isLoggedIn]);

  const do_get_user_info = async () => {
    const payload = {
      token: getLoggedObject()?.token,
      type: getLoggedObject()?.ac_type == 9 ? 2 : 0,
    };
    const { isError, data } = await doPost(payload, "auth/user_data");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setData(data?.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const do_logout = async () => {
    setLoggedObject(null);
    localStorage.removeItem("PROCXAUTH");
    toast.success("You're logout successfully!");
    if (getLoggedObject()?.ac_type == 9) {
      window.location.href = `${window.location.origin}/vendor-login`;
    } else {
      window.location.href = `${window.location.origin}/login`;
    }
  };

  return (
    <>
      <div className="header_fixed">
        <div className="wrapper_full mobil_needede">
          <div className="flex-space-between">
            <div className="mobile_view">
              <div className="top_logo_section">
                <div className="no_mobile dashboard_header_logo">
                  <img src={LOGO} style={{ height: "35px" }} />
                </div>
                <div
                  className="icon_three dashboard_header_logo"
                  style={{ cursor: "pointer" }}
                  onClick={() => do_make_action()}
                >
                  <img src={BURGER} />
                </div>
              </div>
            </div>
            <div className="new_desktop flex-space-between wd100">
              <div className="hedaer_title">
                <AiOutlineArrowLeft
                  size={18}
                  style={{ fontWeight: "bolder", cursor: "pointer" }}
                  onClick={() => navigate(-1)}
                />
                <span>{title}</span>
              </div>

              <div className="right_side_header">
                <div className="image_header">
                  <div className="search_header">
                    <div className="search_icon">
                      <img src={SearchIcon} />
                    </div>
                    <input
                      type="text"
                      autocomplete="off"
                      placeholder="Search here..."
                      className="search_bar"
                      onChange={fun}
                    />
                  </div>
                  {getLoggedObject()?.ac_type != 9 && (
                    <button
                      className="create_button relative"
                      onClick={() => setDropDown(!dropdown)}
                    >
                      <span className="right_margin_button">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                        >
                          <path
                            d="M7.5 3.5H4.5V0.5C4.5 0.367392 4.44732 0.240215 4.35355 0.146447C4.25979 0.0526785 4.13261 0 4 0C3.86739 0 3.74021 0.0526785 3.64645 0.146447C3.55268 0.240215 3.5 0.367392 3.5 0.5V3.5H0.5C0.367392 3.5 0.240215 3.55268 0.146447 3.64645C0.0526785 3.74021 0 3.86739 0 4C0 4.13261 0.0526785 4.25979 0.146447 4.35355C0.240215 4.44732 0.367392 4.5 0.5 4.5H3.5V7.5C3.5 7.63261 3.55268 7.75979 3.64645 7.85355C3.74021 7.94732 3.86739 8 4 8C4.13261 8 4.25979 7.94732 4.35355 7.85355C4.44732 7.75979 4.5 7.63261 4.5 7.5V4.5H7.5C7.63261 4.5 7.75979 4.44732 7.85355 4.35355C7.94732 4.25979 8 4.13261 8 4C8 3.86739 7.94732 3.74021 7.85355 3.64645C7.75979 3.55268 7.63261 3.5 7.5 3.5Z"
                            fill="#D2D2D2"
                          />
                        </svg>
                      </span>
                      <span>Create</span>
                      {dropdown && (
                        <div className="popup_button_slide">
                          <div className="inner_slid_btn">
                            <Link to="/products" className="slide_btn">
                              Stock Items
                            </Link>
                            <Link to="/beskpoke/project" className="slide_btn">
                              Beskpoke Project Orders
                            </Link>
                            <Link to="/pos" className="slide_btn">
                              POS Orders
                            </Link>
                          </div>
                        </div>
                      )}
                    </button>
                  )}
                  <img
                    onClick={() => navigate("/notifications")}
                    className="cursor"
                    src={BellIcon}
                  />
                  <div
                    className="relative header_img_us"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setProDrop(!prodrop)}
                  >
                    <img src={data?.profile_pic} className="img" />
                    <img className="drodown_dark" src={DownArrow} />
                    {prodrop && (
                      <div className="popup_button_slide_pro">
                        <div
                          className="inner_slid_btn"
                          style={{ width: "143px" }}
                        >
                          {getLoggedObject()?.ac_type != 9 && (
                            <Link to="/settings" className="slide_btn">
                              Settings
                            </Link>
                          )}
                          {/* {getLoggedObject()?.ac_type != 9 && ( */}
                          <Link to="/change/password" className="slide_btn">
                            Change Password
                          </Link>
                          {/* )} */}
                          <Link to="/notifications" className="slide_btn">
                            Notifications
                          </Link>
                          <Link
                            onClick={() => do_logout()}
                            className="slide_btn"
                          >
                            Logout
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Header;
