import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LOGO from "../../assets/images/logo.svg";

import SideBar from "./../../base-components/SideBar";
import Header from "./../../base-components/Header";
import { AiFillCloseCircle, AiOutlineDownload } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6";
import Loader from "../../base-components/loader/index.jsx";
import ImagesPop from "../../base-components/ImagesPopup/index.jsx";

function Login() {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const { getLoggedObject } = useAuth();
  const [remarks, setRemarks] = useState("");
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(0);
  const [popup, setPopup] = useState(false);
  const [data, setData] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [status, setStatus] = useState([]);
  const [customer, setCustomer] = useState("");
  const [LPOUpload, setLPOUpload] = useState(false);

  const [selected, setSelected] = useState(0);
  const [imagedata, setImageData] = useState("");
  const [lponumber, setlponumber] = useState("");
  const [reject, setReject] = useState(false);
  const [deliverydate, setDeliveryDate] = useState(false);
  const [id, setID] = useState(0);
  const [completeod, setCompleteOD] = useState(0);
  const [invoice, setInvoice] = useState("");
  const [history, setHistory] = useState([]);
  const [isLink, setisLink] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("type")) {
      const viewValue = params.get("type");
      setEdit(viewValue);
    } else {
      setEdit(0);
    }
  }, []);

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  useEffect(() => {
    fetchOrderDetails();
  }, [slugFromUrl]);

  const fetchOrderDetails = async () => {
    const payload = {
      token: getLoggedObject()?.token,
      id: slugFromUrl,
      type: getLoggedObject()?.ac_type,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/fetch_beskpok_order_details"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setData(data?.data);
        setVendor(data?.vendors);
        setCustomer(data?.customer);
        setStatus(data?.statuses);
        setHistory(data?.history);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const [Remarkloading, setRemarkloading] = useState(false);

  const submit_remarks = async (val) => {
    if (remarks == "") {
      toast.error("Please enter your remarks!");
      return;
    }
    const payload = {
      token: getLoggedObject()?.token,
      id: val,
      remarks: remarks,
      is_link: `${isLink ? 1 : 0}`,
    };

    setRemarkloading(true);
    const { isError, data } = await doPost(payload, "auth/submit_status");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
      setRemarkloading(false);
    } else {
      if (data.action == "success") {
        fetchOrderDetails();
        toast.success("Remarks updated successfully!");
        setRemarks("");
        setLoading(false);
        setisLink(false);
        setRemarkloading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
        setRemarkloading(false);
      }
    }
  };

  const [ApproveBudgetload, setApproveBudgetload] = useState(false);

  const do_approvr_budget = async (val) => {
    const payload = {
      token: getLoggedObject()?.token,
      id: val,
    };
    setApproveBudgetload(true);
    const { isError, data } = await doPost(payload, "auth/do_approvr_budget");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
      setApproveBudgetload(false);
    } else {
      if (data.action == "success") {
        fetchOrderDetails();
        toast.success("Budget Approved updated successfully!");
        setLoading(false);
        setApproveBudgetload(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
        setApproveBudgetload(false);
      }
    }
  };

  const [RejectBudgetload, setRejectBudgetload] = useState(false);

  const do_reject_budget = async (val) => {
    const payload = {
      token: getLoggedObject()?.token,
      id: val,
    };
    setRejectBudgetload(true);
    const { isError, data } = await doPost(payload, "auth/do_reject_budget");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
      setRejectBudgetload(false);
    } else {
      if (data.action == "success") {
        fetchOrderDetails();
        toast.success(
          "Budget Rejected & notification sent to Order creator successfully!"
        );
        setLoading(false);
        setRejectBudgetload(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
        setRejectBudgetload(false);
      }
    }
  };

  const [selectedItems, setSelectedItems] = useState([]);
  const handleBrandChange = (e) => {
    const selectedBrandId = e.target.value;
    const selectedBrandName = e.target.options[e.target.selectedIndex].text;
    setSelectedItems([
      ...selectedItems,
      { id: selectedBrandId, title: selectedBrandName },
    ]);
    e.target.options[e.target.selectedIndex].disabled = true;
  };

  const delete_pill = (itemToDelete) => {
    const updatedSelectedItems = selectedItems.filter(
      (item) => item.id !== itemToDelete.id
    );
    const selectElement = document.querySelector(
      'select[name="vendor_selection"]'
    );
    const optionToRemoveDisabled = selectElement.querySelector(
      `option[value="${itemToDelete.id}"]`
    );
    if (optionToRemoveDisabled) {
      optionToRemoveDisabled.disabled = false;
    }
    setSelectedItems(updatedSelectedItems);
  };

  const [SupplierLoad, setSupplierLoad] = useState(false);

  const do_submit_supplier = async (val) => {
    if (selectedItems.length == 0) {
      toast.error("Please select atleast one or more vendors.");
      return;
    }
    const payload = {
      token: getLoggedObject()?.token,
      id: val,
      vendors: selectedItems,
    };
    setSupplierLoad(true);
    const { isError, data } = await doPost(payload, "auth/do_submit_supplier");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
      setSupplierLoad(false);
    } else {
      if (data.action == "success") {
        fetchOrderDetails();
        setSelectedItems([]);
        toast.success("Quotation request sent to vendors successfully!");
        setLoading(false);
        setSupplierLoad(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
        setSupplierLoad(false);
      }
    }
  };

  const do_click_file = () => {
    fileInputRef.current.click();
  };

  const update_file_csv = (e) => {
    let files = e.target.files;
    if (files.length > 0) {
      let fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      fileReader.onload = (event) => {
        setImageData(event.target.result);
      };
    }
  };

  const [ccvloading, setccvloading] = useState(false);

  const handle_csv = async () => {
    if (imagedata == "") {
      toast.error("Please upload a file!");
      return;
    }
    const payload = {
      image: imagedata,
      name: lponumber,
      id: selected,
      token: getLoggedObject()?.token,
      type: getLoggedObject()?.ac_type == 9 ? 2 : 1,
      bespok: 1,
    };
    setccvloading(true);
    const { isError, data } = await doPost(
      payload,
      getLoggedObject()?.ac_type == 9
        ? "auth/upload_proof_vendor"
        : "auth/upload_lpo"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setccvloading(false);
    } else {
      if (data.action == "success") {
        if (getLoggedObject()?.ac_type == 9) {
          toast.success("Proof uploaded & notification sent successfully!");
          setccvloading(false);
        } else {
          toast.success("LPO uploaded & notified the vendor successfully!");
          setccvloading(false);
        }

        setLPOUpload(false);
        setSelected(0);
        fetchOrderDetails();
        setImageData("");
        setccvloading(false);
      } else {
        toast.error(data?.error);
        setccvloading(false);
      }
    }
  };

  const upload_lpo = async (id) => {
    setLPOUpload(true);
    setSelected(id);
  };

  const [confirmDilveryload, setconfirmDilveryload] = useState(false);

  const confirm_delivery_date = (id) => {
    setDeliveryDate(true);
    setID(id);
  };

  const submit_delivery = async (id) => {
    const payload = {
      id: id,
      token: getLoggedObject()?.token,
      date: lponumber,
      type: getLoggedObject()?.ac_type == 9 ? 2 : 0,
      bespok: 1,
    };
    setconfirmDilveryload(true);
    const { isError, data } = await doPost(
      payload,
      "auth/bespoke_delivery_date_by_vendor"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setconfirmDilveryload(false);
    } else {
      if (data.action == "success") {
        toast.success("Information submitted successfully!");
        setDeliveryDate(false);
        setID(0);
        fetchOrderDetails();
        setconfirmDilveryload(false);
      } else {
        toast.error(data?.error);
        setconfirmDilveryload(false);
      }
    }
  };

  const [RejDelDateload, setRejDelDateload] = useState(false);

  const reject_submit = async (val) => {
    if (lponumber == "") {
      toast.error("Please enter reason of rejection!");
      return;
    }
    const payload = {
      reason: lponumber,
      id: val,
      token: getLoggedObject()?.token,
    };
    setRejDelDateload(true);
    const { isError, data } = await doPost(
      payload,
      "auth/reject_date_delivery"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setRejDelDateload(false);
    } else {
      if (data.action == "success") {
        toast.success("information submitted successfully!");
        setlponumber("");
        setReject(false);
        fetchOrderDetails();
        setRejDelDateload(false);
      } else {
        toast.error(data?.error);
        setRejDelDateload(false);
      }
    }
  };

  const [Approveddildateload, setApproveddildateload] = useState(false);

  const approve_date_submit = async (val) => {
    // if(lponumber==""){
    //     toast.error('Please enter reason of rejection!');
    //     return;
    // }
    const payload = {
      // reason: lponumber,
      id: val,
      token: getLoggedObject()?.token,
    };
    setApproveddildateload(true);
    const { isError, data } = await doPost(payload, "auth/approve_date_submit");
    if (isError) {
      toast.error("Something went wrong with server.");
      setApproveddildateload(false);
    } else {
      if (data.action == "success") {
        toast.success("information submitted successfully!");
        setlponumber("");
        setReject(false);
        fetchOrderDetails();
        setApproveddildateload(false);
      } else {
        toast.error(data?.error);
        setApproveddildateload(false);
      }
    }
  };

  // Approve or Reject Link

  const approve_Link = async (val, status) => {
    const payload = {
      id: val.id,
      isapprove: status,
      token: getLoggedObject()?.token,
    };

    const { isError, data } = await doPost(payload, "auth/submit_link_update");
    if (isError) {
      toast.error("Something went wrong with server.");
    } else {
      if (data.action == "success") {
        toast.success("Link status update successfully!");
        fetchOrderDetails();
      } else {
        toast.error(data?.error);
      }
    }
  };

  // Confirmations Link

  const [confrimloading, setConfirmloading] = useState(false);
  const [conformlink, setConfirmLink] = useState("");

  const Confirmation_Link = async (val) => {
    if (conformlink === "") {
      toast.error("Please Enter Link");
      return;
    }
    const payload = {
      id: val.id,
      url: conformlink,
      token: getLoggedObject()?.token,
    };

    setConfirmloading(true);

    const { isError, data } = await doPost(
      payload,
      "auth/submit_beskpok_artwork"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setConfirmloading(false);
    } else {
      if (data.action == "success") {
        toast.success("Link status update successfully!");
        fetchOrderDetails();
        setConfirmloading(false);
      } else {
        toast.error(data?.error);
        setConfirmloading(false);
      }
    }
  };

  const [complete, setComplete] = useState(false);
  const [completeorderload, setcompleteorderload] = useState(false);

  const complete_order_popup = async (val) => {
    setComplete(true);
    setID(val);
  };

  const handle_complete = async () => {
    if (imagedata == "") {
      toast.error("Please upload delivery notes!");
      return;
    }
    if (invoice == "") {
      toast.error("Please upload invoice!");
      return;
    }
    const payload = {
      image: imagedata,
      invoice: invoice,
      id: id,
      token: getLoggedObject()?.token,
      type: getLoggedObject()?.ac_type == 9 ? 2 : 1,
      bespok: 1,
    };
    //   console.log(payload);
    //   return;
    setcompleteorderload(true);
    const { isError, data } = await doPost(payload, "auth/complete_order");
    if (isError) {
      toast.error("Something went wrong with server.");
      setcompleteorderload(false);
    } else {
      if (data.action == "success") {
        toast.success("Order completed successfully!");
        setComplete(false);
        setID(0);
        fetchOrderDetails();
        setImageData("");
        setInvoice("");
        setcompleteorderload(false);
      } else {
        toast.error(data?.error);
        setcompleteorderload(false);
      }
    }
  };

  const fileInputRefinvoice = useRef(null);
  const fileInputRefinvoicetwo = useRef(null);
  const do_click_file_complete = (val) => {
    setCompleteOD(val);
    if (val == 1) {
      fileInputRefinvoice.current.click();
    } else {
      fileInputRefinvoicetwo.current.click();
    }
  };

  const update_file_complete = (e) => {
    let files = e.target.files;
    if (files.length > 0) {
      let fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      fileReader.onload = (event) => {
        if (completeod == 1) {
          setImageData(event.target.result);
        } else {
          setInvoice(event.target.result);
        }
        fileInputRefinvoice.current.value = "";
      };
    }
  };

  const handledownload = async (val) => {
    try {
      const response = await fetch(val);
      const contentType = response.headers.get("content-type");
      const extension = contentType.split("/")[1]; // Extract file extension from content type
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `filename.${extension}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowFormatted = tomorrow.toISOString().split("T")[0];

  const [ImagePopupStatus, setImagePopupStatus] = useState(false);
  const [image, setImage] = useState("");

  const handleImgPop = (item) => {
    setImagePopupStatus(true);
    setImage(item);
  };

  ////// Add Vendor Response

  const [Vendorloading, setVendorloading] = useState(false);
  const [VendorResponse, setVendorResponse] = useState("");
  const { slug } = useParams();
  const AddVendorResponse = async () => {
    if (VendorResponse == "") {
      toast.error("Please enter response");
      return;
    }

    setVendorloading(true);
    const payload = {
      id: slug,
      response: VendorResponse,
      token: getLoggedObject()?.token,
    };

    setcompleteorderload(true);
    const { isError, data } = await doPost(
      payload,
      "auth/sendVendorResponseToBeskopOrder"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setVendorloading(false);
    } else {
      if (data.status == "success") {
        toast.success("Response added successfully!");
        setVendorloading(false);
        setVendorResponse("");
        fetchOrderDetails();
      } else {
        setVendorloading(false);
      }
    }
  };

  return (
    <div className="container">
      <div className="wrapper-full">
        <div className="page-content">
          <SideBar />
          <div className="right_side_bar">
            <Header title={"Bespoke Project Details"} />
            <div className="content_inner">
              <div className="flex-space-between-stretch products_main_Wrap gap_20">
                <div className="besk_detail_right">
                  <div className="card p20 wd100">
                    <div
                      className="flex-space-between"
                      style={{ marginBottom: "20px" }}
                    >
                      <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className="button_followup ml-0"
                      >
                        Back
                      </button>
                      <div className="order_bsss">Order#: {data.order_id}</div>
                    </div>
                    <div
                      className="flex-space-between image_four"
                      style={{ justifyContent: "space-evenly" }}
                    >
                      {data.image != "" && (
                        <>
                          <img
                            style={{ cursor: "pointer" }}
                            onClick={() => handleImgPop(data.image)}
                            src={data.image}
                          />
                        </>
                      )}

                      {data.imagetwo != "" && (
                        <>
                          <img
                            style={{ cursor: "pointer" }}
                            onClick={() => handleImgPop(data.imagetwo)}
                            src={data.imagetwo}
                          />
                        </>
                      )}

                      {data.imagethree != "" && (
                        <>
                          <img
                            style={{ cursor: "pointer" }}
                            onClick={() => handleImgPop(data.imagethree)}
                            src={data.imagethree}
                          />
                        </>
                      )}

                      {data.imagefour != "" && (
                        <>
                          <img
                            style={{ cursor: "pointer" }}
                            onClick={() => handleImgPop(data.imagefour)}
                            src={data.imagefour}
                          />
                        </>
                      )}
                    </div>

                    {ImagePopupStatus && (
                      <ImagesPop
                        imgsrc={image}
                        onClose={() => setImagePopupStatus(false)}
                      />
                    )}

                    <div className="">
                      <div
                        className="name_pro_details mb-10"
                        style={{ fontSize: "16px", marginTop: "20px" }}
                      >
                        Project name : {data?.name}
                      </div>

                      <div className="desc_pro">
                        Short Description : {data?.shortdescp}
                      </div>

                      {/* <div className="pro_sub_details">
                        <span className="heading_pro_left">Brand</span>
                        <span>{data?.brand}</span>
                      </div> */}
                      {getLoggedObject().ac_type == "2" ||
                        (getLoggedObject().ac_type == "3" && (
                          <div className="pro_sub_details">
                            <span className="heading_pro_left">Budget</span>
                            <span>{data?.budget}</span>
                          </div>
                        ))}
                      <div className="pro_sub_details">
                        <span className="heading_pro_left">Deadline</span>
                        <span>{data?.deliver_date}</span>
                      </div>
                      <div className="pro_sub_details">
                        <span className="heading_pro_left">
                          Dimensions / Color{" "}
                        </span>
                        <span>{data?.dimension}</span>
                      </div>
                      <div className="pro_sub_details">
                        <span className="heading_pro_left">Kam</span>
                        <span>{data?.kam}</span>
                      </div>
                      <div className="pro_sub_details">
                        <span className="heading_pro_left">Request Type</span>
                        <span>{data?.requesttype}</span>
                      </div>
                      <div className="pro_sub_details">
                        <span className="heading_pro_left">Site visit</span>
                        <span>{data?.sitevisit == 0 ? "No" : "Yes"}</span>
                      </div>
                      <div className="pro_sub_details">
                        <span className="heading_pro_left">Brief</span>
                        <span>{data?.brief}</span>
                      </div>
                      {getLoggedObject().ac_type == "3" && (
                        <div className="pro_sub_details">
                          <span
                            style={{ marginTop: "5px" }}
                            className="heading_pro_left"
                          >
                            Final Artwork Submission
                          </span>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <div className="form-group ">
                              <input
                                type="url"
                                name="confirm_Artwork"
                                onChange={(e) => setConfirmLink(e.target.value)}
                                className="detaileds_input"
                                placeholder=""
                              />
                            </div>
                            <button
                              onClick={() => Confirmation_Link(data)}
                              disabled={confrimloading}
                              className="button_followup ml-0"
                            >
                              {confrimloading ? <Loader /> : "Submit"}
                            </button>
                          </div>
                        </div>
                      )}
                      {data.wetransfer != "" && (
                        <div className="pro_sub_details">
                          <span className="heading_pro_left">
                            Link Submitted
                          </span>
                          <span>
                            <a href={data.wetransfer} target="_blank">
                              {data.wetransfer}
                            </a>
                          </span>
                        </div>
                      )}
                      {data.vendor_date != "" && (
                        <div className="pro_sub_details">
                          <span className="heading_pro_left">
                            Vendor Delivery Date
                          </span>
                          <span>{data.vendor_date}</span>
                        </div>
                      )}
                      {data.status == 6 && (
                        <>
                          <div className="pro_sub_details">
                            <span className="heading_pro_left">
                              Delivery Note
                            </span>
                            <Link to={data.delivery_note} target="_blank">
                              <span
                                className="cursor"
                                // onClick={() =>
                                //   handledownload(data.delivery_note)
                                // }
                              >
                                <AiOutlineDownload
                                  size={22}
                                  color="var(--primary-color)"
                                />
                              </span>
                            </Link>
                          </div>
                        </>
                      )}

                      {data.status == 6 && getLoggedObject()?.ac_type != 4 && (
                        <>
                          <div className="pro_sub_details">
                            <span className="heading_pro_left">Invoice</span>
                            <span
                              className="cursor"
                              onClick={() => handledownload(data.invoice)}
                            >
                              <AiOutlineDownload
                                size={22}
                                color="var(--primary-color)"
                              />
                            </span>
                          </div>
                        </>
                      )}

                      {getLoggedObject().ac_type == 9 && (
                        <>
                          <div className="pro_sub_details">
                            <span className="heading_pro_left">
                              Previous Response
                            </span>
                            <span style={{color:"#5CA399"}}>{data?.vendor_response}</span>
                          </div>
                          <div className="pro_sub_details">
                            <span className="heading_pro_left">
                              Vendor Submission
                            </span>
                            <div className="VendSbmissionBox">
                              <input
                                onChange={(e) =>
                                  setVendorResponse(e.target.value)
                                }
                                value={VendorResponse}
                                placeholder="Size / Link / Details to be submitted here"
                              />
                              <button
                                disabled={Vendorloading}
                                type="button"
                                className=""
                                onClick={AddVendorResponse}
                              >
                                {Vendorloading ? <Loader /> : "Submit"}
                              </button>
                            </div>
                          </div>
                        </>
                      )}

                      {getLoggedObject().ac_type != 9 &&
                        data?.vendor_response != null && (
                          <div className="pro_sub_details">
                            <span className="heading_pro_left">
                              Vendor Response
                            </span>
                            <span>{data?.vendor_response}</span>
                          </div>
                        )}

                      {data.approval == 0 && data.delivery_status == 2 && (
                        <>
                          <div className="reason_proof">
                            <b>DELIVERY DATE REJECTION REASON:</b>
                            <br />
                            <br />
                            {data.delivery_reason}
                          </div>
                        </>
                      )}

                      {getLoggedObject()?.ac_type == 9 &&
                        data.vendor == getLoggedObject()?.id && (
                          <>
                            <div className="button_sub_box popup_beskpok_button mt-5p">
                              <button
                                type="button"
                                className="reject_button"
                                onClick={() => navigate("/suppliers_orders")}
                              >
                                Back
                              </button>
                              {data.eta != "" &&
                                data.approval == 0 &&
                                (data.delivery_status == 0 ||
                                  data.delivery_status == 2) && (
                                  <>
                                    <button
                                      type="button"
                                      className="approve_button"
                                      style={{
                                        width: "auto",
                                        padding: "5px 20px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        confirm_delivery_date(data.id);
                                      }}
                                    >
                                      CONFIRM DELIVERY DATE
                                    </button>
                                  </>
                                )}

                              {data.status == 5 &&
                                data.delivery_status == 1 && (
                                  <>
                                    <button
                                      disabled={completeorderload}
                                      type="button"
                                      className="approve_button"
                                      style={{
                                        width: "auto",
                                        padding: "5px 20px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        complete_order_popup(data.id);
                                      }}
                                    >
                                      {completeorderload ? (
                                        <Loader />
                                      ) : (
                                        "COMPLETE ORDER"
                                      )}
                                    </button>
                                  </>
                                )}
                            </div>
                          </>
                        )}

                      {reject && (
                        <>
                          <div className="popup_outer">
                            <div className="popup_inner">
                              <div className="popup_white_center">
                                <div className="modal_heading">
                                  <span>Reject Delivery Date</span>
                                  <span
                                    className="close_icon"
                                    onClick={() => setReject(false)}
                                  >
                                    <AiFillCloseCircle />
                                  </span>
                                </div>
                                <div className="form_setcion">
                                  <div className="form-group">
                                    <label>Reason of Rejection</label>
                                    <textarea
                                      type="number"
                                      style={{ height: "100px" }}
                                      value={lponumber}
                                      className="form-control"
                                      onChange={(e) =>
                                        setlponumber(e.target.value)
                                      }
                                    ></textarea>
                                  </div>

                                  <div className="">
                                    <button
                                      type="button"
                                      className="button_submit"
                                      onClick={() => reject_submit(data.id)}
                                    >
                                      {RejDelDateload ? <Loader /> : "Submit"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {deliverydate && (
                        <>
                          <div className="popup_outer">
                            <div className="popup_inner">
                              <div className="popup_white_center">
                                <div className="modal_heading">
                                  <span>Delivery Date</span>
                                  <span
                                    className="close_icon"
                                    onClick={() => setDeliveryDate(false)}
                                  >
                                    <AiFillCloseCircle />
                                  </span>
                                </div>
                                <div className="form_setcion">
                                  <p
                                    style={{
                                      textTransform: "uppercase",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    Expected delivery date:{" "}
                                    <span className="blue">
                                      {data.deliver_date}
                                    </span>
                                  </p>
                                  <div className="form-group">
                                    <label>Enter Delivery Date</label>
                                    <input
                                      type="date"
                                      min={tomorrowFormatted}
                                      value={lponumber}
                                      className="form-control"
                                      onChange={(e) =>
                                        setlponumber(e.target.value)
                                      }
                                    />
                                  </div>

                                  <div className="">
                                    <button
                                      disabled={confirmDilveryload}
                                      type="button"
                                      className="button_submit"
                                      onClick={() => submit_delivery(data.id)}
                                    >
                                      {confirmDilveryload ? (
                                        <Loader />
                                      ) : (
                                        "Submit"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {getLoggedObject()?.ac_type != 9 && (
                        <>
                          {(getLoggedObject()?.ac_type == 3 ||
                            getLoggedObject()?.ac_type == 2) &&
                            data.budget_exceeded == 1 && (
                              <>
                                <div
                                  className="budget_right"
                                  style={{ marginTop: "40px" }}
                                >
                                  <div
                                    className="top_nam_budget"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    AED {data.actual_budget} /{" "}
                                    <span style={{ color: "#908686" }}>
                                      AED {customer.budget}
                                    </span>
                                  </div>

                                  <div className="flex-space-between gap_20 customer_mov">
                                    <div className="budget_barrr">
                                      <span
                                        className="bar_filled"
                                        style={{
                                          width: data.budget_customer + "%",
                                          background:
                                            "linear-gradient(180deg, #FA3C3C 0%, #B14C4C 100%)",
                                        }}
                                      ></span>
                                    </div>
                                    <div className="buget_price">
                                      AED {data.budget}
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-5p">
                                  <div className="button_sub_box popup_beskpok_button">
                                    <button
                                      style={{ cursor: "pointer" }}
                                      disabled={RejectBudgetload}
                                      className="reject_button"
                                      onClick={() => do_reject_budget(data.id)}
                                    >
                                      {RejectBudgetload ? <Loader /> : "Reject"}
                                    </button>
                                    <button
                                      style={{ cursor: "pointer" }}
                                      disabled={ApproveBudgetload}
                                      className="approve_button"
                                      onClick={() => do_approvr_budget(data.id)}
                                    >
                                      {ApproveBudgetload ? (
                                        <Loader />
                                      ) : (
                                        "Approve"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}

                          {getLoggedObject()?.ac_type == 3 &&
                            data.approval == 1 && (
                              <>
                                <div className="mt-5p">
                                  <div className="button_sub_box popup_beskpok_button">
                                    <button
                                      className="reject_button"
                                      onClick={() => setReject(true)}
                                    >
                                      Reject
                                    </button>
                                    <button
                                      disabled={Approveddildateload}
                                      style={{ cursor: "pointer" }}
                                      className="approve_button"
                                      onClick={() =>
                                        approve_date_submit(data.id)
                                      }
                                    >
                                      {Approveddildateload ? (
                                        <Loader />
                                      ) : (
                                        "Approve"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}

                          {(getLoggedObject()?.ac_type == 3 ||
                            getLoggedObject()?.ac_type == 2) &&
                            data.status == 3 && (
                              <>
                                <div className="mt-5p">
                                  <div className="button_sub_box popup_beskpok_button">
                                    {/* <button className="reject_button" onClick={()=> do_reject_budget(data.id)}>Reject</button> */}
                                    <button
                                      style={{ cursor: "pointer" }}
                                      className="approve_button"
                                      onClick={() => upload_lpo(data.id)}
                                    >
                                      Upload LPO
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}

                          {getLoggedObject()?.ac_type == 2 &&
                            data.budget_exceeded == 0 &&
                            data.budget_status == 1 &&
                            data.vendor_list == null && (
                              <>
                                <div className="mt-5p">
                                  <div className="button_sub_box popup_beskpok_button">
                                    <select
                                      name="vendor_selection"
                                      className="vendorrr_select"
                                      value={""}
                                      onChange={(e) => handleBrandChange(e)}
                                    >
                                      <option value={""}>
                                        --Select Vendor--
                                      </option>
                                      {vendor.map((v, i) => (
                                        <>
                                          <option value={v.id}>
                                            {v.companyname}
                                          </option>
                                        </>
                                      ))}
                                    </select>
                                    {/* <span style={{color:"#87888C", marginTop:"5px",  marginLeft:"10px"}}>Add More...</span> */}
                                  </div>
                                </div>
                                <>
                                  {selectedItems.length > 0 && (
                                    <div className="pills_wrap_flex">
                                      {selectedItems.map((v, i) => (
                                        <>
                                          <div className="pills_user_cars">
                                            <div className="">{v.title}</div>
                                            <div
                                              className="cross_"
                                              style={{ cursor: "pointer" }}
                                              onClick={() => delete_pill(v)}
                                            >
                                              x
                                            </div>
                                          </div>
                                        </>
                                      ))}
                                    </div>
                                  )}
                                </>
                                <div className="mt-5p">
                                  <div className="button_sub_box popup_beskpok_button">
                                    {/* <button className="reject_button" onClick={()=> setPopup(true)}>Reject</button> */}
                                    <button
                                      style={{ cursor: "pointer" }}
                                      disabled={SupplierLoad}
                                      className="approve_button"
                                      onClick={() =>
                                        do_submit_supplier(data.id)
                                      }
                                    >
                                      {SupplierLoad ? <Loader /> : "Submit"}
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="besk_detail_left ">
                  <div className="">
                    <div className="beskpoke_customer">
                      <div className="card p10 wd100">
                        <u
                          style={{
                            fontSize: "14px",
                            marginBottom: "20px",
                            display: "block",
                          }}
                        >
                          Customer Details:
                        </u>
                        <div className="customer_beskpoksss">
                          <span className="besk1">Account Number:</span>
                          <span>{customer.accoutnumber}</span>
                        </div>
                        <div className="customer_beskpoksss">
                          <span className="besk1">Account Name:</span>
                          <span>{customer.accountname}</span>
                        </div>
                        <div className="customer_beskpoksss">
                          <span className="besk1">Parent Account:</span>
                          <span>{customer.parentaccount}</span>
                        </div>
                        <div className="customer_beskpoksss">
                          <span className="besk1">Contact</span>
                          <span>
                            {customer.contact}
                            <br />
                            {customer.contactnumber}
                          </span>
                        </div>
                      </div>

                      {getLoggedObject()?.ac_type != 9 && (
                        <>
                          <div className="besk_big_heading">Status:</div>
                          {status.map((v, i) => (
                            <div className="card p10 wd100" key={i}>
                              <div className="detaor_beskpoksss">
                                <span className="besk1 besk_dot_status">
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="8"
                                      height="8"
                                      viewBox="0 0 8 8"
                                      fill="none"
                                    >
                                      <circle
                                        cx="4"
                                        cy="4"
                                        r="4"
                                        fill="#669D8D"
                                      />
                                      <circle
                                        cx="4"
                                        cy="4"
                                        r="1.875"
                                        fill="#D2D2D2"
                                        stroke="black"
                                        strokeWidth="0.25"
                                      />
                                    </svg>
                                  </span>
                                  <div>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: v.title,
                                      }}
                                    ></span>
                                    <span
                                      style={{
                                        fontSize: "10px",
                                        lineHeight: "17px",
                                      }}
                                    >
                                      {" "}
                                      <br /> -{v?.added_by}
                                      {v?.is_link === "1" &&
                                        getLoggedObject()?.ac_type === "4" &&
                                        v?.reject_approve == 0 && (
                                          <span>
                                            &nbsp;&nbsp;
                                            <FaCheckCircle
                                              onClick={() =>
                                                approve_Link(v, "1")
                                              }
                                              style={{
                                                color: "green",
                                                cursor: "pointer",
                                              }}
                                            />{" "}
                                            &nbsp;{" "}
                                            <FaCircleXmark
                                              onClick={() =>
                                                approve_Link(v, "2")
                                              }
                                              style={{
                                                color: "red",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </span>
                                        )}
                                      {v?.reject_approve === 2 && (
                                        <span style={{ color: "red" }}>
                                          {" "}
                                          Rejected{" "}
                                        </span>
                                      )}
                                      {v?.reject_approve === 1 && (
                                        <span style={{ color: "green" }}>
                                          {" "}
                                          Accepted{" "}
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                </span>
                                {v.date != null && (
                                  <span style={{ fontSize: "9px" }}>
                                    -{v.date}
                                  </span>
                                )}
                              </div>
                            </div>
                          ))}
                        </>
                      )}

                      {getLoggedObject()?.ac_type != 9 && (
                        <>
                          <div className="besk_big_heading">Remarks:</div>
                          <div className="card p10 wd100">
                            <textarea
                              name=""
                              className="besk_remarks"
                              value={remarks}
                              onChange={(e) => setRemarks(e.target.value)}
                            ></textarea>
                            <div
                              className="mt-10px"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <button
                                disabled={Remarkloading}
                                className="button_followup ml-0"
                                onClick={() => submit_remarks(data.id)}
                              >
                                {Remarkloading ? <Loader /> : "Submit"}
                              </button>
                              {getLoggedObject().ac_type == "3" && (
                                <span
                                  style={{
                                    marginLeft: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  Artwork Link{" "}
                                  <input
                                    name="atwork"
                                    onChange={(e) =>
                                      setisLink(e.target.checked)
                                    }
                                    type="checkbox"
                                  />
                                </span>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {popup && (
                  <>
                    <div className="popup_outer">
                      <div className="popup_inner">
                        <div className="popup_white_center ">
                          <div className="modal_heading">
                            <span>Cancel Reject</span>
                            <span
                              className="close_icon"
                              onClick={() => setPopup(false)}
                            >
                              <AiFillCloseCircle />
                            </span>
                          </div>
                          <div className="form_setcion ">
                            <div
                              className="button_sub_box"
                              style={{ margin: "auto" }}
                            >
                              <button
                                className="reject_button"
                                onClick={() => setPopup(false)}
                              >
                                Cancel
                              </button>
                              <button
                                className="approve_button"
                                onClick={() => navigate("/orders")}
                              >
                                Submit
                              </button>
                            </div>
                            <span className="undomn">
                              *The action cannot be undone.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {LPOUpload && (
                  <>
                    <div className="popup_outer">
                      <div className="popup_inner">
                        <div className="popup_white_center">
                          <div className="modal_heading">
                            <span>
                              {getLoggedObject()?.ac_type == 9
                                ? "Upload Proof"
                                : "Upload LPO"}
                            </span>
                            <span
                              className="close_icon"
                              onClick={() => setLPOUpload(false)}
                            >
                              <AiFillCloseCircle />
                            </span>
                          </div>
                          <div className="form_setcion">
                            {getLoggedObject()?.ac_type != 9 && (
                              <div className="form-group">
                                <label>LPO #</label>
                                <input
                                  type="number"
                                  value={lponumber}
                                  className="form-control"
                                  onChange={(e) => setlponumber(e.target.value)}
                                />
                              </div>
                            )}
                            <div className="form-group">
                              <label>
                                Upload{" "}
                                {getLoggedObject()?.ac_type == 9
                                  ? "PROOF"
                                  : "LPO"}{" "}
                                Image
                              </label>
                              <div
                                className="image_browse_button"
                                style={{ width: "100%" }}
                                onClick={() => do_click_file()}
                              >
                                {imagedata == "" ? (
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M7.27269 5.84638C7.74361 5.53172 8.29726 5.36377 8.86364 5.36377C9.22727 5.36377 9.52204 5.65855 9.52204 6.02218C9.52204 6.38581 9.22727 6.68059 8.86364 6.68059C8.5577 6.68059 8.25864 6.7713 8.00427 6.94127C7.7499 7.11124 7.55164 7.35282 7.43456 7.63546C7.31749 7.91811 7.28685 8.22912 7.34654 8.52917C7.40622 8.82923 7.55354 9.10485 7.76987 9.32117C7.9862 9.5375 8.26181 9.68482 8.56187 9.7445C8.86192 9.80419 9.17293 9.77356 9.45558 9.65648C9.73822 9.53941 9.9798 9.34115 10.1498 9.08677C10.3197 8.8324 10.4105 8.53334 10.4105 8.22741C10.4105 7.86378 10.7052 7.569 11.0689 7.569C11.4325 7.569 11.7273 7.86378 11.7273 8.22741C11.7273 8.79378 11.5593 9.34743 11.2447 9.81836C10.93 10.2893 10.4828 10.6563 9.9595 10.8731C9.43624 11.0898 8.86046 11.1465 8.30497 11.036C7.74948 10.9255 7.23923 10.6528 6.83874 10.2523C6.43825 9.85181 6.16552 9.34156 6.05503 8.78607C5.94453 8.23058 6.00124 7.6548 6.21798 7.13154C6.43472 6.60828 6.80176 6.16104 7.27269 5.84638Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M23.1692 9.18164C23.5276 9.18164 23.8181 9.47822 23.8181 9.84408V15.3909C23.8181 18.2285 23.2693 20.3871 21.8733 21.8201C20.4765 23.254 18.3713 23.818 15.6052 23.818H9.12196C6.35673 23.818 4.25161 23.2542 2.85457 21.8205C1.45838 20.3876 0.909058 18.229 0.909058 15.3909V13.1607C0.909058 12.7948 1.1996 12.4983 1.55799 12.4983C1.91639 12.4983 2.20693 12.7948 2.20693 13.1607V15.3909C2.20693 18.0996 2.73916 19.8233 3.77474 20.8861C4.80947 21.948 6.48633 22.4931 9.12196 22.4931H15.6052C18.2417 22.4931 19.9186 21.9478 20.9531 20.8859C21.9884 19.823 22.5203 18.0993 22.5203 15.3909V9.84408C22.5203 9.47822 22.8108 9.18164 23.1692 9.18164Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M2.88158 2.86617C4.2972 1.46169 6.43034 0.90918 9.23439 0.90918H13.6183C13.9797 0.90918 14.2727 1.19988 14.2727 1.55847C14.2727 1.91706 13.9797 2.20775 13.6183 2.20775H9.23439C6.55948 2.20775 4.85718 2.74258 3.80709 3.7844C2.75701 4.82622 2.21793 6.51513 2.21793 9.16898C2.21793 9.52757 1.92493 9.81827 1.5635 9.81827C1.20206 9.81827 0.909058 9.52757 0.909058 9.16898C0.909058 6.387 1.46595 4.27065 2.88158 2.86617Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M15.5454 4.72718C15.5454 4.37573 15.8504 4.09082 16.2266 4.09082H22.5006C22.8768 4.09082 23.1818 4.37573 23.1818 4.72718C23.1818 5.07864 22.8768 5.36355 22.5006 5.36355H16.2266C15.8504 5.36355 15.5454 5.07864 15.5454 4.72718Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M19.3637 0.90918C19.7151 0.90918 20 1.21415 20 1.59035V7.86437C20 8.24057 19.7151 8.54554 19.3637 8.54554C19.0122 8.54554 18.7273 8.24057 18.7273 7.86437V1.59035C18.7273 1.21415 19.0122 0.90918 19.3637 0.90918Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M18.7588 13.4293L14.2551 17.3279L14.2431 17.3381C13.6956 17.7895 13.0085 18.0363 12.2995 18.0363C11.5905 18.0363 10.9035 17.7895 10.3559 17.3381C10.35 17.3332 10.3442 17.3282 10.3385 17.3231L9.99081 17.0153C9.69968 16.7804 9.34208 16.6432 8.96864 16.6232C8.59289 16.6031 8.22049 16.7028 7.90482 16.9079L2.56182 20.5242C2.26358 20.7261 1.85852 20.6474 1.6571 20.3485C1.45567 20.0497 1.53416 19.6438 1.83239 19.4419L7.18878 15.8166C7.7369 15.4583 8.38457 15.2841 9.03805 15.319C9.69153 15.354 10.317 15.5963 10.824 16.0109C10.8306 16.0163 10.837 16.0218 10.8434 16.0275L11.1921 16.3362C11.505 16.591 11.8961 16.7303 12.2995 16.7303C12.7041 16.7303 13.0962 16.5903 13.4095 16.3341L17.9133 12.4354L17.9252 12.4253C18.4728 11.9739 19.1598 11.7271 19.8688 11.7271C20.5779 11.7271 21.2649 11.9739 21.8124 12.4253L21.8244 12.4354L23.5925 13.9661C23.8648 14.2019 23.8949 14.6142 23.6596 14.8872C23.4243 15.1601 23.0128 15.1902 22.7405 14.9544L20.9788 13.4293C20.6655 13.1731 20.2734 13.0331 19.8688 13.0331C19.4643 13.0331 19.0722 13.1731 18.7588 13.4293Z"
                                        fill="#8D98AA"
                                      />
                                    </svg>
                                    <span> Upload your Image</span>
                                  </>
                                ) : (
                                  <div className="image_upl_file">
                                    <img src={imagedata} />
                                  </div>
                                )}
                              </div>
                              <input
                                type="file"
                                name="logo"
                                id="logo"
                                accept=".png, .jpg, ,jpeg"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={update_file_csv}
                              />
                            </div>
                            <div className="">
                              <button
                                style={{ cursor: "pointer" }}
                                disabled={ccvloading}
                                type="button"
                                className="button_submit"
                                onClick={() => handle_csv()}
                              >
                                {ccvloading ? <Loader /> : "Upload"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/* ADD HISTORY */}
              {getLoggedObject()?.ac_type != 9 && (
                <div className="content_inner">
                  <div className="">
                    <div
                      className="card wd100 p30"
                      style={{ marginTop: "20px" }}
                    >
                      <h3
                        style={{
                          textTransform: "uppercase",
                          marginBottom: "20px",
                        }}
                      >
                        History
                      </h3>

                      {history.map((v, i) => (
                        <>
                          <div className="history_wrap">
                            <div className="history_number">{i + 1}</div>
                            <div className="history_data">
                              <div
                                className="content_history"
                                dangerouslySetInnerHTML={{ __html: v.content }}
                              ></div>
                              <div className="history_date">{v.date}</div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {complete && (
                <>
                  <div className="popup_outer">
                    <div className="popup_inner">
                      <div className="popup_white_center">
                        <div className="modal_heading">
                          <span>Complete Delivery</span>
                          <span
                            className="close_icon"
                            onClick={() => setComplete(false)}
                          >
                            <AiFillCloseCircle />
                          </span>
                        </div>
                        <div className="form_setcion">
                          <div className="form-group">
                            <label>Upload Delivery Note</label>
                            <div
                              className="image_browse_button"
                              style={{ width: "100%" }}
                              onClick={() => do_click_file_complete(1)}
                            >
                              {imagedata == "" ? (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.27269 5.84638C7.74361 5.53172 8.29726 5.36377 8.86364 5.36377C9.22727 5.36377 9.52204 5.65855 9.52204 6.02218C9.52204 6.38581 9.22727 6.68059 8.86364 6.68059C8.5577 6.68059 8.25864 6.7713 8.00427 6.94127C7.7499 7.11124 7.55164 7.35282 7.43456 7.63546C7.31749 7.91811 7.28685 8.22912 7.34654 8.52917C7.40622 8.82923 7.55354 9.10485 7.76987 9.32117C7.9862 9.5375 8.26181 9.68482 8.56187 9.7445C8.86192 9.80419 9.17293 9.77356 9.45558 9.65648C9.73822 9.53941 9.9798 9.34115 10.1498 9.08677C10.3197 8.8324 10.4105 8.53334 10.4105 8.22741C10.4105 7.86378 10.7052 7.569 11.0689 7.569C11.4325 7.569 11.7273 7.86378 11.7273 8.22741C11.7273 8.79378 11.5593 9.34743 11.2447 9.81836C10.93 10.2893 10.4828 10.6563 9.9595 10.8731C9.43624 11.0898 8.86046 11.1465 8.30497 11.036C7.74948 10.9255 7.23923 10.6528 6.83874 10.2523C6.43825 9.85181 6.16552 9.34156 6.05503 8.78607C5.94453 8.23058 6.00124 7.6548 6.21798 7.13154C6.43472 6.60828 6.80176 6.16104 7.27269 5.84638Z"
                                      fill="#8D98AA"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M23.1692 9.18164C23.5276 9.18164 23.8181 9.47822 23.8181 9.84408V15.3909C23.8181 18.2285 23.2693 20.3871 21.8733 21.8201C20.4765 23.254 18.3713 23.818 15.6052 23.818H9.12196C6.35673 23.818 4.25161 23.2542 2.85457 21.8205C1.45838 20.3876 0.909058 18.229 0.909058 15.3909V13.1607C0.909058 12.7948 1.1996 12.4983 1.55799 12.4983C1.91639 12.4983 2.20693 12.7948 2.20693 13.1607V15.3909C2.20693 18.0996 2.73916 19.8233 3.77474 20.8861C4.80947 21.948 6.48633 22.4931 9.12196 22.4931H15.6052C18.2417 22.4931 19.9186 21.9478 20.9531 20.8859C21.9884 19.823 22.5203 18.0993 22.5203 15.3909V9.84408C22.5203 9.47822 22.8108 9.18164 23.1692 9.18164Z"
                                      fill="#8D98AA"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M2.88158 2.86617C4.2972 1.46169 6.43034 0.90918 9.23439 0.90918H13.6183C13.9797 0.90918 14.2727 1.19988 14.2727 1.55847C14.2727 1.91706 13.9797 2.20775 13.6183 2.20775H9.23439C6.55948 2.20775 4.85718 2.74258 3.80709 3.7844C2.75701 4.82622 2.21793 6.51513 2.21793 9.16898C2.21793 9.52757 1.92493 9.81827 1.5635 9.81827C1.20206 9.81827 0.909058 9.52757 0.909058 9.16898C0.909058 6.387 1.46595 4.27065 2.88158 2.86617Z"
                                      fill="#8D98AA"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M15.5454 4.72718C15.5454 4.37573 15.8504 4.09082 16.2266 4.09082H22.5006C22.8768 4.09082 23.1818 4.37573 23.1818 4.72718C23.1818 5.07864 22.8768 5.36355 22.5006 5.36355H16.2266C15.8504 5.36355 15.5454 5.07864 15.5454 4.72718Z"
                                      fill="#8D98AA"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M19.3637 0.90918C19.7151 0.90918 20 1.21415 20 1.59035V7.86437C20 8.24057 19.7151 8.54554 19.3637 8.54554C19.0122 8.54554 18.7273 8.24057 18.7273 7.86437V1.59035C18.7273 1.21415 19.0122 0.90918 19.3637 0.90918Z"
                                      fill="#8D98AA"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M18.7588 13.4293L14.2551 17.3279L14.2431 17.3381C13.6956 17.7895 13.0085 18.0363 12.2995 18.0363C11.5905 18.0363 10.9035 17.7895 10.3559 17.3381C10.35 17.3332 10.3442 17.3282 10.3385 17.3231L9.99081 17.0153C9.69968 16.7804 9.34208 16.6432 8.96864 16.6232C8.59289 16.6031 8.22049 16.7028 7.90482 16.9079L2.56182 20.5242C2.26358 20.7261 1.85852 20.6474 1.6571 20.3485C1.45567 20.0497 1.53416 19.6438 1.83239 19.4419L7.18878 15.8166C7.7369 15.4583 8.38457 15.2841 9.03805 15.319C9.69153 15.354 10.317 15.5963 10.824 16.0109C10.8306 16.0163 10.837 16.0218 10.8434 16.0275L11.1921 16.3362C11.505 16.591 11.8961 16.7303 12.2995 16.7303C12.7041 16.7303 13.0962 16.5903 13.4095 16.3341L17.9133 12.4354L17.9252 12.4253C18.4728 11.9739 19.1598 11.7271 19.8688 11.7271C20.5779 11.7271 21.2649 11.9739 21.8124 12.4253L21.8244 12.4354L23.5925 13.9661C23.8648 14.2019 23.8949 14.6142 23.6596 14.8872C23.4243 15.1601 23.0128 15.1902 22.7405 14.9544L20.9788 13.4293C20.6655 13.1731 20.2734 13.0331 19.8688 13.0331C19.4643 13.0331 19.0722 13.1731 18.7588 13.4293Z"
                                      fill="#8D98AA"
                                    />
                                  </svg>
                                  <span> Upload your File</span>
                                </>
                              ) : (
                                <div className="image_upl_file">
                                  <img src={LOGO} />
                                </div>
                              )}
                            </div>
                            <input
                              type="file"
                              name="logo"
                              id="logo"
                              accept=".pdf"
                              ref={fileInputRefinvoice}
                              style={{ display: "none" }}
                              onChange={update_file_complete}
                            />

                            <input
                              type="file"
                              name="logo"
                              id="logo"
                              accept=".pdf"
                              ref={fileInputRefinvoicetwo}
                              style={{ display: "none" }}
                              onChange={update_file_complete}
                            />
                          </div>

                          <div className="form-group">
                            <label>Upload Invoice</label>
                            <div
                              className="image_browse_button"
                              style={{ width: "100%" }}
                              onClick={() => do_click_file_complete(2)}
                            >
                              {invoice == "" ? (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M7.27269 5.84638C7.74361 5.53172 8.29726 5.36377 8.86364 5.36377C9.22727 5.36377 9.52204 5.65855 9.52204 6.02218C9.52204 6.38581 9.22727 6.68059 8.86364 6.68059C8.5577 6.68059 8.25864 6.7713 8.00427 6.94127C7.7499 7.11124 7.55164 7.35282 7.43456 7.63546C7.31749 7.91811 7.28685 8.22912 7.34654 8.52917C7.40622 8.82923 7.55354 9.10485 7.76987 9.32117C7.9862 9.5375 8.26181 9.68482 8.56187 9.7445C8.86192 9.80419 9.17293 9.77356 9.45558 9.65648C9.73822 9.53941 9.9798 9.34115 10.1498 9.08677C10.3197 8.8324 10.4105 8.53334 10.4105 8.22741C10.4105 7.86378 10.7052 7.569 11.0689 7.569C11.4325 7.569 11.7273 7.86378 11.7273 8.22741C11.7273 8.79378 11.5593 9.34743 11.2447 9.81836C10.93 10.2893 10.4828 10.6563 9.9595 10.8731C9.43624 11.0898 8.86046 11.1465 8.30497 11.036C7.74948 10.9255 7.23923 10.6528 6.83874 10.2523C6.43825 9.85181 6.16552 9.34156 6.05503 8.78607C5.94453 8.23058 6.00124 7.6548 6.21798 7.13154C6.43472 6.60828 6.80176 6.16104 7.27269 5.84638Z"
                                      fill="#8D98AA"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M23.1692 9.18164C23.5276 9.18164 23.8181 9.47822 23.8181 9.84408V15.3909C23.8181 18.2285 23.2693 20.3871 21.8733 21.8201C20.4765 23.254 18.3713 23.818 15.6052 23.818H9.12196C6.35673 23.818 4.25161 23.2542 2.85457 21.8205C1.45838 20.3876 0.909058 18.229 0.909058 15.3909V13.1607C0.909058 12.7948 1.1996 12.4983 1.55799 12.4983C1.91639 12.4983 2.20693 12.7948 2.20693 13.1607V15.3909C2.20693 18.0996 2.73916 19.8233 3.77474 20.8861C4.80947 21.948 6.48633 22.4931 9.12196 22.4931H15.6052C18.2417 22.4931 19.9186 21.9478 20.9531 20.8859C21.9884 19.823 22.5203 18.0993 22.5203 15.3909V9.84408C22.5203 9.47822 22.8108 9.18164 23.1692 9.18164Z"
                                      fill="#8D98AA"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M2.88158 2.86617C4.2972 1.46169 6.43034 0.90918 9.23439 0.90918H13.6183C13.9797 0.90918 14.2727 1.19988 14.2727 1.55847C14.2727 1.91706 13.9797 2.20775 13.6183 2.20775H9.23439C6.55948 2.20775 4.85718 2.74258 3.80709 3.7844C2.75701 4.82622 2.21793 6.51513 2.21793 9.16898C2.21793 9.52757 1.92493 9.81827 1.5635 9.81827C1.20206 9.81827 0.909058 9.52757 0.909058 9.16898C0.909058 6.387 1.46595 4.27065 2.88158 2.86617Z"
                                      fill="#8D98AA"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M15.5454 4.72718C15.5454 4.37573 15.8504 4.09082 16.2266 4.09082H22.5006C22.8768 4.09082 23.1818 4.37573 23.1818 4.72718C23.1818 5.07864 22.8768 5.36355 22.5006 5.36355H16.2266C15.8504 5.36355 15.5454 5.07864 15.5454 4.72718Z"
                                      fill="#8D98AA"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M19.3637 0.90918C19.7151 0.90918 20 1.21415 20 1.59035V7.86437C20 8.24057 19.7151 8.54554 19.3637 8.54554C19.0122 8.54554 18.7273 8.24057 18.7273 7.86437V1.59035C18.7273 1.21415 19.0122 0.90918 19.3637 0.90918Z"
                                      fill="#8D98AA"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M18.7588 13.4293L14.2551 17.3279L14.2431 17.3381C13.6956 17.7895 13.0085 18.0363 12.2995 18.0363C11.5905 18.0363 10.9035 17.7895 10.3559 17.3381C10.35 17.3332 10.3442 17.3282 10.3385 17.3231L9.99081 17.0153C9.69968 16.7804 9.34208 16.6432 8.96864 16.6232C8.59289 16.6031 8.22049 16.7028 7.90482 16.9079L2.56182 20.5242C2.26358 20.7261 1.85852 20.6474 1.6571 20.3485C1.45567 20.0497 1.53416 19.6438 1.83239 19.4419L7.18878 15.8166C7.7369 15.4583 8.38457 15.2841 9.03805 15.319C9.69153 15.354 10.317 15.5963 10.824 16.0109C10.8306 16.0163 10.837 16.0218 10.8434 16.0275L11.1921 16.3362C11.505 16.591 11.8961 16.7303 12.2995 16.7303C12.7041 16.7303 13.0962 16.5903 13.4095 16.3341L17.9133 12.4354L17.9252 12.4253C18.4728 11.9739 19.1598 11.7271 19.8688 11.7271C20.5779 11.7271 21.2649 11.9739 21.8124 12.4253L21.8244 12.4354L23.5925 13.9661C23.8648 14.2019 23.8949 14.6142 23.6596 14.8872C23.4243 15.1601 23.0128 15.1902 22.7405 14.9544L20.9788 13.4293C20.6655 13.1731 20.2734 13.0331 19.8688 13.0331C19.4643 13.0331 19.0722 13.1731 18.7588 13.4293Z"
                                      fill="#8D98AA"
                                    />
                                  </svg>
                                  <span> Upload your File</span>
                                </>
                              ) : (
                                <div className="image_upl_file">
                                  <img src={LOGO} />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="">
                            <button
                              disabled={completeorderload}
                              type="button"
                              className="button_submit"
                              onClick={() => handle_complete()}
                            >
                              {completeorderload ? <Loader /> : "Submit"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
