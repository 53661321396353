import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost, check_role_restrictions  } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LOGO from "../../assets/images/logo.svg";
import Profile from "../../assets/images/pro_image.svg";

import SideBar from "./../../base-components/SideBar";
import SettingsBar from "./../../base-components/SideBar/Settings";
import Header from "./../../base-components/Header";
import { AiFillEdit, AiFillDelete, AiFillCloseCircle } from "react-icons/ai";

const projects =  [
    {title: "Nike", id: 1, date:"20 December 2023"},
    {title: "Adidas", id: 2, date:"20 December 2023"},
    {title: "Logo", id: 3, date:"20 December 2023"},

]
function Login() {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [upload, setUpload] = useState(false);
  const [role, setRole] = useState(0);
  const [data, setData] = useState([]);

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl = lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  const do_click_file = () => {
    fileInputRef.current.click();
  }

  const handle_submit = () => {
    toast.success("Brand has been created successfully!")
    setUpload(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (isLoggedIn) {
        try {
          const response = await check_role_restrictions();
            setRole(response);
            setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error("Error:", error);
        }
      }
    };
    fetchData();

    get_all_notifications()
  }, [isLoggedIn]);

  const get_all_notifications = async() => {
    const payload = {
      token: getLoggedObject()?.token,
      type: getLoggedObject()?.ac_type==9?2:0
    }
    const {isError, data} = await doPost(payload, 'auth/get_all_notifictaions');
    if(isError) {
        toast.error('Something went wrong with server.');
        setLoading(false);
    }else{
        if(data.action == "success") {
          setData(data?.data)
          setLoading(false)
        }
        else {
          setLoading(false);
          toast.error(data?.error);
        }
    }
  }

  const do_navigate = (val, beskpok) => {
    if(beskpok == 1){
      navigate("/beskpoke/details/"+val);
    } else {
      navigate("/order/details/"+val);
    }
    
  }


  return (
    <div className="container">
      <div className="wrapper-full">
        <div className="page-content">
          <SideBar />
          <div className="right_side_bar">
            <Header title={'Notifications'} />

            <div className="content_inner">
                <div className="content_settings">
                  
                {
                      (getLoggedObject()?.ac_type == -1 || getLoggedObject()?.ac_type == 2) &&
                     <SettingsBar />
                }

                  <div className="right_settings_box" style={(role==4 || getLoggedObject()?.ac_type == 9 || getLoggedObject()?.ac_type == 3)?{width:"100%"}:{}}>
                    <div className="tabs_show">

                    {
                        (role != 4 && getLoggedObject()?.ac_type != 9) &&
                        <>
                        {
                              (getLoggedObject()?.ac_type == -1 || getLoggedObject()?.ac_type == 2)  &&
                        <div onClick={()=>navigate("/settings") } className={`tabs cursor ${slugFromUrl == "settings"?"active_tab":""}`} >Account  Setting</div>
                        }
                        <div className={`tabs cursor ${slugFromUrl == "notifications"?"active_tab":""}`} onClick={()=>navigate("/notifications")}>Notifications</div>
                        
                      </>
                    }
                  </div>
                  {
                    (data.length == 0 && !loading) &&
                    <>
                      <div className="no_text_found">No notifications found..</div>
                    </>
                  }
                  {
                    data.map((v, i)=>(
                      <>
                        <div className="card p10 wd100 mb-10" style={(v.oID!=0 && v.show_details ==1)?{cursor:"pointer"}:{}} onClick={()=> {(v.oID!=0 && v.show_details ==1) && do_navigate(v.oID, v.beskpok)}}>
                            <div className="detaor_beskpoksss">
                                <span className="besk1 besk_dot_status">
                                    <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                        <circle cx="4" cy="4" r="4" fill="#669D8D"/>
                                        <circle cx="4" cy="4" r="1.875" fill="#D2D2D2" stroke="black" stroke-width="0.25"/>
                                    </svg>
                                    </span>
                                    <span dangerouslySetInnerHTML={{__html: v.content}}>
                                    </span>
                                </span>
                                <span style={{fontSize:"10px"}}>{v.date}</span>
                            </div>
                      </div>
                      </>
                    ))
                  }
                  
                    
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
