import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

import SideBar from "./../../base-components/SideBar";
import SettingsBar from "./../../base-components/SideBar/Settings";
import Header from "./../../base-components/Header";
import LOADER from "./../../base-components/Loader";
import "./style.css";
import { doPost } from "../../utils/apiCalls";
import { toast } from "react-toastify";
import Loader from "../../base-components/loader";

function ExportsData() {
  const navigate = useNavigate();
  const { getLoggedObject } = useAuth();

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  const [loading, setLoading] = useState(false);

  const [Reportsloading, setReportsloading] = useState(false);

  const GetMonthlyReports = async () => {
    const payload = {
      token: getLoggedObject()?.token,
    };
    setReportsloading(true);
    const { isError, data } = await doPost(payload, "Auth/downloadOrdersPdf");
    if (isError) {
      toast.error("Something went wrong with server.");
      setReportsloading(false);
    } else {
      if (data.status == "success") {
        toast.success("Downloaded Successfully");
        const downloadLink = document.createElement("a");
        downloadLink.href = data.url;
        downloadLink.target = "_blank";
        downloadLink.download = "monthly_reports.pdf";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setReportsloading(false);
      } else {
        setReportsloading(false);
        toast.error(data?.error);
      }
    }
  };

  return (
    <div className="container">
      <div className="wrapper-full">
        <div className="page-content">
          <SideBar />
          <div className="right_side_bar">
            <Header title={"Brands"} />

            <div className="content_inner">
              <div className="content_settings">
                {loading ? (
                  <>
                    <LOADER />
                  </>
                ) : (
                  <>
                    <SettingsBar />
                    <div className="right_settings_box">
                      <h1 style={{ marginBottom: "10px" }}>All Reports</h1>

                      <div className="card wd100 mt-10px p10">
                        <div className="p10 ReportsBox">
                          <h3>Complete Orders (Month) </h3>
                          <button
                            onClick={GetMonthlyReports}
                            disabled={Reportsloading}
                          >
                            {" "}
                            {Reportsloading ? <Loader /> : "Download"}{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExportsData;
