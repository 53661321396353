import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost, check_role_restrictions  } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LOGO from "../../assets/images/logo.svg";

import SideBar from "./../../base-components/SideBar";
import SettingsBar from "./../../base-components/SideBar/Settings";
import Header from "./../../base-components/Header";
import { AiFillEdit, AiFillDelete, AiFillCloseCircle } from "react-icons/ai";
import DeletePopup from "./Delete";

function Login() {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } = useAuth();
  const [loading, setLoading] = useState(false);
  const [upload, setUpload] = useState(false);
  const [edit, setEdit] = useState(0);
  const [delpopup, setDelPopup] = useState(false);

  const [projects, setProjects] = useState([]);
  const [title, setTitle] = useState("");
  const [btnloading, setBtnLoading] = useState(false);
  const [delid, setDelID] = useState(0);
  const [role, setRole] = useState(0);

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl = lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (isLoggedIn) {
        try {
          const response = await check_role_restrictions();
          if (response == 4) {
            toast.error("You are not authorized to perform this action!");
            setLoading(true);
            navigate("/dashboard");
          } else {
            setRole(response);
            await do_get_brands_info();
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          console.error("Error:", error);
        }
      }
    };
    fetchData();
  }, [isLoggedIn]);

    const do_get_brands_info = async () => {
        const payload = {
        token: getLoggedObject()?.token
        }
        const {isError, data} = await doPost(payload, 'auth/do_get_projects_info');
        if(isError) {
            toast.error('Something went wrong with server.');
            setLoading(false);
        }else{
            if (data.action == "success") {
            setProjects(data?.data);
            setLoading(false);
            }
            else {
            setLoading(false);
            toast.error(data?.error);
            }
        }
    };

  const handle_submit = async () => {
    if(title==""){
        toast.error('Please enter project name!');
        return;
    }
    const payload = {
        title:title,
        token: getLoggedObject()?.token,
        edit: edit
    }
    setBtnLoading(true)
    const {isError, data} = await doPost(payload, 'auth/submit_project');
    if(isError) {
        toast.error('Something went wrong with server.');
        setBtnLoading(false);
    }else{
        if (data.action == "success") {
            toast.success(edit==0?"Your projec has been created successfully!":"Project information updated successfully!");
            await do_get_brands_info();
            setEdit(0);
            handle_reset();
            setUpload(false);
        }
        else {
            setBtnLoading(false);
            toast.error(data?.error);
        }
    }
  }

  const handle_edit = async (v) => {
    setTitle(v?.title)
    setUpload(true)
    setEdit(v.id)
  }

  const handle_delete_popup = (val) => {
    setDelID(val.id)
    setDelPopup(true);
  }

  const handle_del = async () => {
    const payload = {
        id: delid,
        token: getLoggedObject()?.token
    }
    setBtnLoading(true)
    const {isError, data} = await doPost(payload, 'auth/delete_project');
    if(isError) {
        toast.error('Something went wrong with server.');
        setBtnLoading(false);
    }else{
        if (data.action == "success") {
            toast.success("Project deleted successfully!");
            await do_get_brands_info();
            setEdit(0);
            setDelID(0);
            setUpload(false)
            setDelPopup(false)
        }
        else {
            setBtnLoading(false);
            toast.error(data?.error);
        }
    }
  }

  const close_handle = () => {
    setDelID(0);
    handle_reset()
    setDelPopup(false)
  }

  const handle_reset = () => {
    setTitle("");
    setEdit(0)
    setDelID(0)
    setDelPopup(false)
    setUpload(false)
  }

  return (
    <div className="container">
      <div className="wrapper-full">
        <div className="page-content">
          <SideBar />
          <div className="right_side_bar">
            <Header title={'Projects'} />

            <div className="content_inner">
                <div className="content_settings">
                  
                  <SettingsBar />

                  <div className="right_settings_box">
                    <div className="">
                    <button className="create_button" onClick={()=> setUpload(!upload)}>
                        <span className="right_margin_button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                <path d="M7.5 3.5H4.5V0.5C4.5 0.367392 4.44732 0.240215 4.35355 0.146447C4.25979 0.0526785 4.13261 0 4 0C3.86739 0 3.74021 0.0526785 3.64645 0.146447C3.55268 0.240215 3.5 0.367392 3.5 0.5V3.5H0.5C0.367392 3.5 0.240215 3.55268 0.146447 3.64645C0.0526785 3.74021 0 3.86739 0 4C0 4.13261 0.0526785 4.25979 0.146447 4.35355C0.240215 4.44732 0.367392 4.5 0.5 4.5H3.5V7.5C3.5 7.63261 3.55268 7.75979 3.64645 7.85355C3.74021 7.94732 3.86739 8 4 8C4.13261 8 4.25979 7.94732 4.35355 7.85355C4.44732 7.75979 4.5 7.63261 4.5 7.5V4.5H7.5C7.63261 4.5 7.75979 4.44732 7.85355 4.35355C7.94732 4.25979 8 4.13261 8 4C8 3.86739 7.94732 3.74021 7.85355 3.64645C7.75979 3.55268 7.63261 3.5 7.5 3.5Z" fill="#D2D2D2"/>
                            </svg>
                        </span>
                        <span>Create Project</span>
                    </button>
                    </div>

                    {
                        upload &&
                        <>
                        <div className="popup_outer">
                            <div className="popup_inner">
                                <div className="popup_white_center">
                                    <div className="modal_heading">
                                        <span>{edit==0?"Add":"Edit"} Project</span>
                                        <span className="close_icon" onClick={()=>setUpload(false)}>
                                            <AiFillCloseCircle />
                                        </span>
                                    </div>
                                    <div className="form_setcion">
                                        <div className="form-group">
                                            <label>Project Name</label>
                                            <input type="text" name="company_name" className="setting_input" placeholder="Please enter Project name" value={title} onChange={(e)=>setTitle(e.target.value)} />
                                        </div>
                                        <div className="">
                                            <button type="button" className="button_submit" onClick={()=>handle_submit()}>{edit==0?"Submit":"Update"}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        </>
                    }

                    <div className="card wd100 mt-10px p10">
                        <table className="wd100 table_wrap">
                            <tr>
                                <th>Name</th>
                                <th>Added Date</th>
                                <th>Action</th>
                            </tr>
                        {
                          projects.length == 0 &&
                          <>
                            <tr>
                              <td colSpan={3} className="no_data">No Data found!</td>
                            </tr>
                          </>
                        }
                        {
                            projects.map((v, index) => (
                                <tr>
                                    <td>{v.title}</td>
                                    <td>{v.date}</td>
                                    <td>
                                        <AiFillEdit onClick={()=> handle_edit(v)} size={18} style={{marginRight:"10px", cursor:"pointer"}} />
                                        <AiFillDelete onClick={() => handle_delete_popup(v)} size={15} className="delete_icon_color" />
                                    </td>
                                </tr>
                            ))
                        }
                        </table>
                    </div>

                    {
                        delpopup && 
                        <DeletePopup title={'Project'} ondeleteCall={handle_del} onclosecall={close_handle} />
                    }

                    
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
